import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map} from 'rxjs/operators';
import { AccessControlService } from '../../services/access-control/access-control.service';

/**
 * Activates the route based on permissions
 * 
 * example
 *  ```typescript
 *  const routes: Routes = [
 * { path: 'test-results', 
 *  component: TestResultsComponent ,
 *  canActivate: [PermissionsGuard],
 * data: { 'moduleName': 'testResultsSummary'}
 * },
 * ];
 *  ```
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(private router: Router, private accessCtrlService: AccessControlService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const routeData = route.data;
    if(!routeData) {
      return of(true);
    }
    this.accessCtrlService.clearUserPermissions();
    return this.accessCtrlService.haveRequiredPermissions(routeData.moduleName).pipe(map( hasPerm => {
      if(hasPerm){
        return true;
      } else {
        console.warn(`Restricted access to path ${state.url}`);
        this.router.navigate(['unauthorized']);
        return false;
      }
    }));
  }
  
}
