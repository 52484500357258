import { Component, ElementRef, OnInit, ViewChildren, Output, Input, EventEmitter } from '@angular/core';
import { OrdersService } from '../../services/orders.service';
import * as _ from 'lodash';
import { LoaderService } from '../../services/loader.service';
import { ToasterSevice } from '../../services/ToasterService.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { Router } from '@angular/router';
import { CaptureFormService } from 'src/app/services/capture-form.service';
import { ErrorDialogComponent } from 'src/app/dialogs/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ThisReceiver } from '@angular/compiler';
import {ViewEncapsulation } from '@angular/core'
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';


interface MeterManufacturer {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-miu-validator',
templateUrl: './miu-validator.component.html',
styleUrls: ['./miu-validator.component.scss'],
})
export class MiuValidatorComponent implements OnInit {
  fieldCount = 20;
  miuNumber:any ={};
  meterNumbers:any ={};
  miuNumbers:any ={};
  invalidSerial: string[] = [];
  validSerial: string[] = [];
  validMeterSerial: string[] = [];
  invalidMeterSerial: string[] = [];
  validMiuSerial: string[] = [];
  invalidMiuSerial: string[] = [];
  meterMap = new Map();
  miuMap = new Map();
  @ViewChildren('userInput') userInput ;
  @ViewChildren('userMeterInput') userMeterInput;
  allStatesAndDistricts: any;
  allMeterManufactures: any;
  selectedDistrict: any;
  allStatesAndDistrictsMIU: any;
  allMeterManufacturesMIU: any;
  selectedDistrictMIU: any;
  @Output() stateDistrictSelectionChanged=new EventEmitter<any>();
  @Input() currentTab;
  @Input() mode;
  locationConfiguration = {
    addNew: false,
    list: <any>[],
    selected: null,
    temp: null,
  };
KEY_ENTER_ASCII_VAL = 13;
KEY_NUMBER_RANGE_MIN = 48;
KEY_NUMBER_RANGE_MAX = 57;
UPPER_ALPHA_RANGE_MIN = 65;
UPPER_ALPHA_RANGE_MAX = 90;
LOWER_ALPHA_RANGE_MIN = 97;
LOWER_ALPHA_RANGE_MAX = 122;
  

  isFocused: boolean[] = new Array(18).fill(false);
  meterManufactures: MeterManufacturer[] = [
  ];
  miuManufactures: MeterManufacturer[] = [
{ value : 'ARI' , viewValue : 'ARI' },
{ value : 'AY Mcdonald' , viewValue : 'AY Mcdonald' },
{ value : 'Aclara' , viewValue : 'Aclara' },
{ value : 'American Standard' , viewValue : 'American Standard' },
{ value : 'Ames' , viewValue : 'Ames' },
{ value : 'Apollo' , viewValue : 'Apollo' },
{ value : 'Apollo/Conbraco' , viewValue : 'Apollo/Conbraco' },
{ value : 'BAVCO' , viewValue : 'BAVCO' },
{ value : 'Backflow Direct' , viewValue : 'Backflow Direct' },
{ value : 'Badger' , viewValue : 'Badger' },
{ value : 'Beeco' , viewValue : 'Beeco' },
{ value : 'Buckner' , viewValue : 'Buckner' },
{ value : 'CLA' , viewValue : 'CLA' },
{ value : 'CONVERSION' , viewValue : 'CONVERSION' },
{ value : 'Cash Acme' , viewValue : 'Cash Acme' },
{ value : 'Champion' , viewValue : 'Champion' },
{ value : 'Cla-Val' , viewValue : 'Cla-Val' },
{ value : 'Conbraco' , viewValue : 'Conbraco' },
{ value : 'Deringer' , viewValue : 'Deringer' },
{ value : 'Elster' , viewValue : 'Elster' },
{ value : 'Febco' , viewValue : 'Febco' },
{ value : 'Flomatic' , viewValue : 'Flomatic' },
{ value : 'Ford' , viewValue : 'Ford' },
{ value : 'Giacomini' , viewValue : 'Giacomini' },
{ value : 'Grinnell' , viewValue : 'Grinnell' },
{ value : 'Hersey' , viewValue : 'Hersey' },
{ value : 'Honeywell' , viewValue : 'Honeywell' },
{ value : 'Itron' , viewValue : 'Itron' },
{ value : 'Jones Stephens Corp' , viewValue : 'Jones Stephens Corp' },
{ value : 'Kamstrup' , viewValue : 'Kamstrup' },
{ value : 'Kennedy-Grinnell' , viewValue : 'Kennedy-Grinnell' },
{ value : 'Kent' , viewValue : 'Kent' },
{ value : 'Legend' , viewValue : 'Legend' },
{ value : 'Master Meter' , viewValue : 'Master Meter' },
{ value : 'Metron-Farnier' , viewValue : 'Metron-Farnier' },
{ value : 'Mueller' , viewValue : 'Mueller' },
{ value : 'Musterfirma GmbH' , viewValue : 'Musterfirma GmbH' },
{ value : 'N/A' , viewValue : 'N/A' },
{ value : 'Neptune' , viewValue : 'Neptune' },
{ value : 'Orion' , viewValue : 'Orion' },
{ value : 'Precision' , viewValue : 'Precision' },
{ value : 'Premier' , viewValue : 'Premier' },
{ value : 'Rainbird' , viewValue : 'Rainbird' },
{ value : 'Rockwell' , viewValue : 'Rockwell' },
{ value : 'Sensus' , viewValue : 'Sensus' },
{ value : 'Strahman' , viewValue : 'Strahman' },
{ value : 'Taco' , viewValue : 'Taco' },
{ value : 'Toro' , viewValue : 'Toro' },
{ value : 'UNKNOWN' , viewValue : 'UNKNOWN' },
{ value : 'Unknown' , viewValue : 'Unknown' },
{ value : 'User Owned' , viewValue : 'User Owned' },
{ value : 'Verify' , viewValue : 'Verify' },
{ value : 'Watts' , viewValue : 'Watts' },
{ value : 'Wilkins' , viewValue : 'Wilkins' },
{ value : 'Wolverine Brass' , viewValue : 'Wolverine Brass' },
{ value : 'Worthington' , viewValue : 'Worthington' },
{ value : 'Zenner' , viewValue : 'Zenner' },
  ];
  manufacturerSelected: string = "";
  manufacturerSelectedMIU: string = "";
  constructor(
    public ordersService: OrdersService,
    public toasterSevice: ToasterSevice,
    public captureFormService: CaptureFormService,
    private loader: LoaderService,
    public utilitiesService: UtilitiesService,
    private router: Router,
    public dialog: MatDialog,
  ) {
   for(let i = 0; i< this.fieldCount; i++){
    this.meterMap.set( i, ["" ,"Enter Meter Number"]);
   }
   for(let i = 0; i< this.fieldCount; i++){
    this.miuMap.set( i, ["" ,"Enter MIU Number"]);
   }
   

  }

  ngOnInit(): void {
    this.initlizeData();
  }

  initlizeData() {
    this.loader.showLoader();
    const calls = this.utilitiesService.getAllStatesAndDistricts(false);
    //calls[0] = ;
    forkJoin(calls).subscribe((response) => {
      let tabdataObject;
      let locationObject;
      this.allStatesAndDistricts = response[0];
      this.allStatesAndDistrictsMIU = response[0];
      console.log(locationObject,"selected");
        this.loader.hideLoader();

    }, (error) => {
      this.loader.hideLoader();
      this.toasterSevice.error("There was an error please try again later");
      console.log("Error from server", error);
    });

    const manufacturerCalls = this.utilitiesService.getAllMeterManufactures();
    forkJoin(manufacturerCalls).subscribe((response) => {
      let tabdataObject;
      let locationObject;
      this.allMeterManufactures = response[0];
      for(let i = 0; i < this.allMeterManufactures.data.length; i++){
          this.meterManufactures.push({value: this.allMeterManufactures.data[i].value, viewValue: this.allMeterManufactures.data[i].value})
          //this.miuManufactures.push({value: this.allMeterManufactures.data[i].value, viewValue: this.allMeterManufactures.data[i].value})
      }
      console.log(locationObject,"selected");
        this.loader.hideLoader();
    }, (error) => {
      this.loader.hideLoader();
      this.toasterSevice.error("There was an error please try again later");
      console.log("Error from server", error);
    });
  }
  navigateback(){
    this.router.navigateByUrl('/orders');
  }
  validate(miuNumber){
    this.loader.showLoader();
    let errorlog = true;
    const keys = Object.keys(miuNumber);
    keys.forEach((key, index) => {
      const miuSerial = miuNumber[key]
        if(!!miuSerial){
        this.captureFormService.fetchDetails(miuSerial, '60000000' , 'miu').subscribe((res) => {
          if (res && res.equipmentData && res.equipmentData.length > 0) {
            res.equipmentData[0].returnStatuses.map(v => {
              if (res.equipmentData[0].returnStatuses.length > 0 && res.equipmentData[0].returnStatuses[0].returnStatusType == 'E' && res.equipmentData[0].returnStatuses[0].returnStatusDescription !== " Device/Connection Object Plant mismatch occured. Enter Valid MIU Serial Number") {
                this.invalidSerial.push(miuSerial);
              }
            })
            if(this.invalidSerial.indexOf(miuSerial) == -1){
              this.validSerial.push(miuSerial);
            }
          }
          if(res.equipmentData === undefined && errorlog){
            errorlog = false;
            const dialogRef = this.dialog.open(ErrorDialogComponent, {
              hasBackdrop: true,
              disableClose: true,
              width: '350px',
              height: '250px',
            });
            dialogRef.afterClosed().subscribe(res => {
              this.loader.hideLoader();
            });

          }
          this.loader.hideLoader();
        });
      }
    });

  }

  validateMeters(meterNumbers){
    this.invalidMeterSerial = [];
    this.validMeterSerial = [];
    this.loader.showLoader();
    let errorlog = true;
    let district = this.selectedDistrict;
    let manufacturer = this.manufacturerSelected;
    const keys = Object.keys(meterNumbers);
    keys.forEach((key, index) => {
      const isAlphaNumeric = this.isAlphaNumeric(meterNumbers[key]);
      const meterSerial = meterNumbers[key]
        if(!!meterSerial && isAlphaNumeric){
        this.captureFormService.fetchDetails(meterSerial, '60000000' , 'meter', district, manufacturer).subscribe((res) => {
          if (res && res.scannerData && res.scannerData.length > 0) {
            res.scannerData[0].returnStatuses.map(v => {
              if (res.scannerData[0].returnStatuses.length > 0 && res.scannerData[0].returnStatuses[0].returnStatusType == 'E' || res.scannerData[0].planningPlant == "") {
                const match = key.match(/\d+$/);
                if(match){
                  const keyNumber = parseInt(match[0], 10);   
                  this.invalidMeterSerial.push(meterSerial);
                  this.meterMap.set(keyNumber, ["", "Not Validated"]);
                }
              }
            })
            if(this.invalidMeterSerial.indexOf(meterSerial) == -1){
              const match = key.match(/\d+$/);
              if(match){
                const keyNumber = parseInt(match[0], 10);   
                this.validMeterSerial.push(meterSerial);
                this.meterMap.set(keyNumber, [res.scannerData[0].planningPlant, ""])
              }
            }
          }
          if(res.scannerData === undefined && errorlog){
            errorlog = false;
            const dialogRef = this.dialog.open(ErrorDialogComponent, {
              hasBackdrop: true,
              disableClose: true,
              width: '350px',
              height: '250px',
            });
            dialogRef.afterClosed().subscribe(res => {
              this.loader.hideLoader();
            });

          }
          this.loader.hideLoader();
        });
      }
      else{
        const match = key.match(/\d+$/);
                if(match){
                  const keyNumber = parseInt(match[0], 10);   
                  this.invalidMeterSerial.push(meterSerial);
                  this.meterMap.set(keyNumber, ["", "Not Validated"]);
                }
                this.loader.hideLoader();
      }
    });
  }

  validateMius(miuNumbers){
    this.invalidMiuSerial = [];
    this.validMiuSerial = [];
    this.loader.showLoader();
    let errorlog = true;
    let district = this.selectedDistrictMIU;
    let manufacturer = this.manufacturerSelectedMIU;
    const keys = Object.keys(miuNumbers);
    keys.forEach((key, index) => {
      const isAlphaNumeric = this.isAlphaNumeric(miuNumbers[key]);
      const miuSerial = miuNumbers[key]
        if(!!miuSerial && isAlphaNumeric){
        this.captureFormService.fetchDetails(miuSerial, '60000000' , 'miu', district, manufacturer).subscribe((res) => {
          if (res && res.scannerData && res.scannerData.length > 0) {
            res.scannerData[0].returnStatuses.map(v => {
              if (res.scannerData[0].returnStatuses.length > 0 && res.scannerData[0].returnStatuses[0].returnStatusType == 'E' || res.scannerData[0].planningPlant == "") {
                const match = key.match(/\d+$/);
                if(match){
                  const keyNumber = parseInt(match[0], 10);   
                  this.invalidMiuSerial.push(miuSerial);
                  this.miuMap.set(keyNumber, ["", "Not Validated"]);
                }
              }
            })
            if(this.invalidMiuSerial.indexOf(miuSerial) == -1){
              const match = key.match(/\d+$/);
              if(match){
                const keyNumber = parseInt(match[0], 10);   
                this.validMiuSerial.push(miuSerial);
                this.miuMap.set(keyNumber, [res.scannerData[0].planningPlant, ""])
              }
            }
          }
          if(res.scannerData === undefined && errorlog){
            errorlog = false;
            const dialogRef = this.dialog.open(ErrorDialogComponent, {
              hasBackdrop: true,
              disableClose: true,
              width: '350px',
              height: '250px',
            });
            dialogRef.afterClosed().subscribe(res => {
              this.loader.hideLoader();
            });

          }
          this.loader.hideLoader();
        });
      }
      else{
        const match = key.match(/\d+$/);
                if(match){
                  const keyNumber = parseInt(match[0], 10);   
                  this.invalidMiuSerial.push(miuSerial);
                  this.miuMap.set(keyNumber, ["", "Not Validated"]);
                }
                this.loader.hideLoader();
      }
    });
  }

  clearAll(){
    this.miuNumber ={};
    this.invalidSerial = [];
    this.validSerial = [];
  }

  clearAllMeter(){
    this.meterNumbers = {};
    this.invalidMeterSerial = [];
    this.validMeterSerial = [];
    for(let i = 0; i< this.fieldCount; i++){
      this.meterMap.set( i, ["" ,"Enter Meter Number"]);
     }
  }

  clearAllMiu(){

    this.miuNumbers = {};
    this.invalidMiuSerial = [];
    this.validMiuSerial = [];
    for(let i = 0; i< this.fieldCount; i++){
        this.miuMap.set( i, ["" ,"Enter Miu Number"]);
    }
  }

  isAlphaNumeric(miuNumber){
    const regex = /^[a-zA-Z0-9]+$/;
    return regex.test(miuNumber);
  }

  alphaNumeric(event, index) {
    const key = event.keyCode;
    if(key == this.KEY_ENTER_ASCII_VAL) {
      let control = this.userInput;
      let nextindex = index + 1;
      let nextControl = control._results.filter(x => { return x.nativeElement.id == nextindex });
      if (nextControl != null) {
        nextControl[0]?.nativeElement.select();
      }
    }
    return ((key >= this.KEY_NUMBER_RANGE_MIN && key <= this.KEY_NUMBER_RANGE_MAX) || 
    (key >= this.UPPER_ALPHA_RANGE_MIN && key <= this.UPPER_ALPHA_RANGE_MAX) ||
    (key >= this.LOWER_ALPHA_RANGE_MIN && key <= this.LOWER_ALPHA_RANGE_MAX));
  }

  alphaNumericMeter(event, index) {
    const key = event.keyCode;
    if(key == this.KEY_ENTER_ASCII_VAL) {
      let control = this.userMeterInput;
      let nextindex = "meterInput" + (index + 1);
      let nextControl = control._results.filter(x => { return x.nativeElement.id == nextindex });
      if (nextControl != null) {
        nextControl[0]?.nativeElement.select();
      }
    }
    return ((key >= this.KEY_NUMBER_RANGE_MIN && key <= this.KEY_NUMBER_RANGE_MAX) || 
    (key >= this.UPPER_ALPHA_RANGE_MIN && key <= this.UPPER_ALPHA_RANGE_MAX) ||
    (key >= this.LOWER_ALPHA_RANGE_MIN && key <= this.LOWER_ALPHA_RANGE_MAX));
  }

  stateDistrictSelectionChange(district) {
    this.selectedDistrict = district; 
    console.log(this.selectedDistrict); 
    }

  stateDistrictSelectionChangeMIU(district) {
    this.selectedDistrictMIU = district; 
    console.log(this.selectedDistrictMIU); 
    }

    isMeterArrayPopulated(): boolean {
      const keys = Object.keys(this.meterNumbers);
      let isValid = true;
      keys.forEach((key) => {
        if(this.meterNumbers[key].length > 0){
          isValid = false;
        }
      })
      return isValid;
    }
    
    isMIUArrayPopulated(): boolean {
      const keys = Object.keys(this.miuNumbers);
      let isValid = true;
      keys.forEach((key) => {
        if(this.miuNumbers[key].length > 0){
          isValid = false;
        }
      })

      return isValid;
    }
  }
