import { Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import {
  OKTA_CONFIG,
  OktaAuthModule,
} from '@okta/okta-angular';

import { LoaderComponent } from './components/loader/loader.component';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { dbConfig } from './db.config';
import { CaptureFormService } from './services/capture-form.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpBackend, HttpClient } from '@angular/common/http';
import { PoliceEscortComponent } from './dialogs/police-escort/police-escort.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { FsronlyComponent } from './auth/fsronly/fsronly.component';
import { ContractoronlyComponent } from './auth/contractoronly/contractoronly.component';
import { SocketService } from './services/socket.service';
import { ReadMiuComponent } from './dialogs/read-miu/read-miu.component';
import { UtilitiesService } from './services/utilities.service';
import { OrdersService } from './services/orders.service';
import { FormsModule } from '@angular/forms';
import { MiuValidatorComponent } from './components/miu-validator/miu-validator.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { IndexComponent } from './index/index.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NetworkComponent } from './network/network.component';
import { MatListModule } from '@angular/material/list';
import { LocationComponent } from './location/location.component';
import { SharedModule } from './modules/shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NguiMapModule } from '@ngui/map';
import { DiagnosticPageHeaderComponent } from './diagnostic-page/diagnostic-page-header.component';
import { DiagnosticConfirmDlgComponent } from './location/diagnostic-confirm-dlg/diagnostic-confirm-dlg.component';
import { ReorderConfirmationDialogComponent } from './dialogs/reorder-confirmation-dialog/reorder-confirmation-dialog.component';
import { AuthInterceptor } from './interceptors/auth-interceptor';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs'; 
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { StateDistrictSelectorComponent } from './components/state-district-selector/state-district-selector.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SearchPipe } from './pipe/search.pipe';
import { DeferSelectWarningComponent } from './dialogs/defer-select-warning/defer-select-warning.component';
import { NoReadReasonComponent } from './dialogs/no-read-reason/no-read-reason.component';



// const config: SocketIoConfig = { url: 'https://w1vmgmt-dev.awapps.com', options: {
//   transports: ['websocket'],
//   path: '/api/stomp-endpoint'
// }};

export const httpInterceptorProviders = [
   { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

const oktaAuth = new OktaAuth(environment.authConfig as OktaAuthOptions);

@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent, PoliceEscortComponent,
        UnauthorizedComponent,
        FsronlyComponent,
        ContractoronlyComponent,
        ReadMiuComponent,
        SearchPipe,
        MiuValidatorComponent, ErrorDialogComponent, IndexComponent, NetworkComponent, LocationComponent, DiagnosticConfirmDlgComponent, DiagnosticPageHeaderComponent, ReorderConfirmationDialogComponent, StateDistrictSelectorComponent, DeferSelectWarningComponent, NoReadReasonComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        DragDropModule,
        FormsModule,
        OktaAuthModule,
        MatSnackBarModule,
        MatDialogModule,
        MatExpansionModule,
        MatListModule,
        NguiMapModule,
        MatTabsModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        // SocketIoModule.forRoot(config),
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatSidenavModule,
        NgxIndexedDBModule.forRoot(dbConfig),
        ToastrModule.forRoot({
            positionClass: "toast-top-right"
        }),
        ServiceWorkerModule.register('./custom-sw.js', {
            enabled: true,
            registrationStrategy: 'registerImmediately' //registerWhenStable:30000'
        }),
        SharedModule
    ],
    providers: [
        OrdersService,
        CaptureFormService,
        SocketService,
        UtilitiesService,
        {
            provide: OKTA_CONFIG,
            useValue: { oktaAuth }
        },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        httpInterceptorProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
