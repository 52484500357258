import  cloneDeep  from 'lodash-es/cloneDeep';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-state-district-selector',
  templateUrl: './state-district-selector.component.html',
  styleUrls: ['./state-district-selector.component.scss']
})
export class StateDistrictSelectorComponent implements OnInit {
  locationInfo: any;
  locationInfoList: any = [];
  outputArray: any = [];
  @Input() selectedDistrict;
  @Input() inputFormat!:{type:"string"|"object",key:""};
  @Input() outputFormat!:"stateDistrict"|"district";
  @Input() dataSource;
  @Input() mode!:"single"|"multi";
  @Output() onSelection: EventEmitter<any> = new EventEmitter();
  searchValue!: string;
  allDistrictsCount:number = 0;
  selectedState =  
    {
      state:null,
      district:null
    } 
  constructor() { }

  ngOnInit(): void {
    this.mode = this.mode?this.mode:"multi";
    this.outputFormat = this.outputFormat?this.outputFormat:'stateDistrict';
    this.inputFormat = this.inputFormat?this.inputFormat:{type:'string',key:""};
  }
  ngOnChanges (changes:SimpleChange){
    this.initlize();
  }
  /**
   * @name Initlize
   * @description Iniliizes the component 
   */
  initlize() { 
    this.allDistrictsCount = 0;
    this.outputArray = [];
    this.locationInfo = cloneDeep(this.dataSource);
      for(var i=0;i<this.locationInfo.length;i++)
      {
        this.locationInfo[i]["selectedDistrcitsCount"] = 0;
        this.locationInfo[i]['searchString']="";
        for(var j=0;j<this.locationInfo[i].districts.length;j++)
        {
          this.locationInfo[i].districts[j]={name:this.locationInfo[i].districts[j],selected:false,searchString:this.locationInfo[i].districts[j]+this.locationInfo[i].state};
          this.locationInfo[i]['searchString']=this.locationInfo[i]['searchString'].concat(this.locationInfo[i].districts[j].name.split("-@-")[0]+this.locationInfo[i].state);
          this.allDistrictsCount++;
          for(var k=0;k<this.selectedDistrict?.length;k++){
            if (this.mode === 'multi' && this.locationInfo[i].districts[j].name === this.inputFormater(this.selectedDistrict[k])) {
              this.locationInfo[i].districts[j].selected = true;
              this.locationInfo[i]["selectedDistrcitsCount"]++;
              this.outputArray.push(this.outputFormater(this.locationInfo[i].state, this.locationInfo[i].districts[j].name))
              break;
            }
            if (this.mode === 'single' && this.locationInfo[i].districts[j].name === this.inputFormater(this.selectedDistrict)) {
              this.onSingleDistrict(this.locationInfo[i],this.locationInfo[i].districts[j])
              break;
            }
        }
      }
    }
    if (this.mode === 'multi') {
      this.onSelection.emit(this.outputArray);
    }

  }

  /**
   * @name onSelectAllChange
   * @description Updates select All for states Checkbox
   * @param {boolean} checked
   */
  onSelectAllChange(checked: boolean) {
    for(let i=0;i<this.locationInfo.length;i++)
     {
       this.onStateChange(checked,this.locationInfo[i],false);
     }
     this.onSelection.emit(this.outputArray); 
  }

  /**
   * Updates state Checkbox and all Districts
   * @param {boolean} checked
   * @param state array
   */
  onStateChange(checked: boolean,state,isBroadcaste) {
     for(let i=0;i<state.districts.length;i++)
     {
       if(state.districts[i].selected != checked)
       {
        state.districts[i].selected = !checked;
       this.onDistrictChange(state,state.districts[i],false);
       }
     }
     if(isBroadcaste)
     {
      this.onSelection.emit(this.outputArray);
     }
  }

  onDistrictChange(state,district,isBroadcaste) {
    if(district.selected){
      district.selected = false;
      state.selectedDistrcitsCount--;
      for(let i=0;i<this.outputArray.length;i++)
      {
        if(district.name==(typeof this.outputArray[i] =="string"?this.outputArray[i]:this.outputArray[i].district))
        {          
          this.outputArray.splice(i,1);
          break;
        }
      }
      if(isBroadcaste)
     {
      this.onSelection.emit(this.outputArray);
     }
    }
    else{
      district.selected = true;
         state.selectedDistrcitsCount++;
        this.outputArray.push(this.outputFormater(state.state,district.name));
      if(isBroadcaste)
     {
      this.onSelection.emit(this.outputArray); //multi mode emit
     }
    }
  }

  onSingleDistrict(state,district){
    
    
    if(this.mode=="single"){
      this.selectedDistrict = district;
      this.selectedState.state = state.state;
      this.selectedState.district = district.name;
      this.onSelection.emit(this.outputFormater(state.state,district.name));  //single mode emit
    }
  }

  clearSelection(){
    this.selectedDistrict={};
  }

  outputFormater(state,district){
    let value;
    switch (this.outputFormat) {
      case "stateDistrict":
        value = {"state":state,"district":district};
        break;
      case "district":
        value = district;
      break;
    }  
    return value;
  }
  inputFormater(data){
    let value;
    switch (this.inputFormat.type) {
      case "object":
        value = data[this.inputFormat.key];
        break;
      case "string":
        value = data;
      break;
    }  
    return value;
  }
  
}