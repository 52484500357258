<div class="main-container">
  <div class="header">
    <div class="title">
      <div class="text">{{data.title}}</div>
    </div>
    <div [mat-dialog-close] class="close-section">
      <img src="assets/images/other/close-icon.svg">
    </div>
  </div>
  <div class="content">
    <div class="text">{{data.description}}</div>
  </div>
  <div class="action">
    <button mat-button (click)="save()" cdkFocusInitial>{{data.button ? data.button : 'OK'}}</button>
  </div>
</div>