import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrdersService } from 'src/app/services/orders.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { UtilitiesService } from '../services/utilities.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  complete: any;
  inspection: any;
  largeMeterConfig: any;
  meterTest: any;
  meterActivites: any;
  nontransitions: any;
  premise: any;
  transitions: any;
  uploadedFiles: any;
  version: any;
  widgets: any;
  workOrderDetails: any;
  workOrders: any;
  basic: boolean = false;
  executing: boolean = false;
  transMsg:String[] = [];
  executedCount = 1;
  constructor(
    private router: Router,
    public ordersService: OrdersService,
    private dbService: NgxIndexedDBService,
    private utilities: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.transitionsFlow();
    this.transMsg.push('Loading');
  }

  switchFunctionality() {
    this.basic = !this.basic;
    if (this.basic) {
      this.basicFlow();
    } else {
      this.transitionsFlow();
    }
  }

  startExecuting() {
    this.executing = true;
    this.dbService.getAll('transitions').subscribe((orders) => {
      this.transitions = orders;
      this.executeCall(this.transitions[0]);
    })
  }

  executeCall(item) {
    this.transMsg.push('Index: ' + this.executedCount + ' =========> URL: ' + item.url + ' =========> DATA: ' + JSON.stringify(item));
    this.utilities.postCall(item.url, item.data, item.key, false, false).subscribe(output => {   // 1st false is for options , 2nd false for ignore condition
      this.transMsg.push('Response: ' + JSON.stringify(output));
      if (this.transitions.length > 0) {
        this.transitions.shift();
        this.executedCount++;
        this.executeCall(this.transitions[0]);
      }
    });
  }

  transitionsFlow() {
    this.dbService.getAll('transitions').subscribe((orders) => {
      this.transitions = orders;
      const count = (orders && orders.length > 0) ? orders.length + '' : '0'
      this.transMsg.push('Loaded Transitions from Index-Db & Count is ' + count);
    });
  }

  basicFlow(): void {
    this.dbService.getAll('complete').subscribe((orders) => {
      this.complete = orders;
    });

    this.dbService.getAll('inspection').subscribe((orders) => {
      this.inspection = orders;
    });
    this.dbService.getAll('largeMeterConfig').subscribe((orders) => {
      this.largeMeterConfig = orders;
    });
    this.dbService.getAll('meter-test').subscribe((orders) => {
      this.meterTest = orders;
    });

    this.dbService.getAll('meterActivites').subscribe((orders) => {
      this.meterActivites = orders;
    });

    this.dbService.getAll('nontransitions').subscribe((orders) => {
      this.nontransitions = orders;
    });

    this.dbService.getAll('premise').subscribe((orders) => {
      this.premise = orders;
    });

    this.dbService.getAll('transitions').subscribe((orders) => {
      this.transitions = orders;
    });

    this.dbService.getAll('uploadedFiles').subscribe((orders) => {
      this.uploadedFiles = orders;
    });

    this.dbService.getAll('version').subscribe((orders) => {
      this.version = orders;
    });

    this.dbService.getAll('widgets').subscribe((orders) => {
      this.widgets = orders;
    });

    this.dbService.getAll('workOrderDetails').subscribe((orders) => {
      this.workOrderDetails = orders;
    });
    
    this.dbService.getAll('workOrders').subscribe((orders) => {
      this.workOrders = orders;
    });
    // this.ordersService.getStoredOrders(false,false)

    // this.dbService
    // .getAll('workOders')
    // .subscribe((orders) => {
    //   this.workOrders = orders;
    //   // debugger
    // });
  }
  // getStoredData(){
  //   this.dbService.getAll('workOrders').subscribe(
  //     (storedOrders) => {
  //       this.workOrders = storedOrders;
  //     })
  // }
}
