import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContractoronlyComponent } from './auth/contractoronly/contractoronly.component';
import { FsronlyComponent } from './auth/fsronly/fsronly.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { MiuValidatorComponent } from './components/miu-validator/miu-validator.component';
import { IndexComponent } from './index/index.component';
import { NetworkComponent } from './network/network.component';
import { LocationComponent } from './location/location.component';
import { OktaAuthGuard, OktaCallbackComponent} from '@okta/okta-angular';
import { PermissionsGuard } from './auth/permissions/permissions.guard';
import { AuthService } from './services/auth/auth.service';

type PathMatch = "full" | "prefix" | undefined;

const routes: Routes = [
  { 
    path: 'login/callback',
    component: OktaCallbackComponent 
  },
  {
    path: 'orders',
    loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [OktaAuthGuard]
  },
  {
    path: '',
    redirectTo: 'orders',
    pathMatch: 'full' as PathMatch,
  },
  {
    path: 'glance',
    loadChildren: () => import('./modules/work-at-glance/work-at-glance.module').then((m) => m.WorkAtGlanceModule),
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'validator',
    component: MiuValidatorComponent,
  },
  {
    path: 'index',
    component: IndexComponent,
  },
  {
    path: 'network',
    component: NetworkComponent,
  },
  {
    path: 'location',
    component: LocationComponent,
  },
  {
    path: 'fsronly',
    canActivate: [OktaAuthGuard,PermissionsGuard],
    component: FsronlyComponent,
    data: {
      'moduleName': 'fsronly'
    }
  },
  {
    path: 'contractoronly',
    canActivate: [OktaAuthGuard,PermissionsGuard],
    component: ContractoronlyComponent,
    data: {
      'moduleName': 'contractoronly'
    }
  },
  {
    path: '**',
    redirectTo: 'orders',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    AuthService]
})
export class AppRoutingModule {}
