import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeechRecognitionBottomSheetComponent, VoiceToTextComponent } from './voice-to-text.component';
import { SpeechRecognitionService } from 'src/app/services/speech-recognition.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';



@NgModule({
    declarations: [
        SpeechRecognitionBottomSheetComponent,
        VoiceToTextComponent
    ],
    imports: [
        CommonModule,
        MatBottomSheetModule
    ],
    exports: [
        SpeechRecognitionBottomSheetComponent,
        VoiceToTextComponent,
        MatBottomSheetModule
    ],
    providers: [SpeechRecognitionService]
})
export class VoiceToTextModule { }
