import { Injectable } from '@angular/core';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class MeterActionsService {

  constructor() { }

  meterActions = 
  {
    "100": {
      "actionDescription": "ADD_METER",
      "actionTitle": "Add Meter",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "X",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "101": {
      "actionDescription": "ADD_METER_AND_MIU2",
      "actionTitle": "Add Meter and MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "X",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "102": {
      "actionDescription": "ADD_METER_REPLACE_MIU2",
      "actionTitle": "Add Meter and Replace MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "X",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "103": {
      "actionDescription": "ADD_METER_AND_REMOVE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "X",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": ""
    },
    "104": {
      "actionDescription": "ADD_METER_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "X",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "110": {
      "actionDescription": "ADD_METER_AND_MIU1",
      "actionTitle": "Add Meter and MIU 1",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "I",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "111": {
      "actionDescription": "ADD_METER_AND_MIU1_AND_MIU2",
      "actionTitle": "Add Meter and MIU 1 and MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "I",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "112": {
      "actionDescription": "ADD_METER_AND_ADD_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Add Meter and Add MIU 1 and Replace MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "I",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "113": {
      "actionDescription": "ADD_METER_AND_ADD_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Add Meter and Add MIU 1 and Remove MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "I",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "NSI"
    },
    "114": {
      "actionDescription": "ADD_METER_AND_ADD_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "I",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "120": {
      "actionDescription": "ADD_METER_REPLACE_MIU1",
      "actionTitle": "Add Meter and Replace MIU 1",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "E",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "121": {
      "actionDescription": "ADD_METER_AND_REPLACE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Add Meter and Replace MIU 1 and Add MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "E",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "122": {
      "actionDescription": "ADD_METER_REPLACE_MIU1_AND_MIU2",
      "actionTitle": "Add Meter and Replace MIU 1 & 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "E",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "123": {
      "actionDescription": "ADD_METER_AND_REPLACE_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Add Meter and Replace MIU 1 and Remove MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "E",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "NSI"
    },
    "124": {
      "actionDescription": "ADD_METER_AND_REPLACE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "E",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "130": {
      "actionDescription": "ADD_METER_AND_REMOVE_MIU1NoAction",
      "actionTitle": "Add Meter and Remove MIU 1",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "R",
      "mIU2Flag": "X",
      "isValid": "false",
      "message": "This combination is not permitted.",
      "interfaceName": "NSI"
    },
    "131": {
      "actionDescription": "ADD_METER_AND_REMOVE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Add Meter and Remove MIU 1 and Add MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "R",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "NSI"
    },
    "132": {
      "actionDescription": "ADD_METER_AND_REMOVE_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Add Meter and Remove MIU 1 and Replace MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "R",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "NSI"
    },
    "133": {
      "actionDescription": "ADD_METER_AND_REMOVE_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "R",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "134": {
      "actionDescription": "ADD_METER_AND_REMOVE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "R",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "140": {
      "actionDescription": "ADD_METER_AND_DOWNGRADE_TP1",
      "actionTitle": "Add Meter and Install Touchpad",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "T",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "141": {
      "actionDescription": "ADD_METER_AND_DOWNGRADE_TP1_AND_ADD_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "T",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "142": {
      "actionDescription": "ADD_METER_AND_DOWNGRADE_TP1_AND_REPLACE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "T",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "143": {
      "actionDescription": "ADD_METER_AND_DOWNGRADE_TP1_AND_REMOVE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "T",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "144": {
      "actionDescription": "ADD_METER_AND_DOWNGRADE_TP1_AND_DOWNGRADE_TP2",
      "actionTitle": "Add Meter and Install Touchpad ",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "T",
      "mIU2Flag": "T",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "200": {
      "actionDescription": "ADD_COMPOUND_METER",
      "actionTitle": "Add Compound Meter",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "X",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "201": {
      "actionDescription": "ADD_COMPOUND_AND_ADD_MIU2",
      "actionTitle": "Add Compound Meter and Add MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "X",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "202": {
      "actionDescription": "ADD_COMPOUND_AND_REPLACE_MIU2",
      "actionTitle": "Add Compound Meter and Replace MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "X",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "203": {
      "actionDescription": "ADD_COMPOUND_AND_REMOVE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "X",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "204": {
      "actionDescription": "ADD_COMPOUND_METER_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "X",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "210": {
      "actionDescription": "ADD_COMPOUND_AND_ADD_MIU1NoAction",
      "actionTitle": "Add Meter and Add MIU 1",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "I",
      "mIU2Flag": "X",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "NSI"
    },
    "211": {
      "actionDescription": "ADD_COMPOUND_METER_AND_ADD_MIU1_AND_ADD_MIU2",
      "actionTitle": "Add Compound Meter and Add MIU 1 & 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "I",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "212": {
      "actionDescription": "ADD_COMPOUND_AND_ADD_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Add Meter and Add MIU 1 and Replace MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "I",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "213": {
      "actionDescription": "ADD_COMPOUND_AND_ADD_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Add Meter and Add MIU 1 and Remove MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "I",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "NSI"
    },
    "214": {
      "actionDescription": "ADD_COMPOUND_METER_AND_ADD_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "I",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "220": {
      "actionDescription": "ADD_COMPOUND_AND_REPLACE_MIU1",
      "actionTitle": "Add Meter and Replace MIU 1",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "E",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "221": {
      "actionDescription": "ADD_COMPOUND_AND_REPLACE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Add Meter and Replace MIU 1 and Add MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "E",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "222": {
      "actionDescription": "ADD_COMPOUND_METER_AND_REPLACE_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Add Compound Meter and Replace MIU 1 & 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "E",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "223": {
      "actionDescription": "ADD_COMPOUND_AND_REPLACE_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Add Compound Meter and Replace MIU 1 and Remove MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "E",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "NSI"
    },
    "224": {
      "actionDescription": "ADD_COMPOUND_METER_AND_REPLACE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "E",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "230": {
      "actionDescription": "ADD_COMPOUND_AND_REMOVE_MIU1",
      "actionTitle": "Add Meter and Remove MIU 1",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "R",
      "mIU2Flag": "X",
      "isValid": "false",
      "message": "This combination is not permitted.",
      "interfaceName": "NSI"
    },
    "231": {
      "actionDescription": "ADD_COMPOUND_AND_REMOVE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Add Compound Meter and Remove MIU 1 and Add MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "R",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "NSI"
    },
    "232": {
      "actionDescription": "ADD_COMPOUND_AND_REMOVE_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Add Compound Meter and Remove MIU 1 and Replace MIU 2",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "R",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "NSI"
    },
    "233": {
      "actionDescription": "ADD_COMPOUND_METER_AND_REMOVE_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "R",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "234": {
      "actionDescription": "ADD_COMPOUND_METER_AND_REMOVE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "R",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "240": {
      "actionDescription": "ADD_COMPOUND_METER_AND_DOWNGRADE_TP1",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "T",
      "mIU2Flag": "X",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "241": {
      "actionDescription": "ADD_COMPOUND_METER_AND_DOWNGRADE_TP1_AND_ADD_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "T",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "242": {
      "actionDescription": "ADD_COMPOUND_METER_AND_DOWNGRADE_TP1_AND_REPLACE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "T",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "243": {
      "actionDescription": "ADD_COMPOUND_METER_AND_DOWNGRADE_TP1_AND_REMOVE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "I",
      "mIU1Flag": "T",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "244": {
      "actionDescription": "ADD_COMPOUND_METER_DOWNGRADE_TP1_AND_DOWNGRADE_TP2",
      "actionTitle": "Add Compound Meter and change MIUs to Touchpad/Keyed",
      "steps": [
        "newMeterNumber",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "I",
      "mIU1Flag": "T",
      "mIU2Flag": "T",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "300": {
      "actionDescription": "REPLACE_METER",
      "actionTitle": "Replace Meter",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "X",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "301": {
      "actionDescription": "REPLACE_METER_ADD_MIU2",
      "actionTitle": "Replace Meter and Add MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "X",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "302": {
      "actionDescription": "REPLACE_METER_AND_MIU2",
      "actionTitle": "Replace Meter and MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "X",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "303": {
      "actionDescription": "REPLACE_METER_AND_REMOVE_MIU2",
      "actionTitle": "Replace Meter and remove MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "X",
      "mIU2Flag": "R",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "304": {
      "actionDescription": "REPLACE_METER_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "E",
      "mIU1Flag": "X",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "310": {
      "actionDescription": "REPLACE_METER_ADD_MIU1",
      "actionTitle": "Replace Meter and Add MIU 1",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "I",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "311": {
      "actionDescription": "REPLACE_METER_ADD_MIU1_AND_MIU2",
      "actionTitle": "Replace Meter and Add MIU 1 and MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "I",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "312": {
      "actionDescription": "REPLACE_METER_AND_ADD_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Replace Meter and Add MIU 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "I",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "313": {
      "actionDescription": "REPLACE_METER_AND_ADD_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Replace Meter and Add MIU 1 and Remove MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "I",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "314": {
      "actionDescription": "REPLACE_METER_AND_ADD_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "Replace Meter and Add MIU 1 and Downgrade MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "I",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "320": {
      "actionDescription": "REPLACE_METER_AND_MIU1",
      "actionTitle": "Replace Meter and MIU 1",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "E",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "321": {
      "actionDescription": "REPLACE_METER_AND_REPLACE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Replace Meter and Replace MIU 1 and Add MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "E",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "x",
      "interfaceName": "DRR"
    },
    "322": {
      "actionDescription": "REPLACE_METER_AND_MIU1_AND_MIU2",
      "actionTitle": "Replace Meter and MIU 1 and MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "E",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "323": {
      "actionDescription": "REPLACE_METER_AND_REPLACE_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Replace Meter and Replace MIU 1 and Remove MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "E",
      "mIU2Flag": "R",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "324": {
      "actionDescription": "REPLACE_METER_AND_REPLACE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "Replace Meter and Replace MIU 1 and Downgrade MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "E",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "330": {
      "actionDescription": "REPLACE_METER_AND_REMOVE_MIU1",
      "actionTitle": "Replace Meter and Remove MIU 1",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "R",
      "mIU2Flag": "X",
      "isValid": "false",
      "message": "This combination is not permitted.",
      "interfaceName": "DRR"
    },
    "331": {
      "actionDescription": "REPLACE_METER_AND_REMOVE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Replace Meter and Remove MIU 1 and Add MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "R",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "332": {
      "actionDescription": "REPLACE_METER_AND_REMOVE_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Replace Meter and Remove MIU 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "R",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "333": {
      "actionDescription": "REPLACE_METER_REMOVE_MIU1_REMOVE_MIU2",
      "actionTitle": "Replace Meter Remove miu1 and miu2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "R",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted.",
      "interfaceName": "DRR"
    },
    "334": {
      "actionDescription": "REPLACE_METER_AND_REMOVE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "Replace Meter and Remove MIU 1 and Downgrade MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "R",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "340": {
      "actionDescription": "REPLACE_METER_AND_DOWNGRADE_TP1",
      "actionTitle": "Replace Meter and Install Touchpad/Keyed",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "T",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "341": {
      "actionDescription": "REPLACE_METER_AND_DOWNGRADE_TP1_AND_ADD_MIU2",
      "actionTitle": "Replace Meter and Downgrade MIU 1 and Add MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "T",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "342": {
      "actionDescription": "REPLACE_METER_AND_DOWNGRADE_TP1_AND_REPLACE_MIU2",
      "actionTitle": "Replace Meter and Change to  Touchpad 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "T",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "343": {
      "actionDescription": "REPLACE_METER_AND_DOWNGRADE_TP1_AND_REMOVE_MIU2",
      "actionTitle": "Replace Meter and Downgrade MIU 1 and Remove MIU 2",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "T",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "344": {
      "actionDescription": "REPLACE_METER_AND_DOWNGRADE_TP1_AND_DOWNGRADE_TP2",
      "actionTitle": "Replace Meter and Install Touchpad",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "T",
      "mIU2Flag": "T",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "400": {
      "actionDescription": "UPGRADE_TO_COMPOUND/DOWNGRADE_TO_SINGLE",
      "actionTitle": "Upgrade to Compound Meter",
      "steps": [
        "outgoingRead",
        "newMeterNumber",
        "meterReason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "E",
      "mIU1Flag": "X",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "500": {
      "actionDescription": "REMOVE_METER_ONLY",
      "actionTitle": "Remove Meter",
      "steps": [
        "outgoingRead",
        "meterReason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "X",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "501": {
      "actionDescription": "REMOVE_METER_AND_ADD_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "R",
      "mIU1Flag": "X",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": ""
    },
    "502": {
      "actionDescription": "REMOVE_METER_AND_REPLACE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "R",
      "mIU1Flag": "X",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": ""
    },
    "503": {
      "actionDescription": "REMOVE_METER_AND_MIU2",
      "actionTitle": "Remove Meter and MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "X",
      "mIU2Flag": "R",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "504": {
      "actionDescription": "REMOVE_METER_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "R",
      "mIU1Flag": "X",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": ""
    },
    "510": {
      "actionDescription": "REMOVE_METER_AND_ADD_MIU1",
      "actionTitle": "Remove Meter and Add MIU 1",
      "steps": [
        "outgoingRead",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "I",
      "mIU2Flag": "X",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "511": {
      "actionDescription": "REMOVE_METER_AND_ADD_MIU1_AND_ADD_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "R",
      "mIU1Flag": "I",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": ""
    },
    "512": {
      "actionDescription": "REMOVE_METER_AND_ADD_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Remove Meter and Add MIU 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "I",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "513": {
      "actionDescription": "REMOVE_METER_AND_ADD_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Remove Meter and Add MIU 1 and Remove MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "I",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "514": {
      "actionDescription": "REMOVE_METER_AND_ADD_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "Remove Meter and Add MIU 1 and Downgrade MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "I",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "520": {
      "actionDescription": "REMOVE_METER_AND_REPLACE_MIU1",
      "actionTitle": "Remove Meter and Replace MIU 1",
      "steps": [
        "outgoingRead",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "E",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "521": {
      "actionDescription": "REMOVE_METER_AND_REPLACE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Remove Meter and Replace MIIU 1 and Add MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "E",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "522": {
      "actionDescription": "REMOVE_METER_AND_REPLACE_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "R",
      "mIU1Flag": "E",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "This combination is not permitted.",
      "interfaceName": ""
    },
    "523": {
      "actionDescription": "REMOVE_METER_AND_REPLACE_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Remove Meter and Replace MIU 1 and Remove MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "E",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "524": {
      "actionDescription": "REMOVE_METER_AND_REPLACE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "Remove Meter and Replace MIU 1 and Downgrade MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "E",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "530": {
      "actionDescription": "REMOVE_METER_AND_MIU1",
      "actionTitle": "Remove Meter and MIU 1",
      "steps": [
        "outgoingRead",
        "meterReason",
        "MIU1Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "R",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "531": {
      "actionDescription": "REMOVE_METER_AND_REMOVE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Remove Meter and Remove MIU 1 and Add MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "R",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "532": {
      "actionDescription": "REMOVE_METER_AND_REMOVE_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Remove Meter and Remove MIU 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "R",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "533": {
      "actionDescription": "REMOVE_METER_AND_MIU1_AND_MIU2",
      "actionTitle": "Remove Meter and MIU 1 and MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "MIU1Reason",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "R",
      "mIU2Flag": "R",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "534": {
      "actionDescription": "REMOVE_METER_AND_REMOVE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "Remove Meter and Remove MIU 1 and Downgrade MIU2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "R",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "540": {
      "actionDescription": "REMOVE_METER_AND_DOWNGRADE_TP1",
      "actionTitle": "Remove Meter and Change to Touchpad/Keyed",
      "steps": [
        "outgoingRead",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "T",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "541": {
      "actionDescription": "REMOVE_METER_AND_DOWNGRADE_TP1_AND_ADD_MIU2",
      "actionTitle": "Remove Meter and Downgrade MIU 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "T",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "542": {
      "actionDescription": "REMOVE_METER_AND_DOWNGRADE_TP1_AND_REPLACE_MIU2",
      "actionTitle": "Remove Meter and Downgrade MIU 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "T",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "543": {
      "actionDescription": "REMOVE_METER_AND_DOWNGRADE_TP1_AND_REMOVE_MIU2",
      "actionTitle": "Remove Meter and Downgrade MIU 1 and Remove MIU 2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "T",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "544": {
      "actionDescription": "REMOVE_METER_AND_DOWNGRADE_TP1_AND_DOWNGRADE_TP2",
      "actionTitle": "Remove Meter and Downgrade MIU 1 and Downgrade MIU2",
      "steps": [
        "outgoingRead",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "R",
      "mIU1Flag": "T",
      "mIU2Flag": "T",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "600": {
      "actionDescription": "REGISTER_CHANGE",
      "actionTitle": "Change Register",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "X",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "601": {
      "actionDescription": "CHANGE_REGISTER_AND_ADD_MIU2",
      "actionTitle": "Change Register and Add MIU",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "X",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "602": {
      "actionDescription": "CHANGE_REGISTER_AND_REPLACE_MIU2",
      "actionTitle": "Change Register and Replace MIU2",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "X",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "603": {
      "actionDescription": "CHANGE_REGISTER_AND_REMOVE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "G",
      "mIU1Flag": "X",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": ""
    },
    "604": {
      "actionDescription": "CHANGE_REGISTER_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "G",
      "mIU1Flag": "X",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "610": {
      "actionDescription": "CHANGE_REGISTER_AND_ADD_MIU1",
      "actionTitle": "Change Register and Add MIU 1",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "I",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "611": {
      "actionDescription": "CHANGE_REGISTER_AND_ADD_MIU1_AND_ADD_MIU2",
      "actionTitle": "Change Register and Add MIU 1 & 2",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "I",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "612": {
      "actionDescription": "CHANGE_REGISTER_AND_ADD_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Change Register and Add MIU 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "I",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "613": {
      "actionDescription": "CHANGE_REGISTER_AND_ADD_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Change Register and Add MIU 1 and Remove MIU 2",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "I",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "614": {
      "actionDescription": "CHANGE_REGISTER_AND_ADD_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "G",
      "mIU1Flag": "I",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "620": {
      "actionDescription": "CHANGE_REGISTER_AND_REPLACE_MIU1",
      "actionTitle": "Change Register and Replace MIU 1",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "E",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "621": {
      "actionDescription": "CHANGE_REGISTER_AND_REPLACE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Change Register and Replace MIU 1 and Add MIU 2",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "E",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "622": {
      "actionDescription": "CHANGE_REGISTER_AND_REPLACE_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Change Register and Replace MIU 1 and MIU 2",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "E",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "623": {
      "actionDescription": "CHANGE_REGISTER_AND_REPLACE_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Change Register and Replace MIU 1 and Remove MIU 2",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "E",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "624": {
      "actionDescription": "CHANGE_REGISTER_AND_REPLACE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "G",
      "mIU1Flag": "E",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "630": {
      "actionDescription": "CHANGE_REGISTER_AND_REMOVE_MIU1",
      "actionTitle": "Change Register and Remove MIU 1",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "R",
      "mIU2Flag": "X",
      "isValid": "false",
      "message": "This combination is not permitted.",
      "interfaceName": "DRR"
    },
    "631": {
      "actionDescription": "CHANGE_REGISTER_AND_REMOVE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Change Register and Remove MIU 1 and Add MIU 2",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "R",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "632": {
      "actionDescription": "CHANGE_REGISTER_AND_REMOVE_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Change Register and Remove MIU 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "R",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "This combination is not permitted - You must have 2 Endpoints installed or none installed.",
      "interfaceName": "DRR"
    },
    "633": {
      "actionDescription": "CHANGE_REGISTER_METER_AND_REMOVE_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "G",
      "mIU1Flag": "R",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted.",
      "interfaceName": ""
    },
    "634": {
      "actionDescription": "CHANGE_REGISTER_METER_AND_REMOVE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "G",
      "mIU1Flag": "R",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "640": {
      "actionDescription": "CHANGE_REGISTER_AND_DOWNGRADE_TP1",
      "actionTitle": "Change Register and Touchpad",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "T",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "641": {
      "actionDescription": "CHANGE_REGISTER_METER_AND_DOWNGRADE_TP1_AND_ADD_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "G",
      "mIU1Flag": "T",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "642": {
      "actionDescription": "CHANGE_REGISTER_METER_AND_DOWNGRADE_TP1_AND_REPLACE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "G",
      "mIU1Flag": "T",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "643": {
      "actionDescription": "CHANGE_REGISTER_METER_AND_DOWNGRADE_TP1_AND_REMOVE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "G",
      "mIU1Flag": "T",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "644": {
      "actionDescription": "CHANGE_REGISTER_AND_DOWNGRADE_TP1_AND_DOWNGRADE_TP2",
      "actionTitle": "Change Register and change MIUs to Touchpad/Keyed",
      "steps": [
        "outgoingRead",
        "register",
        "meterReason",
        "TP1",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "G",
      "mIU1Flag": "T",
      "mIU2Flag": "T",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "001": {
      "actionDescription": "ADD_MIU2",
      "actionTitle": "Add MIU 2",
      "steps": [
        "outgoingRead",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "X",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "002": {
      "actionDescription": "REPLACE_MIU2",
      "actionTitle": "Replace MIU 2",
      "steps": [
        "outgoingRead",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "X",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "003": {
      "actionDescription": "REMOVE_MIU2_ONLY",
      "actionTitle": "Remove MIU 2",
      "steps": [
        "outgoingRead",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "X",
      "mIU2Flag": "R",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "004": {
      "actionDescription": "DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "X",
      "mIU1Flag": "X",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "010": {
      "actionDescription": "ADD_MIU1",
      "actionTitle": "Add MIU",
      "steps": [
        "outgoingRead",
        "newMIU1Number",
        "MIU1Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "I",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "011": {
      "actionDescription": "ADD_MIU1_AND_MIU2",
      "actionTitle": "Add MIU 1 and MIU 2",
      "steps": [
        "outgoingRead",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "I",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "NSI"
    },
    "012": {
      "actionDescription": "ADD_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Add MIU 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "I",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "013": {
      "actionDescription": "ADD_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Add MIU 1 and Remove MIU2",
      "steps": [
        "outgoingRead",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "I",
      "mIU2Flag": "R",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "014": {
      "actionDescription": "ADD_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "X",
      "mIU1Flag": "I",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "020": {
      "actionDescription": "REPLACE_MIU1",
      "actionTitle": "Replace MIU",
      "steps": [
        "outgoingRead",
        "newMIU1Number",
        "MIU1Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "E",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "021": {
      "actionDescription": "REPLACE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Replace MIU 1 and Add MIU 2",
      "steps": [
        "outgoingRead",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "E",
      "mIU2Flag": "I",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "022": {
      "actionDescription": "REPLACE_MIU1_AND_MIU2",
      "actionTitle": "Replace MIU 1 and MIU 2",
      "steps": [
        "outgoingRead",
        "newMIU1Number",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "E",
      "mIU2Flag": "E",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "023": {
      "actionDescription": "REPLACE_MIU1_AND_REMOVE_MIU2",
      "actionTitle": "Replace MIU 1 and Remove MIU 2",
      "steps": [
        "outgoingRead",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "E",
      "mIU2Flag": "R",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "024": {
      "actionDescription": "REPLACE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "X",
      "mIU1Flag": "E",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "030": {
      "actionDescription": "REMOVE_MIU1_ONLY",
      "actionTitle": "Remove MIU",
      "steps": [
        "outgoingRead",
        "MIU1Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "R",
      "mIU2Flag": "X",
      "isValid": "false",
      "message": "This combination is not permitted.",
      "interfaceName": "DRR"
    },
    "031": {
      "actionDescription": "REMOVE_MIU1_AND_ADD_MIU2",
      "actionTitle": "Remove MIU 1 and Add MIU 2",
      "steps": [
        "outgoingRead",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "R",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "032": {
      "actionDescription": "REMOVE_MIU1_AND_REPLACE_MIU2",
      "actionTitle": "Remove MIU 1 and Replace MIU 2",
      "steps": [
        "outgoingRead",
        "MIU1Reason",
        "newMIU2Number",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "R",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": "DRR"
    },
    "033": {
      "actionDescription": "REMOVE_MIU1_AND_MIU2_ONLY",
      "actionTitle": "Remove MIU 1 and MIU 2",
      "steps": [
        "outgoingRead",
        "MIU1Reason",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "R",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "This combination is not permitted.",
      "interfaceName": "DRR"
    },
    "034": {
      "actionDescription": "REMOVE_MIU1_AND_DOWNGRADE_TP2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "X",
      "mIU1Flag": "R",
      "mIU2Flag": "T",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "040": {
      "actionDescription": "DOWNGRADE_TP1",
      "actionTitle": "Change MIU to Touchpad/Keyed",
      "steps": [
        "outgoingRead",
        "TP1",
        "MIU1Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "T",
      "mIU2Flag": "X",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "041": {
      "actionDescription": "DOWNGRADE_TP1_AND_ADD_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "X",
      "mIU1Flag": "T",
      "mIU2Flag": "I",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "042": {
      "actionDescription": "DOWNGRADE_TP1_AND_REPLACE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "X",
      "mIU1Flag": "T",
      "mIU2Flag": "E",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "043": {
      "actionDescription": "DOWNGRADE_TP1_AND_REMOVE_MIU2",
      "actionTitle": "",
      "steps": [
        ""
      ],
      "meterFlag": "X",
      "mIU1Flag": "T",
      "mIU2Flag": "R",
      "isValid": "false",
      "message": "You cannot have mixed endpoints - both must match (both MIU, TP or Keyed).",
      "interfaceName": ""
    },
    "044": {
      "actionDescription": "DOWNGRADE_TP1_AND_DOWNGRADE_TP2",
      "actionTitle": "Change MIUs to Touchpad/Keyed",
      "steps": [
        "outgoingRead",
        "TP1",
        "MIU1Reason",
        "TP2",
        "MIU2Reason",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "T",
      "mIU2Flag": "T",
      "isValid": "true",
      "message": "",
      "interfaceName": "DRR"
    },
    "NMM": {
      "actionDescription": "NO_METER",
      "actionTitle": "No meter",
      "steps": [
      ],
      "meterFlag": "N",
      "mIU1Flag": "X",
      "mIU2Flag": "S",
      "isValid": "true",
      "message": "",
      "interfaceName": "MISC"
    }
  }
    /**
   * @name getActionDetails
   * @param code three digit string
   * @param isDual is compound meter or not 
   * @description gets the details of the code 
   * @returns  ex :{
      "actionDescription": "actionDescription",
      "actionTitle": "actionTitle",
      "steps": [
        "outgoingRead",
        "newMIU1Number",
        "MIU1Reason",
        "MIU2Reason",
        "readNewMeter",
        "review"
      ],
      "meterFlag": "X",
      "mIU1Flag": "E",
      "mIU2Flag": "R",
      "isValid": true,
      "message": ""
    } or null
   */ 
  getActionDetails(code:string, isDual,isMeterAvailable) {
    
    if(this.meterActions[code])
    {
      if(isDual){
        return this.checkExceptions(code,isMeterAvailable);
      }
      else{
        const temp = _.cloneDeep(this.checkExceptions(code,isMeterAvailable));
          temp.mIU2Flag = "S";
          return  temp;
      }
    }
    else return null;
  }
  checkExceptions(code,isMeterAvailable){
    if((code=="030"||code=="033")&&!isMeterAvailable){
      const temp = _.cloneDeep(this.meterActions[code]);
      temp.isValid = "true";
      return temp
    }
    return this.meterActions[code]
  }
}
