import { flatMap } from "lodash";
import { DEFAULT_MAX_DIALS } from "../app.config";
// import * as moment from 'moment';

export interface Device {
    [key: string]: {
        id: string;
        name: string;
        directional: string;
        supplemental: string;
        position: string;
        img: string;
        selected: boolean;
    }
}

export interface Alert {
    label: string;
    class: string;
    url: string;
    selectedurl: string;
}
export interface LockPlug {
    label: string;
    url: string;
    value: string;
}

export interface DevicePostion {
    value: string;
    label: string;
}

export interface SecureAccess {
    value: string;
    label: string;
}

export interface HeatType {
    id: string;
    label: string;
    class: string;
}
export interface ActionVal {
    label: string;
    value: string;
}

export interface Tile {
    content: string;
    colspan: number;
    rowspan: number;
    class: string;
    data: Device[];
    meterDirectional: 'RL' | 'RE' | 'FL' | 'FS' | 'LR' | 'RA' | 'LF' | 'RF';
    meterSupplemental: 'IS' | 'OS';
}

export interface AppState {
    isUpdate: boolean;
    value: string;
}

export interface Activity {
    description: string;
}



export interface TestResult {
    registerId: string;
    lowMedHighIndicator: string;
    initialRepair: string;
    accuracy: string;
    calculatedVolume: string;
    testFlowRate: string;
    startRead: string;
    endRead: string;
    controlMeterUOM: string;
    registerUOM: string;
    result: string;
}

export interface Completion1 {
    workOrderNumber: string;
    miscInvoice: string;
    backOfficeReview: string;
    backOfficeComments: string;
    completionStatus: string;
    notes: string;
    activities: Activity[];
    additionalWorkNeeded: string;
    purpose: string;
    technicalInspectedOn: string;
    technicalInspectedBy: string;
    serviceFound: string;
    serviceLeft: string;
    operatedPointOfControl: string;
    additionalInformation: string;
    curbBoxMeasurementDescription: string;
    safety: string;
    heatType: string;
    meterPositionLocation: string;
    meterDirectionalLocation: string;
    meterSupplementalLocation: string;
    readingDevicePositionalLocation: string;
    readingDeviceSupplementalLocation: string;
    readingDeviceDirectionalLocation: string;
    fSRId: string;
    fSRInteraction: string;
    serialNumber: string;
    oldMeterSerialNumber: string;
    registers: REGISTER[];
    meterSerialNumber: string;
    manufacturerSerialNumber: string;
    deviceCategory: string;
    installation: string;
    actionFlag: string;
    activityReason: string;
    rating: string;
    qualityIssues: any[];
    inspectionDate: string;
    inspectionPassed: string;
    internalLeadPipingIndicator: string;
    customerSideMaterial: string;
    leadInspectionDate: string;
    leadInspectedBy: string;
    violationCodes: any[];
    register1ReasonCode: string;
    register2ReasonCode: string;
    investigationExpiryDate: string;
    notificationItemText: string;
    latitude: string;
    longitude: string;
    needTwoManCrew: string;
    leakDetectedNonCompany: string;
    leakDetectedDate: string;
    testResults: TestResult[];
    secureAccess: string;
}

export class Update {
    statusNumber: string = "";
    statusNonNumber: string = "";
    statusNotes: string = "";
    itemTimeStamp: string = "";
    assignmentStart: string = "";
    assignmentEnd: string = "";
    workOrderNumber: string = "";
    assignedEngineer: string = "";
    engineerId: string = "";
    operationNumber: string = "";
    dispatchId: string = "";
}

export interface Data1 {
    completion: Completion1[];
    update: Update;
}
export interface selectedActivity {
    meter: number | null;
    activityOption: any;
}
export interface VerifictionType{
    meter:string|null,
    miu1:string|null,
    miu2:String|null
}
export class Equipment {
    workOrderNumber: string = "";
    ams:string="";
    equipmentId: string = "";
    processingIndicator: string = "";
    equipmentCategory: string = "";
    deviceLocation: string = "";
    installation: string = "";
    serviceType: string = "";
    dualtoSingle: boolean = false;
    installationType: string = "";
    latitude: number = 0;
    longitude: number = 0;
    serialNumber: string = "";
    manufacturerSerialNumber: string = "";
    meterManufacturer: string = "";
    meterSize: string = "";
    meterSizeVal: string = "";
    meterSizeVal2: string = "";
    serviceLineSize: any;
    meterLocation: string = "";
    positionalLocation: string = "";
    directionalLocation: string = "";
    supplementalLocation: string = "";
    miuNumber1: string = "";
    miu1DeviceCategory: string = "";
    miuNumber1WsDate: string = "";
    miuNumber1WeDate: string = "";
    miuNumber1DataPlan: string = "";
    miuNumber2DataPlan: string = "";
    miuNumber2: string = "";
    miu2DeviceCategory: string = "";
    miuNumber2WsDate: string = "";
    miuNumber2WeDate: string = "";
    readType: string = "";
    readType2: string = "";
    meterReading: number = 0;
    meterReading2: number = 0;
    unitForReading: string = "";
    unitForReading2: string = "";
    meterReadingUnit?: any;
    rfId?: any;
    rfMiu: boolean = false;
    tpEndcoder: boolean = false;
    isAPIRead: boolean = false;
    encoderId?: any;
    encoderId2?: any;
    typeShortDescription: string = "";
    readTypeDescription: string = "";
    readTypeDescription2: string = "";
    deviceLocationNotes: string[] = [];
    pointOfControlNotes: any[] = [];
    compoundMeter: string = "No";
    numberOfDials: string = DEFAULT_MAX_DIALS;
    numberOfDials2: string = DEFAULT_MAX_DIALS;
    registerId: string = "";
    registerId2: string = "";
    registerGroup: string = "";
    readingDeviceDirectionalLocation: string = "";
    readingDevicePositionalLocation: string = "";
    readingDeviceSupplementalLocation: string = "";
    sapErrorCode: string = "";
    problemMeter: boolean = false;
    problemMeterDescription?: any;
    readDate: string = "";
    readDate2: string = "";
    amiDevice: boolean = false;
    isAMIDevice: boolean = false;
    meterReadType: string = "";
    meterReadType2: string = "";
    secureAccess: string = "";
    meterReadings: Array<any> = [];
    meterReadings2: Array<any> = [];
    addedMeter: boolean = false;
    removedMeter: boolean = false;
    incomingMeter: boolean = false;
    outgoingMeter: boolean = false;
    oldNumberMeter: string = "";
    siteId: string = "";
    addedMIU: boolean = false;
    removedMIU: boolean = false;
    incomingMIU: boolean = false;
    outgoingMIU: boolean = false;
    oldNumberMIU: string = "";
    oldNumberMIU2: string = "";
    device: string = "";
    sewer: boolean = false;
    noMeterInfo: boolean = false;
    dummy: boolean = false;
    fixedNoChargeMeter: boolean = false;
    noRead: boolean = false;
    noRead2: boolean = false;
    fetchRead: boolean = false;
    activities: Array<string> = [];
    meterAction: string = "";
    miuAction: string = "";
    miu2Action: string = "";
    meterFlag: string = "";
    miu1Flag: string = "";
    miu2Flag: string = "";
    replaceError: string = "";
    deviceCategory: string = "";
    errors = new ERRORS();
    activityNotes: string[] = [];
    miuActivityNotes: any = {};
    meterActivityNotes: any = {};
    maintainActivityNotes: any[] = [];
    readingNotes: string[] = [];
    oldRead: string = '';
    oldRead2: string = '';
    sourceOfRead1: string = '01';
    sourceOfRead2: string = '01';
    noReadReason1:string="";
    noReadReason2:string="";
    replaceRegister1: boolean = false;
    replaceRegister2: boolean = false;
    activePSTOrder: boolean = true;
    register1ReasonCode: string = "";
    register2ReasonCode: string = "";
    largeMeterConfig: any[] = [];
    disabledMeterForProcessingIndicator: boolean = false;
    oldEquipmentObject?:Equipment=undefined;
    activityPerformed:any=undefined;
    verificationType:VerifictionType={
        meter:null,
        miu1:null,
        miu2:null
    }
}
export class ERRORS {
    'Maintain_Activities': boolean = false;
    'Meter_Action': boolean = false;
    'MIU_Action': boolean = false;
}

export const CAPTURE_FORM_SCHEMA = {
    'workOrderNumber': '',
    'miscInvoice': '',
    'backOfficeReview': '',
    'completionStatus': '',
    'notes': '',
    'activities': [],
    'additionalWorkNeeded': '',
    'purpose': '',
    'technicalInspectedOn': '', //moment().format('YYYYMMDD'),
    'technicalInspectedBy': '',
    'serviceFound': '',
    'serviceLeft': '',
    'operatedPointOfControl': '',
    'additionalInformation': '',
    'curbBoxMeasurementDescription': '',
    'safety': '',
    'heatType': 'N',
    'meterPositionLocation': '',
    'meterDirectionalLocation': '',
    'meterSupplementalLocation': '',
    'readingDevicePositionalLocation': '',
    'readingDeviceSupplementalLocation': '',
    'readingDeviceDirectionalLocation': '',
    'fSRId': '',
    'fSRInteraction': '',
    'serialNumber': '',
    'oldMeterSerialNumber': '',
    'registers': [
        {
            'size': '',
            'mIUNumber': '',
            'encoderId': '',
            'oldRead': '',
            'newRead': '',
            'readType': '',
            'oldSourceofRead': '',
            'newSourceofRead': '',
            'dials': ''
        }
    ],
    'meterSerialNumber': '',
    'manufacturerSerialNumber': '',
    'deviceCategoryMeter': '',
    'deviceCategoryMIU': '',
    'installation': '',
    'actionFlagMeter': '',
    'actionFlagMIU': '',
    'activityReasonMeter': '',
    'activityReasonMIU': '',
    'rating': '',
    'qualityIssues': [],
    'inspectionDate': '',
    'inspectionPassed': '',
    'internalLeadPipingIndicator': '',
    'customerSideMaterial': '',
    'leadInspectionDate': '',
    'leadInspectedBy': '',
    'violationCodes': [],
    'register1ReasonCode': '',
    'register2ReasonCode': '',
    'investigationExpiryDate': '',
    'notificationItemText': '',
    'latitude': '',
    'longitude': '',
    'needTwoManCrew': '',
    'leakDetectedNonCompany': '',
    'leakDetectedDate': '',
    'testResults': [{
        'registerId': '',
        'lowMedHighIndicator': '',
        'initialRepair': '',
        'accuracy': '',
        'calculatedVolume': '',
        'testFlowRate': '',
        'startRead': '',
        'endRead': '',
        'controlMeterUOM': '',
        'registerUOM': ''
    }, {
        'registerId': '',
        'lowMedHighIndicator': '',
        'initialRepair': '',
        'accuracy': '',
        'calculatedVolume': '',
        'testFlowRate': '',
        'startRead': '',
        'endRead': '',
        'controlMeterUOM': '',
        'registerUOM': ''
    }]
};


export class CAPTURE_FORM_OBJECT {

    meterPositionLocation: string = "";
    meterDirectionalLocation: string = "";
    meterSupplementalLocation: string = "";
    readingDevicePositionalLocation: string = "";
    readingDeviceSupplementalLocation: string = "";
    readingDeviceDirectionalLocation: string = "";
    secureAccess: string = "";
    curbBoxMeasurementDescription: string = "";//control point location notes
    safety: string = "";//alerts list follwoed by safety notes   e.g "poison,bee,notes from safety here"
    heatType: string = "";
    lockPlugMeter: string = "";

    // serialNumber: string = "";//removed in new schema
    oldMeterSerialNumber: string = "";
    registers: REGISTER[] = [];
    meterSerialNumber: string = "";
    manufacturerSerialNumber: string = "";
    // deviceCategory: string = "";//removed and renamed below
    installation: string = "";
    // actionFlag: string = "";//remove from old scma with renamed one below
    additionalInformation: string = "";


    serviceFound: string = "";
    serviceLeft: string = "";
    operatedPointOfControl: string = ""; // 'C01 (Yes)' : 'C02 (No)'
    activities: Array<any> = [];//maintain activities [{description:'activitycode'},{description:'activitycode'}] and company codes and csutomer codes
    // activityReason: string = "";//reason in case of remove,replace,install




    customerSideMaterial: string = "";//if yse to is customer service line inspected
    internalLeadPipingIndicator: string = "";//y in case of service line N for no
    fSRInteraction: string = "";//if yes to customer service side leak

    additionalWorkNeeded: string = "";//followup reason
    purpose: string = "";//followup purpose


    completionStatus: string = "";//inc or comp
    rating: string = "";//rating stars at incomplete
    qualityIssues: string[] = [];//string array in form [{qualityIssue: "Q08"}, {qualityIssue: "Q14"}]



    //readonly

    workOrderNumber: string = "";
    miscInvoice: string = "";
    backOfficeReview: string = "";
    backOfficeComments: string = "";
    register1ReasonCode: string = "";
    register2ReasonCode: string = "";
    investigationExpiryDate: string = "";
    notes: string = "";
    inspectionDate: string = "";
    inspectionPassed: string = "";
    leadInspectionDate: string = "";
    leadInspectedBy: string = "";
    violationCodes: string[] = [];
    notificationItemText: string = "";
    latitude: string = "";
    longitude: string = "";
    needTwoManCrew: string = "";
    leakDetectedNonCompany: string = "";
    leakDetectedDate: string = "";
    technicalInspectedOn: string = "";
    technicalInspectedBy: string = "";
    fSRId: string = "";
    testResults: any[] = [];
    testResults1!: [{

        "registerId": "";

        "lowMedHighIndicator": "";

        "initialRepair": "";

        "accuracy": "";

        "calculatedVolume": "";

        "testFlowRate": "";

        "startRead": "";

        "endRead": "";

        "controlMeterUOM": "";

        "registerUOM": "";

        "result": "";

    }];

    //newly added fields below:
    sourceOfRead: string = "";//23 if read is entered manually |    25 in case read is fetched from api |    blank in case of no read obtained
    meterDeviceCategory: string = "";
    actionFlagMeter: string = "";//'I'-install,defalt-X
    //actionFlagMIU: string = "";//I for install,default-X

    activityReasonMeter: string = "";
    //activityReasonMIU: string = "";

    //new inspection tab fields
    filterPitcherorCartridge: string ="";
    filterPitcherorCartridgeOtherReason:  string ="";
    customerInteraction :  string ="";
    leaveLeadInformationMaterial:  string ="";

}

export class REGISTER {
    size: string = "";
    encoderId: string = "";
    oldRead: string = "";
    readType: string = "";
    newRead: string = "";
    dials: string = "";
    mIUSerialNumber: string = "";
    mIUDeviceCategory: string = "";
    oldMIUSerialNumber: string = "";
    oldMIUDevice: string = "";
    newSourceofRead: string = '';
    oldSourceofRead: string = '';
    uom:string='';
    activityReasonMIU:string='';
    regType:string='';
}
export class LARGE_METER_OBJ {
    registerId: string = "";
    lowMedHighIndicator: string = "";
    initialRepair: string = "";
    accuracy: string = "";
    calculatedVolume: string = "";
    testFlowRate: string = "";
    startRead: string = "";
    endRead: string = "";
    controlMeterUOM: string = "";
    registerUOM: string = "";
    result: string = "";
}

export class REGISTERACTION {
    actionFlagMeter: string = "";
    actionFlagMIU1: string = "";
    actionFlagMIU2:string= "";
    sourceOfRead: string = "";
    meterDeviceCategory: string = "";
    meterSerialNumber: string = "";
    oldMeterSerialNumber: string = "";
    manufacturerSerialNumber: string = "";
    sapInterface:string="";
    meterAction: string = "";
    //miuAction: string = "";
    installation: string = "";
    registers:REGISTER[]=[];
    register1ReasonCode: string = "";
    register2ReasonCode: string = "";
    testResults: LARGE_METER_OBJ[] = [];
}
 export interface MIU{ //First miu 
    endpointType:string,//basic read type 
    newManufacturer:string, //enum 
    newSerialNumber:string,
    oldSerialNumber:string,
    newDeviceCategory:string,
    oldDeviceCategory:string,
    newRead:string,
    oldRead:string,
    newSourceOfRead:string,
    oldSourceOfRead:string,
    actionCode:string,
    actionReason:string,
    registerType:string, //for dual meter scenarios 
    registerReasonCode:string, //enum, RZC only. Should we consolidate into "actionCode"? 
    size:string,
    dials:string,
    unitOfMeasure:string,
    encoderId: string
}
 export interface METERS
{
    devLocationId: string, //v1.3  
    installationId: string, //v1.3 from equipment array 
    newManufacturer: string, //enum 
    newSerialNumber: string,
    oldSerialNumber: string,
    newCategory:string, //enum 
    oldCategory: string, //enum 
    actionCode: string, //enum. "actionFlag" -> "actionCode" 
    actionReason: string, //enum. "activityReason" -> "actionReason" 
    isLeakDetectedNonCompany:boolean, //bool (true = Y, false = N) 
    customerSideLeakInteraction:string, //enum 01,02,03, blank (this is when FSR leaves door hanger etc...) 
    pointOfControlDesc:string, //free text 
    isInternalLeadPiping:boolean, //bool (true = Y, false = N) 
    customerSideMaterial:string, //enum 
    isLeadInfoProvided:boolean, //boolean true = YES, false = NO  
    isTwoPersonCrew:boolean, //bool (true = Y, false = N) 
    functionalLocLongText:string, //device location notes 
    safetyLongText:string,
    meterPositionalLocation:string,
    meterDirectionalLocation:string,
    meterSupplementalLocation:string,
    readingDevicePositionalLocation:string,
    readingDeviceSupplementalLocation:string,
    readingDeviceDirectionalLocation:string,
    secureAccess:string, //enum 00,01,02,03,04,05 
    lockPlugMeter:string, //enum 01,02,03,04,05 
    heatType:string, //enum N (non-hotwater), U (unknown), Y (hot water), blank (no entry) 
    investigationExpiryDate:string, //utc (RZC only, otherwise blank) 
    isInspectionPassed:string, //enum Y, N, blank 
    violationCode:string, //comma seperated enum 1-11 
    isFilterPitcherOrCartridgeProvided:boolean, //boolean true = YES, false = NO, default false 
    filterPitcherOrCartridgeInteraction:string, //enum 'HANDED TO CUSTOMER','LEFT ON PORCH / DOORSTEP','OTHER'. 
    filterPitcherorCartridgeOtherReason:string,
    miu: MIU[],//array needed for dual meter scenarios,
    largeMeterTest:LARGE_METER_OBJ[],
    
}
 interface SOCOMPLETIONOBJECT {
    isOffline: boolean, //Boolean 
    workOrderId:number,
    fsrId: number, //employee id 
    fsrUsername:string, //network id 
    fsrFullName: string,
    companyName: string,
    status: string, //enum 
    isMiscInvoice:boolean, //boolean 
    isBackOfficeReview: boolean, //boolean 
    backOfficeComments: string, //varchar 
    fsrComments: string, //varchar 
    installationId: string, // from workorder object needed for nometer scneario
    fsrTimezone:string, //enum 
    startTimestamp: string, //utc  
    endTimestamp: string, //utc 
    additionalWorkNeeded: string, //enum 
    serviceFound: string, //enum 
    serviceLeft: string, //enum 
    purpose:string, //enum 
    workInformationQualityIssue:string, //comma separated enum Q01-Q15 
    activityUpdate: string, //comma seperated enum (same as primary actionCode?) 
    operatedPointOfControl: string, //enum C01,C02 
    funoCode: string, //TBD 
    funoReason:string, //TBD 
    meter: METERS[],
    premiseId:string
}
export interface SOCOMPLETIONPAYLOAD{
    completion:SOCOMPLETIONOBJECT,
    update:Update | null
}

