<div class="container">
  <app-diagnostic-page></app-diagnostic-page>
  <button class="flow-btn" (click)="switchFunctionality()">{{!basic ? 'Normal' : 'Transitions'}}</button>
  <ng-container *ngIf="!basic">
    <h2>TRANSITIONS</h2>
    <div class="fix-transitions">
      <div class="message">Records Count: {{transitions.length}}</div>
      <div *ngFor="let msg of transMsg" class="status-msg">{{msg}}</div>
      <button *ngIf="transitions.length > 0 && !executing" class="flow-btn" (click)="startExecuting()">Start</button>
    </div>
  </ng-container>
  <ng-container *ngIf="basic">
    <h2>INDEXED-DB</h2>
    <div class="index">
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Complete</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of complete">
              <mat-expansion-panel-header>
                <mat-panel-title>Order Number: {{ data.order }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data.data | json }}
              </div>
            </mat-expansion-panel>
            <div class="no-items" *ngIf="!complete || complete.length === 0">
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Inspecetion</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of inspection">
              <mat-expansion-panel-header>
                <mat-panel-title>Order Number: {{ data.order }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data.data | json }}
              </div>
            </mat-expansion-panel>
            <div class="no-items" *ngIf="!inspection || inspection.length === 0">
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Large Meter Config</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of largeMeterConfig">
              <mat-expansion-panel-header>
                <mat-panel-title>Order Number: {{ data.order }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data | json }}
              </div>
            </mat-expansion-panel>
            <div
              class="no-items"
              *ngIf="!largeMeterConfig || largeMeterConfig.length === 0"
            >
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Meter Test</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of meterTest">
              <mat-expansion-panel-header>
                <mat-panel-title>Order Number: {{ data.order }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data | json }}
              </div>
            </mat-expansion-panel>
            <div class="no-items" *ngIf="!meterTest || meterTest.length === 0">
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Meter Activites</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of meterActivites">
              <mat-expansion-panel-header>
                <mat-panel-title>Order Number: {{ data.order }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data.data | json }}
              </div>
            </mat-expansion-panel>
            <div
              class="no-items"
              *ngIf="!meterActivites || meterActivites.length === 0"
            >
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
  
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Non Transitions</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of nontransitions">
              <mat-expansion-panel-header>
                <mat-panel-title>Key: {{ data.key }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data.data | json }}
              </div>
            </mat-expansion-panel>
            <div
              class="no-items"
              *ngIf="!nontransitions || nontransitions.length === 0"
            >
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Premise</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of premise">
              <mat-expansion-panel-header>
                <mat-panel-title>Order Number: {{ data.order }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data.data | json }}
              </div>
            </mat-expansion-panel>
            <div class="no-items" *ngIf="!premise || premise.length === 0">
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
  
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Transitions</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of transitions">
              <mat-expansion-panel-header>
                <mat-panel-title>Key: {{ data.key }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data | json }}
              </div>
            </mat-expansion-panel>
            <div
              class="no-items"
              *ngIf="!transitions || transitions.length === 0"
            >
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
  
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Uploaded Files</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of uploadedFiles">
              <mat-expansion-panel-header>
                <mat-panel-title>Key: {{ data.key }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data | json }}
              </div>
            </mat-expansion-panel>
            <div
              class="no-items"
              *ngIf="!uploadedFiles || uploadedFiles.length === 0"
            >
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
  
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Version</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of version">
              <mat-expansion-panel-header>
                <mat-panel-title>Key: {{ data.key }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data | json }}
              </div>
            </mat-expansion-panel>
            <div class="no-items" *ngIf="!version || version.length === 0">
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
  
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Widgets</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of widgets">
              <mat-expansion-panel-header>
                <mat-panel-title
                  >OrderNumber: {{ data.workOrderNumber }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data.workOrder | json }}
              </div>
            </mat-expansion-panel>
            <div class="no-items" *ngIf="!widgets || widgets.length === 0">
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
  
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Work Order Details</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of workOrderDetails">
              <mat-expansion-panel-header>
                <mat-panel-title>OrderNumber: {{ data.Order }} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data | json }}
              </div>
            </mat-expansion-panel>
            <div
              class="no-items"
              *ngIf="!workOrderDetails || workOrderDetails.length === 0"
            >
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- <hr> -->
  
      <mat-accordion class="index-accordian-parent">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="table-name"
            >Work Oders</mat-expansion-panel-header
          >
          <mat-accordion class="index-accordian">
            <mat-expansion-panel *ngFor="let data of workOrders">
              <mat-expansion-panel-header>
                <mat-panel-title
                  >OrderNumber: {{ data.workOrderNumber }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="items">
                {{ data | json }}
              </div>
            </mat-expansion-panel>
            <div class="no-items" *ngIf="!workOrders || workOrders.length === 0">
              No Data
            </div>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>
  
</div>
