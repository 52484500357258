import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericMeterComponent } from './generic-meter.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UploadFileModule } from 'src/app/modules/upload-file/upload-file.module';
import { FileUploadModule } from 'ng2-file-upload';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatIconModule } from '@angular/material/icon';
import { DialsComponent } from '../dials/dials.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        GenericMeterComponent,
        DialsComponent
    ],
    imports: [
        CommonModule,
        MatSelectModule,
        MatInputModule,
        MatGridListModule,
        MatDialogModule,
        MatCheckboxModule,
        MatRadioModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        UploadFileModule,
        FileUploadModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatListModule,
        MatDividerModule,
        MatStepperModule,
        CdkStepperModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
    ],
    exports: [
        GenericMeterComponent,
        DialsComponent
    ]
})
export class GenericMeterModule { }
