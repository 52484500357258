import { HeatType, Alert, DevicePostion, SecureAccess, ActionVal, LockPlug } from "../interfaces/capture-form.interfaces";

export class CaptureFormConfig {
  public static PARENT_ROUTE = 'glance';
  public static SECTION_ROUTE = 'capture';

  public static SECURE_ACCESS: SecureAccess[] = [
    { 'value': '00', 'label': 'Not Secure' },
    { 'value': '01', 'label': 'Gated Community' },
    { 'value': '02', 'label': 'Locked Entry, key needed for access' },
    { 'value': '03', 'label': 'Locked Entry, Customer needed for access' },
    { 'value': '04', 'label': 'Special Security Access Needed' },
    { 'value': '05', 'label': 'Secured Meter, Customer does not need to be present' }
  ];

  public static BALANCE_PARAMS = [
    { 'label': 'less than $250', 'shortLabel': '<=$250', 'balanceAmountTo': 250, 'balanceAmountFrom': null },
    { 'label': 'between $251 - $1000', 'shortLabel': '$251 - $1000', 'balanceAmountTo': 1000, 'balanceAmountFrom': 251 },
    { 'label': 'between $1001 - $2500', 'shortLabel': '$1001 - $2500', 'balanceAmountTo': 2500, 'balanceAmountFrom': 1001 },
    { 'label': 'greater than $2500', 'shortLabel': '>$2500', 'balanceAmountTo': null, 'balanceAmountFrom': 2500 },
  ]
  public static SEWER_MATCODES = [

    'SCI',
    'CVF',
    'CVN',
    'IPM'

  ]
  public static MATCODES = {
    FCNM: [],//fixed charge no meter
    STO: ["STO"],
    PST: ["PST"],
    RZC: ["RZC"],
    TXL: ["TXL"]
  }

  public static RZC_ZeroCODE_MATCODE = [
    { "value": "IRRIGATION", "label": "Irrigation" },
    { "value": "LANDLORD", "label": "Landlord" },
    { "value": "SEASONAL", "label": "Seasonal" },
    { "value": "SNOWBIRD", "label": "Snowbird" },
    { "value": "VACANT", "label": "Vacant" },
    { "value": "WAREHSTORE", "label": "Warehouse" },
    { "value": "FIRE SERVI", "label": "Fire Service" },
    { "value": "METER OKAY", "label": "Meter Okay" },
    { "value": "HOSPITALIZ", "label": "Hospitalized" },
    { "value": "HYDRAN MTR", "label": "Hydrant" },
    { "value": "USER AGREE", "label": "User Agreement" },
    { "value": "CUST O WEL", "label": "Well" },
    { "value": "DETECTMTR", "label": "Detector" },
    { "value": "COMPANY", "label": "Company" },
    { "value": "MINIM USER", "label": "Minimal" },
    { "value": "LMVR", "label": "LMVR" },
    { "value": "METER CHNG", "label": "Meter Change" },


  ]
  public static PREMISE_MAPPING = {
    'meterLocation': [
      {
        'code': 'IS',
        'description': 'Inside'
      }, {
        'code': 'OS',
        'description': 'Outside'
      }, {
        'code': 'SA',
        'description': 'Secure Access'
      }
    ],
    'meterPosition': [
      {
        'value': '4H',
        'label': 'Alley'
      },
      {
        'value': '1B',
        'label': 'Bathroom'
      },
      {
        'value': '1A',
        'label': 'Cellar/Basement'
      },
      {
        'value': '1C',
        'label': 'Closet'
      },
      {
        'value': '1D',
        'label': 'Crawl Space'
      },
      {
        'value': '4A',
        'label': 'Curb'
      },
      {
        'value': '4C',
        'label': 'Driveway'
      },
      {
        'value': '4D',
        'label': 'Fence'
      },
      {
        'value': '7B',
        'label': 'Garage'
      },
      {
        'value': 'HY',
        'label': 'Hydrant Meter'
      },
      {
        'value': 'KT',
        'label': 'Kitchen'
      },
      {
        'value': '4J',
        'label': 'Lawn/Field'
      },
      {
        'value': '7E',
        'label': 'Main Building'
      },
      {
        'value': '4I',
        'label': 'Parking Lot'
      },
      {
        'value': '4G',
        'label': 'Property Line'
      },
      {
        'value': '4B',
        'label': 'Sidewalk'
      },
      {
        'value': '7D',
        'label': 'Shop'
      },
      {
        'value': '4K',
        'label': 'Street'
      },
      {
        'value': 'UV',
        'label': 'Unable to Verify'
      },
      {
        'value': '1U',
        'label': 'Utility Room'
      },
      {
        'value': 'US',
        'label': 'Utility Strip'
      },
      {
        'value': '7A',
        'label': 'Wall'
      },
      {
        'value': 'VT',
        'label': 'Within the Vault'
      }
    ],
    'meterDirectional': [
      {
        'code': 'RR',
        'description': 'Rear'
      },
      {
        'code': 'FR',
        'description': 'Front'
      },
      {
        'code': 'LS',
        'description': 'Left Side'
      },
      {
        'code': 'RS',
        'description': 'Right Side'
      },
      {
        'code': 'RL',
        'description': 'Rear Left Side'
      },
      {
        'code': 'RE',
        'description': 'Rear Right Side'
      },
      {
        'code': 'FL',
        'description': 'Front Left Side'
      },
      {
        'code': 'FS',
        'description': 'Front Right Side'
      },
      {
        'code': 'LR',
        'description': 'Left Side Rear'
      },
      {
        'code': 'RA',
        'description': 'Right Side Rear'
      },
      {
        'code': 'LF',
        'description': 'Left Side Front'
      },
      {
        'code': 'RF',
        'description': 'Right Side Front'
      }
    ],
  };

  public static READING_DEVICE_POSITIONS: DevicePostion[] = [
    {
      'value': '4H',
      'label': 'Alley'
    },
    {
      'value': '1B',
      'label': 'Bathroom'
    },
    {
      'value': '1A',
      'label': 'Cellar/Basement'
    },
    {
      'value': '1C',
      'label': 'Closet'
    },
    {
      'value': '1D',
      'label': 'Crawl Space'
    },
    {
      'value': '4A',
      'label': 'Curb'
    },
    {
      'value': '4C',
      'label': 'Driveway'
    },
    {
      'value': '4D',
      'label': 'Fence'
    },
    {
      'value': '7B',
      'label': 'Garage'
    },
    {
      'value': 'KT',
      'label': 'Kitchen'
    },
    {
      'value': '4J',
      'label': 'Lawn/Field'
    },
    {
      'value': '7E',
      'label': 'Main Building'
    },
    {
      'value': '4I',
      'label': 'Parking Lot'
    },
    {
      'value': '4G',
      'label': 'Property Line'
    },
    {
      'value': '4B',
      'label': 'Sidewalk'
    },
    {
      'value': '7D',
      'label': 'Shop'
    },
    {
      'value': '4K',
      'label': 'Street'
    },
    {
      'value': 'UV',
      'label': 'Unable to Verify'
    },
    {
      'value': '1U',
      'label': 'Utility Room'
    },
    {
      'value': 'US',
      'label': 'Utility Strip'
    },
    {
      'value': '7A',
      'label': 'Wall'
    },
    {
      'value': 'VT',
      'label': 'Within the Vault'
    }
  ];

  public static METER_DEVICE_POSITIONS: DevicePostion[] = [
    {
      'value': '4H',
      'label': 'Alley'
    },
    {
      'value': '1B',
      'label': 'Bathroom'
    },
    {
      'value': '1A',
      'label': 'Cellar/Basement'
    },
    {
      'value': '1C',
      'label': 'Closet'
    },
    {
      'value': '1D',
      'label': 'Crawl Space'
    },
    {
      'value': '4A',
      'label': 'Curb'
    },
    {
      'value': '4C',
      'label': 'Driveway'
    },
    {
      'value': '4D',
      'label': 'Fence'
    },
    {
      'value': '7B',
      'label': 'Garage'
    },
    {
      'value': 'HY',
      'label': 'Hydrant Meter'
    },
    {
      'value': 'KT',
      'label': 'Kitchen'
    },
    {
      'value': '4J',
      'label': 'Lawn/Field'
    },
    {
      'value': '7E',
      'label': 'Main Building'
    },
    {
      'value': '4I',
      'label': 'Parking Lot'
    },
    {
      'value': '4G',
      'label': 'Property Line'
    },
    {
      'value': '4B',
      'label': 'Sidewalk'
    },
    {
      'value': '7D',
      'label': 'Shop'
    },
    {
      'value': '4K',
      'label': 'Street'
    },
    {
      'value': 'UV',
      'label': 'Unable to Verify'
    },
    {
      'value': '1U',
      'label': 'Utility Room'
    },
    {
      'value': 'US',
      'label': 'Utility Strip'
    },
    {
      'value': '7A',
      'label': 'Wall'
    },
    {
      'value': 'VT',
      'label': 'Within the Vault'
    }
  ];

  public static FOLLOW_UP_REASON = [
    { 'code': 'F16', 'name': 'Add Curb Box Offset' },
    { 'code': 'F24', 'name': 'By pass not working' },
    { 'code': 'F29', 'name': 'Check for leak in Curb Box' },
    { 'code': 'F26', 'name': 'Clean Curb Box' },
    { 'code': 'F28', 'name': 'Clean Curb Box and Install Resetter' },
    { 'code': 'F07', 'name': 'Curb stop broken' },
    { 'code': 'F20', 'name': 'Customers valve not working' },
    { 'code': 'F14', 'name': 'Install Pit' },
    { 'code': 'F27', 'name': 'Install Resetter' },
    { 'code': 'F09', 'name': 'Leak on company side' },
    { 'code': 'F03', 'name': 'Locate curb stop' },
    { 'code': 'F08', 'name': 'Low Pressure-company side' },
    { 'code': 'F10', 'name': 'Locate Meter Pit' },
    { 'code': 'F21', 'name': 'Locate and operate Gate valve' },
    { 'code': 'F12', 'name': 'Locate Curb Stop - Emergency' },
    { 'code': 'F13', 'name': 'Locate Meter Pit - Emergency' },
    { 'code': 'F31', 'name': 'Pit and Piping Repair Required' },
    { 'code': 'F19', 'name': 'Poor Pipe Integrity' },
    { 'code': 'F25', 'name': 'Pump Out Vault' },
    { 'code': 'F01', 'name': 'Raise / lower pit to grade' },
    { 'code': 'F02', 'name': 'Raise / lower curb box to grade' },
    { 'code': 'F23', 'name': 'Remove Meter Demo' },
    { 'code': 'F04', 'name': 'Repair / replace curb box' },
    { 'code': 'F05', 'name': 'Repairs to Ring and Cover' },
    { 'code': 'F06', 'name': 'Repairs to pit' },
    { 'code': 'F30', 'name': 'Repair Reading Device' },
    { 'code': 'F15', 'name': 'Replace Meter Pit Top Section' },
    { 'code': 'F22', 'name': 'Replace Meter' },
    { 'code': 'F11', 'name': 'Restoration' },
    { 'code': 'F17', 'name': 'Valve inoperable' },
    { 'code': 'F18', 'name': 'Vault inaccessible' }
  ];
  public static SERVICE_LINE_TYPE = [
    { 'code': 'AC', 'name': 'Asbestos Cement/ Transite', 'othercode': 'Transit' },
    { 'code': 'Brass', 'name': 'Brass', 'othercode': 'Brass' },
    { 'code': 'Vitrified Clay', 'name': 'Clay - Sewer', 'othercode': 'Vitrified Clay' },
    { 'code': 'Cast Iron', 'name': 'Cast Iron', 'othercode': 'Wicl' },
    { 'code': 'Copper', 'name': 'Copper', 'othercode': 'Copper' },
    { 'code': 'Customer Line Not Installed', 'name': 'Customer Line Not Installed', 'othercode': 'Customer Line Not Installed' },
    { 'code': 'Ductile', 'name': 'Ductile Iron', 'othercode': 'Ductile' },
    { 'code': 'Galvanized', 'name': 'Galvanized', 'othercode': 'Galvanized' },
    { 'code': 'Galvanized requiring replaceme', 'name': 'Galvanized requiring replacement', 'othercode': 'Galvanized requiring replacement' },
    { 'code': 'Galvanized with Lead Gooseneck', 'name': 'Galvanized with Lead Gooseneck', 'othercode': 'Galvanized with Lead Gooseneck' },
    { 'code': 'Lead', 'name': 'Lead/Tubeloy', 'othercode': 'Tubeloy' },
    { 'code': 'Not Lead Material undetermin', 'name': 'Not Lead - Material undetermined', 'othercode': 'Not Lead - Material undetermined' },
    { 'code': 'Other with Lead Gooseneck', 'name': 'Other with Lead Gooseneck', 'othercode': 'Other with Lead GooseneckOLG' },
    { 'code': 'Plastic', 'name': 'Plastic Pipe PE/PVC, HDPE', 'othercode': 'Carlon' },

    { 'code': 'Unclassified', 'name': 'Unclassified', 'othercode': 'Unclassified', others:[
      { 'code': 'Unclassified No Tap Info', 'name': 'No Tap Info', 'othercode': 'Unclassified No Tap Info' },
      { 'code': 'Unclassified Potential Lead', 'name': 'Potential Lead', 'othercode': 'Unclassified Potential Lead' },
      { 'code': 'Unclassified Research Needed', 'name': 'Research Needed', 'othercode': 'Unclassified Research Needed' },
      { 'code': 'Unclassified Unlikely Lead', 'name': 'Unlikely Lead', 'othercode': 'Unclassified Unlikely Lead' }],
    },

    {
      'code': 'Unknown', 'name': 'Unknown', 'othercode': 'Unknown', others: [
        { 'code': 'Unknown Not visible', name: 'Not Visible', 'othercode': 'Unknown Not visible' },
        { 'code': 'Unknown Pipe Heavily Painted', name: 'Pipe Heavily Painted', 'othercode': 'Unknown Pipe Heavily Painted' },
        { 'code': 'Unknown via test', name: 'Unknown via Test', 'othercode': 'Unknown via test' },
        { 'code': 'Unknown Potential Lead', name: 'Potential Lead', 'othercode': 'Unknown Potential Lead' }
      ]
    },   
    
  ];
  public static CUSTOMER_SIDE_LEAK_OPTIONS = [
    { 'code': '02', 'name': 'Left a Door Hanger' },             // need to change the code as SAP provides
    // { 'code': '03', 'name': 'Talked to Customer and Left a Door Hanger' },    // need to change the code as SAP provides
    { 'code': '01', 'name': 'Talked to Customer' }
  ];

  public static PURPOSE_TAB = [
    { 'code': 'I04', 'name': 'AW Compliance' },
    { 'code': 'I14', 'name': 'Asset Record Control' },
    { 'code': 'I01', 'name': 'Customer (COMPLAINT/REQUEST)' },
    { 'code': 'I11', 'name': 'Damaged/Billable' },
    { 'code': 'I15', 'name': 'Demolition' },
    { 'code': 'I02', 'name': 'Equipment Reliability' },
    { 'code': 'I12', 'name': 'Estimates' },
    { 'code': 'I07', 'name': 'Leak Detection' },
    { 'code': 'I05', 'name': 'Regulatory Complaint' },
    { 'code': 'I08', 'name': 'Revenue $150-$500' },
    { 'code': 'I09', 'name': 'Revenue $501-$1000' },
    { 'code': 'I10', 'name': 'Revenue > $1000' },
    { 'code': 'I03', 'name': 'Safety' },
    { 'code': 'I06', 'name': 'Seasonal' },
    { 'code': 'I13', 'name': 'Water Quality' }
  ];
  public static SEWER_PASS_INSPECTION = [
    { 'code': '001', 'name': 'Downspout' },
    { 'code': '002', 'name': 'Clean Out' },
    { 'code': '003', 'name': 'Area Drain' },
    { 'code': '004', 'name': 'Storm Sump w/Diverter ' },
    { 'code': '005', 'name': 'Stump Sump to Sanitary' },
    { 'code': '006', 'name': 'Combination Sump' },
    { 'code': '007', 'name': 'Unsealed Sanitary Sump' },
    { 'code': '008', 'name': 'Window Well Drain' },
    { 'code': '009', 'name': 'Foundation Drain' },
    { 'code': '010', 'name': 'Lateral' },
    { 'code': '011', 'name': 'Other' }
  ];
  public static INCOMPLETE_TAB = [
    {
      'id': 'in1', 'name': 'Compliance',
      'category': [
        { 'code': '2HRS', 'value': 'Reg - 2 hr non pay suspension' },
        { 'code': 'CMPR', 'value': 'Customer compliance with compa' },
        { 'code': 'DUNN', 'value': 'Dunning lock on account' },
        { 'code': 'EXPR', 'value': 'Expiry of disconnect doc' },
        { 'code': 'FLTY', 'value': 'Faulty outside read device' },
        { 'code': 'INST', 'value': 'Installment payment plan' },
        { 'code': 'LFTO', 'value': 'No permit left off' },
        { 'code': 'PMNT', 'value': 'Payment Received' },
        { 'code': 'PVTW', 'value': 'PVT Well Connected' }
      ]
    },
    {
      'id': 'in2', 'name': 'Company', 'category': [
        { 'code': '2PSN', 'value': '2 Person Job' },
        { 'code': 'EMER', 'value': 'Attend Emergency' },
        { 'code': 'ERRO', 'value': 'Company Error' },
        { 'code': 'NDAS', 'value': 'Require Assistance' },
        { 'code': 'SHFT', 'value': 'End Of Shift' },
        { 'code': 'EXEM', 'value': 'Exemption' },
        { 'code': 'SUPI', 'value': 'Supervisor Instructed' },
        { 'code': 'WCOM', 'value': 'Work Already Completed' }
      ]
    },
    {
      'id': 'in3', 'name': 'Access', 'category': [
        { 'code': 'ACDN', 'value': 'Denied Access' },
        { 'code': 'ADLT', 'value': 'No Adult At Premise' },
        { 'code': 'BLCK', 'value': 'Access BLKD To CURB' },
        { 'code': 'MTRB', 'value': 'Meter Blocked' },
        { 'code': 'NANS', 'value': 'Called Customer - No Answer' },
        { 'code': 'VACT', 'value': 'Property Vacant' }
      ]
    },
    {
      'id': 'in4', 'name': 'Distribution', 'category': [
        { 'code': 'CLEN', 'value': 'Cleanout Curb Box' },
        { 'code': 'CURB', 'value': 'CURB Stop Needs Repair' },
        { 'code': 'IMPB', 'value': 'NO Couplings Installed' },
        { 'code': 'OFST', 'value': 'Curb Box Offset' },
        { 'code': 'PITP', 'value': 'Pit & Piping Repair Required' },
        { 'code': 'PLMB', 'value': 'Bad Plumbing' }
      ]
    },
    {
      'id': 'in5', 'name': 'Customer',
      'category': [
        { 'code': 'CUST', 'value': 'Customer Request' },
        { 'code': 'MOVI', 'value': 'Move IN' },
        { 'code': 'MOVO', 'value': 'Move OUT' },
        { 'code': 'CARD', 'value': 'Customer Not on Premise' },
        { 'code': 'RESC', 'value': 'Customer To Re-Schedule' }
      ]
    },
    {
      'id': 'in6', 'name': 'Dependency',
      'category': [
        { 'code': 'DPND', 'value': 'Dependency Not Carried' },
      ]
    },
    {
      'id': 'in7', 'name': 'Safety & Health', 'category': [
        { 'code': 'HEAL', 'value': 'Health Hazard' },
        { 'code': 'SAFE', 'value': 'Safety Hazard' },
        { 'code': 'VDOG', 'value': 'Vicious Dog/Animal' },
        { 'code': 'WEAT', 'value': 'Due To Weather Conditions' }
      ]
    },
    {
      'id': 'in8', 'name': 'Locate', 'category': [
        { 'code': 'LOCT', 'value': 'Locate Curb Box' }
      ]
    },
    {
      'id': 'in9', 'name': 'Regulatory', 'category': [
        { 'code': 'REGU', 'value': 'Regulatory Investigation Or CO' },
      ]
    },
    {
      'id': 'in10', 'name': 'Shared Services Line', 'category': [
        { 'code': 'SERV', 'value': 'Shared Service Line' }
      ]
    },
    {
      'id': 'in11', 'name': 'Meter', 'category': [
        { 'code': 'SOUN', 'value': 'Move On Meter/ Sound On Device' }
      ]
    }
  ];
  public static FEEDBACK_REASONS = [
    { 'code': 'Q01', 'label': 'Incorrect Order Type' },
    { 'code': 'Q02', 'label': 'Incorrect Call Ahead#' },
    { 'code': 'Q03', 'label': 'No Call Ahead#' },
    { 'code': 'Q04', 'label': 'Required Appt Not Made' },
    { 'code': 'Q05', 'label': 'Appointment Not needed' },
    { 'code': 'Q06', 'label': 'Wrong Account' },
    { 'code': 'Q07', 'label': 'Incorrect Information' },
    { 'code': 'Q08', 'label': 'Information Missing' },
    { 'code': 'Q09', 'label': 'Meter Misread This Month' },
    { 'code': 'Q10', 'label': 'Meter Misread Last Month' },
    { 'code': 'Q11', 'label': 'Dependency Not Met' },
    { 'code': 'Q12', 'label': 'Missing Material' },
    { 'code': 'Q13', 'label': 'Dependency Missing' },
    { 'code': 'Q14', 'label': 'Wrong Equipment Referenced' },
    { 'code': 'Q15', 'label': 'Incorrect Location' }
  ]

  public static HEAT_TYPES: HeatType[] = [
    {
      id: '',
      label: 'No Entry',
      class: 'w1v-no-entry-icon'
    },
    {
      id: 'U',
      label: 'Unknown',
      class: 'w1v-unknown-icon'
    },
    {
      id: 'Y',
      label: 'Hot Water',
      class: 'w1v-hotWater-icon'
    },
    {
      id: 'N',
      label: 'Non Hot Water',
      class: 'w1v-noHotWater-icon'
    }
  ];

  public static ALERTS: Alert[] = [
    {
      label: 'Pet',
      class: 'w1v-pet-icon',
      url: 'assets/images/icons/Alert_Pet.svg',
      selectedurl: 'assets/images/icons/Alert_Pet_white.svg',
    },
    {
      label: 'Snake',
      class: 'w1v-snake-icon',
      url: 'assets/images/icons/Alert_Snake.svg',
      selectedurl: 'assets/images/icons/Alert_Snake_white.svg',
    },
    {
      label: 'Bees',
      class: 'w1v-bee-icon',
      url: 'assets/images/icons/Alert_Bee.svg',
      selectedurl: 'assets/images/icons/Alert_Bee_white.svg',
    },
    {
      label: 'Poison Ivy',
      class: 'w1v-poison-ivy-icon',
      url: 'assets/images/icons/Alert_Poison_Ivy.svg',
      selectedurl: 'assets/images/icons/Alert_Poison_Ivy_white.svg',
    },
    {
      label: 'Bug',
      class: 'w1v-bugs-icon',
      url: 'assets/images/icons/Alert_Insect.svg',
      selectedurl: 'assets/images/icons/Alert_Insect_white.svg',
    }
  ];

  public static LOCK_PLUG: LockPlug[] = [
    {
      label: 'Curb stop plugged',
      url: 'assets/images/icons/Lock_PlugCurbStop.svg',
      value: '01',
    },
    {
      label: 'Piping plugged',
      url: 'assets/images/icons/Lock_PlugPipe.svg',
      value: '02',
    },
    {
      label: 'Meter Plugged',
      url: 'assets/images/icons/Lock_PlugMeter.svg',
      value: '03'
    },
    {
      label: 'Meter Locked',
      url: 'assets/images/icons/Lock_Meter.svg',
      value: '04'
    },
    {
      label: 'Corp Locked',
      url: 'assets/images/icons/Lock_Corp.svg',
      value: '05'
    }
  ];

  public static SERVICE_FOUND_OPTIONS: ActionVal[] = [
    { 'label': 'On', 'value': 'Y' },
    { 'label': 'Off', 'value': 'N' },
    { 'label': 'Unable to Verify', 'value': 'U' }
  ];

  public static SERVICE_FOUND_AT_OPTIONS: ActionVal[] = [
    { 'label': 'Curb Stop', 'value': 'I01' },
    { 'label': 'Pit', 'value': 'I05' },
    { 'label': 'Customer Valve', 'value': 'I03' },
    { 'label': 'Corporation', 'value': 'I07' }
  ];

  public static SERVICE_FOUND_CODES: ActionVal[] = [
    { 'label': 'I01', 'value': 'ON at Curb Stop' },
    { 'label': 'I02', 'value': 'OFF at Curb Stop' },
    { 'label': 'I05', 'value': 'ON at Pit' },
    { 'label': 'I06', 'value': 'OFF at Pit' },
    { 'label': 'I03', 'value': 'ON at Customer Valve' },
    { 'label': 'I04', 'value': 'OFF at Customer Valve' },
    { 'label': 'I07', 'value': 'ON at Corporation' },
    { 'label': 'I08', 'value': 'OFF at Corporation' },
    { 'label': 'I09', 'value': 'U' }

  ]

  public static COMPANY_ACTIONS: ActionVal[] = [
    { 'value': 'I27', 'label': 'Changed Pressure Regulator' },
    { 'value': 'I52', 'label': 'Curb Box Repair' },
    { 'value': 'I07', 'label': 'Leak Inspection - Company Issue' },
    { 'value': 'I28', 'label': 'Pressure Test' },
    { 'value': 'I55', 'label': 'Repair Replace Lid' },
    { 'value': 'I51', 'label': 'Secure Lid' },
    { 'value': 'I34', 'label': 'Sewer Issue - Company Responsible' },
    { 'value': 'I37', 'label': 'Sounded service - No Noise' },
    { 'value': 'I38', 'label': 'Sounded service - Noise' },
    { 'value': 'I54', 'label': 'Water Quality Investigation' },
    { 'value': 'I14', 'label': 'Abandoned Meter' },
    { 'value': 'I01', 'label': 'Called Ahead - no answer' },
    { 'value': 'I43', 'label': 'Collected Water Sample' },
    // { 'value': 'I48', 'label': 'Customer side Leak Detected' },
    // { 'value': 'I47', 'label': 'Gave Time to Pay' },
    { 'value': 'I08', 'label': 'Leak Inspection - Customer Issue' },
    { 'value': 'I09', 'label': 'Left Company Information' },
    { 'value': 'I29', 'label': 'Pressure Test-Customer Issue' },
    { 'value': 'I48', 'label': 'Property Vacant' },
    { 'value': 'I26', 'label': 'Posted for Non-Pay' },
    { 'value': 'I21', 'label': 'Re-read / Inspected with Customer' },
    { 'value': 'I35', 'label': 'Sewer Issue - not AW' },
    { 'value': 'I46', 'label': 'Witnessed Tap Destroy' },
    { 'value': 'I00', 'label': 'COVID Customer Entry Questions: YES to Any Question' },
    { 'value': 'I000', 'label': 'COVID Customer Entry Questions: All Responses NO' },
    { 'value': 'I70', 'label': 'Attempted contact prior to shut off' },
    { 'value': 'I71', 'label': 'Posted or hand delivered notice' }
  ];

  public static INSTALL = {
    'id': 'install',
    'name': 'Install',
    'src': 'assets/images/ic_add.png',
    'activeSrc': 'assets/images/InstallIcon-white.svg',
    'activeStatus': false,
    'width': 20,
    'options': [
      // { 'code': '02', 'name': 'Installation Extension' },
      // { 'code': '05', 'name': 'Initial data Creation' },
      { 'code': '12', 'name': 'Customer Request' },
      { 'code': '22', 'name': 'New Service' },
      // { 'code': '23', 'name': 'Demo' },
      // { 'code': '26', 'name': 'Meter Malfunction' },
      // { 'code': '27', 'name': 'Backward Meter' },
      // { 'code': '28', 'name': 'Warrantee' },
      // { 'code': '30', 'name': 'Incompatable with RF' },
      // { 'code': '31', 'name': 'Meter Leaking' },
      // { 'code': '32', 'name': 'AMI Repair/Replace' },
      // { 'code': '33', 'name': 'AMI New Install' },
      // { 'code': '52', 'name': 'Mueller Composite Meter' },
      // { 'code': '53', 'name': 'Error Status 999' },
      // { 'code': '54', 'name': 'DEVICE & REGI READ DIFFERS' },
      // { 'code': '56', 'name': 'MDMS Conversion' },
      // { 'code': '57', 'name': 'Deactivate Cell MIU - Damaged' },
      // { 'code': '58', 'name': 'Deactivate MIU - Warranty EOL' },
      // { 'code': '59', 'name': 'Replaced AMI' },
      // { 'code': '60', 'name': 'Replaced AMR/Touchpad' },
      // { 'code': '61', 'name': 'Upgraded to AMI' },
      // { 'code': '62', 'name': 'Downgrade to AMR/Touchpad' },
      // { 'code': '63', 'name': 'New Install RF/AMR/Touchpad' },

      // {'code':'','name':''},
      // { 'code': 'I18', 'name': 'Installed Meter' },
      // { 'code': 'I33', 'name': 'Installed RF' },
      // { 'code': 'I36', 'name': 'Installed Smart Valve' },
      // { 'code': 'I39', 'name': 'Installed Touchpad' }
    ],
    'optionData': [
      // { 'code': '33', 'name': 'AMI New Install' },
      // { 'code': '12', 'name': 'Customer Request' },
      // { 'code': '23', 'name': 'Demolition' },
      // { 'code': '22', 'name': 'New Service' }
    ]
  };
  public static REPLACE_REGISTER = {
    'id': 'replace',
    'name': 'Replace',
    'src': 'assets/images/ic_cached.png',
    'activeSrc': 'assets/images/ReplaceIcon-white.svg',
    'activeStatus': false,
    'width': 20,
    'options': [
      // { 'code': 'I17', 'name': 'Replaced Meter' },
      // { 'code': 'I32', 'name': 'Replaced Register' }
      // { 'code': '02', 'name': 'Installation Extension' },
      { 'code': '25', 'name': 'Abandoned' },
      { 'code': '13', 'name': 'Bad Dial' },
      { 'code': '65', 'name': 'Backward Meter' },
      { 'code': '12', 'name': 'Customer Request' },
      { 'code': '20', 'name': 'Damaged' },
      { 'code': '54', 'name': 'Device & Register Read Differs' },
      { 'code': '18', 'name': 'Downsize' },
      { 'code': '14', 'name': 'Fast Meter' },
      { 'code': '16', 'name': 'Frozen Meter' },
      { 'code': '30', 'name': 'Incompatable with RF' },
      { 'code': '31', 'name': 'Meter Leaking' },
      { 'code': '26', 'name': 'Meter Malfunction' },
      { 'code': '52', 'name': 'Mueller Composite Meter' },
      { 'code': '06', 'name': 'Periodic Replacement' },
      { 'code': '21', 'name': 'Stolen' },
      { 'code': '17', 'name': 'Stopped Meter' },
      { 'code': '19', 'name': 'Upsize' },
      { 'code': '28', 'name': 'Warrantee' },
      { 'code': '66', 'name': 'Dead Battery' },


      // { 'code': '05', 'name': 'Initial data Creation' },
      // { 'code': '22', 'name': 'New Service' },
      // { 'code': '23', 'name': 'Demo' },


      // { 'code': '32', 'name': 'AMI Repair/Replace' },
      // { 'code': '33', 'name': 'AMI New Install' },
      // { 'code': '53', 'name': 'Error Status 999' },
      // { 'code': '54', 'name': 'DEVICE & REGI READ DIFFERS' },
      // { 'code': '56', 'name': 'MDMS Conversion' },
      // { 'code': '57', 'name': 'Deactivate Cell MIU - Damaged' },
      // { 'code': '58', 'name': 'Deactivate MIU - Warranty EOL' },
      // { 'code': '59', 'name': 'Replaced AMI' },
      // { 'code': '60', 'name': 'Replaced AMR/Touchpad' },
      // { 'code': '61', 'name': 'Upgraded to AMI' },
      // { 'code': '62', 'name': 'Downgrade to AMR/Touchpad' },
      // { 'code': '63', 'name': 'New Install RF/AMR/Touchpad' },
    ],
    'optionData': [
      // { 'code': '25', 'name': 'Abandoned' },
      // { 'code': '33', 'name': 'AMI New Install' },
      // { 'code': '32', 'name': 'AMI Repair/Replace' },
      // { 'code': '13', 'name': 'Bad Dial' },
      // { 'code': '0000', 'name': 'Backward Meter' },
      // { 'code': '12', 'name': 'Customer Request' },
      // { 'code': '20', 'name': 'Damaged' },
      // { 'code': '23', 'name': 'Demolition' },
      // { 'code': '54', 'name': 'Device & Register Read Differs' },
      // { 'code': '18', 'name': 'Downsize' },
      // { 'code': '53', 'name': 'Error Status 999' },
      // { 'code': '14', 'name': 'Fast Meter' },
      // { 'code': '16', 'name': 'Frozen Meter' },
      // { 'code': '30', 'name': 'Incompatible with RF' },
      // { 'code': '31', 'name': 'Meter Leaking' },
      // { 'code': '26', 'name': 'Meter Malfunction' },
      // { 'code': '52', 'name': 'Mueller Composite Meter' },
      //
      // { 'code': '24', 'name': 'Plugged' },
      // { 'code': '51', 'name': 'Retire Meter In Place' },
      // { 'code': '21', 'name': 'Stolen' },
      // { 'code': '17', 'name': 'Stopped Meter' },
      // { 'code': '15', 'name': 'Slow Meter' },
      // { 'code': '19', 'name': 'Upsize' },
      // { 'code': '28', 'name': 'Warranty' },
      //
    ]
  }

  public static REPLACE = {
    'id': 'replace',
    'name': 'Replace',
    'src': 'assets/images/ic_cached.png',
    'activeSrc': 'assets/images/ReplaceIcon-white.svg',
    'activeStatus': false,
    'width': 20,
    'options': [
      // { 'code': 'I17', 'name': 'Replaced Meter' },
      // { 'code': 'I32', 'name': 'Replaced Register' }
      // { 'code': '02', 'name': 'Installation Extension' },
      { 'code': '25', 'name': 'Abandoned' },
      { 'code': '13', 'name': 'Bad Dial' },
      { 'code': '65', 'name': 'Backward Meter' },
      { 'code': '12', 'name': 'Customer Request' },
      { 'code': '20', 'name': 'Damaged' },
      { 'code': '54', 'name': 'Device & Register Read Differs' },
      { 'code': '18', 'name': 'Downsize' },
      { 'code': '14', 'name': 'Fast Meter' },
      { 'code': '16', 'name': 'Frozen Meter' },
      { 'code': '30', 'name': 'Incompatable with RF' },
      { 'code': '31', 'name': 'Meter Leaking' },
      { 'code': '26', 'name': 'Meter Malfunction' },
      { 'code': '52', 'name': 'Mueller Composite Meter' },
      { 'code': '06', 'name': 'Periodic Replacement' },
      { 'code': '21', 'name': 'Stolen' },
      { 'code': '17', 'name': 'Stopped Meter' },
      { 'code': '19', 'name': 'Upsize' },
      { 'code': '28', 'name': 'Warrantee' },
      { 'code': '66', 'name': 'Dead Battery' },
      { 'code': '68', 'name': 'Replaced UME Periodic' },
      { 'code': '69', 'name': 'Replaced UME Broken' },
      { 'code': '70', 'name': 'Re-certified UME' },
      { 'code': '71', 'name': 'Retired UME' },

      // { 'code': '05', 'name': 'Initial data Creation' },
      // { 'code': '22', 'name': 'New Service' },
      // { 'code': '23', 'name': 'Demo' },


      // { 'code': '32', 'name': 'AMI Repair/Replace' },
      // { 'code': '33', 'name': 'AMI New Install' },
      // { 'code': '53', 'name': 'Error Status 999' },
      // { 'code': '54', 'name': 'DEVICE & REGI READ DIFFERS' },
      // { 'code': '56', 'name': 'MDMS Conversion' },
      // { 'code': '57', 'name': 'Deactivate Cell MIU - Damaged' },
      // { 'code': '58', 'name': 'Deactivate MIU - Warranty EOL' },
      // { 'code': '59', 'name': 'Replaced AMI' },
      // { 'code': '60', 'name': 'Replaced AMR/Touchpad' },
      // { 'code': '61', 'name': 'Upgraded to AMI' },
      // { 'code': '62', 'name': 'Downgrade to AMR/Touchpad' },
      // { 'code': '63', 'name': 'New Install RF/AMR/Touchpad' },
    ],
    'optionData': [
      // { 'code': '25', 'name': 'Abandoned' },
      // { 'code': '33', 'name': 'AMI New Install' },
      // { 'code': '32', 'name': 'AMI Repair/Replace' },
      // { 'code': '13', 'name': 'Bad Dial' },
      // { 'code': '0000', 'name': 'Backward Meter' },
      // { 'code': '12', 'name': 'Customer Request' },
      // { 'code': '20', 'name': 'Damaged' },
      // { 'code': '23', 'name': 'Demolition' },
      // { 'code': '54', 'name': 'Device & Register Read Differs' },
      // { 'code': '18', 'name': 'Downsize' },
      // { 'code': '53', 'name': 'Error Status 999' },
      // { 'code': '14', 'name': 'Fast Meter' },
      // { 'code': '16', 'name': 'Frozen Meter' },
      // { 'code': '30', 'name': 'Incompatible with RF' },
      // { 'code': '31', 'name': 'Meter Leaking' },
      // { 'code': '26', 'name': 'Meter Malfunction' },
      // { 'code': '52', 'name': 'Mueller Composite Meter' },
      //
      // { 'code': '24', 'name': 'Plugged' },
      // { 'code': '51', 'name': 'Retire Meter In Place' },
      // { 'code': '21', 'name': 'Stolen' },
      // { 'code': '17', 'name': 'Stopped Meter' },
      // { 'code': '15', 'name': 'Slow Meter' },
      // { 'code': '19', 'name': 'Upsize' },
      // { 'code': '28', 'name': 'Warranty' },
      //
    ]
  };

  public static MAINTAIN = {
    'id': 'maintain',
    'name': 'Maintain',
    'src': 'assets/images/ic_build.png',
    'activeSrc': 'assets/images/MaintainIcon-white.svg',
    'activeStatus': false,
    'width': 20,
    'options': [
      // { 'code': 'I49', 'name': 'AMI New Install' },
      { 'code': 'I15', 'name': 'Backwards Meter - Corrected' },
      // { 'code': 'I16', 'name': 'Backwards Meter - Not Corrected' },
      { 'code': 'I06', 'name': 'Checked Leak at Curb/Street' },
      { 'code': 'I04', 'name': 'Cleaned Out Curb Box/Pit' },
      { 'code': 'I53', 'name': 'Data Logger' },
      { 'code': 'I05', 'name': 'Located/Marked C/S' },
      { 'code': 'I10', 'name': 'Locked Setting' },
      { 'code': 'I13', 'name': 'Meter # Verified' },
      { 'code': 'I19', 'name': 'Moved Meter to the Curb' },
      { 'code': 'I24', 'name': 'Pumped Out Pit' },
      { 'code': 'I20', 'name': 'Read Meter' },
      { 'code': 'I30', 'name': 'Repaired Reading Device' },
      // { 'code': 'I31', 'name': 'Replaced Reading Device' },
      //  { 'code': 'I56', 'name': 'Sewer Inspection' },
      { 'code': 'I23', 'name': 'Tested Meter In Place' },
      { 'code': 'I44', 'name': 'Turned Off' },
      { 'code': 'I45', 'name': 'Turned On' },
      { 'code': 'I40', 'name': 'Unable to Execute' },
      { 'code': 'I41', 'name': 'Unable to Obtain Meter Read' },
      { 'code': 'I42', 'name': 'Unable to Turn Water off/on' },
      { 'code': 'I12', 'name': 'Verified No Movement on Meter' },
      { 'code': 'I11', 'name': 'Verified Movement on Meter' },
      { 'code': 'I72', 'name': 'Deactivate / Inactivate MIU' },

      //below commented as they are not part of miu relase, need to use them with new 4.0 refractoring
      // { 'code': '0000', 'name': 'Plugged' },
      // { 'code': '000', 'name': 'Changed \ Repaired Meter Pit Lid' },
      // { 'code': '000', 'name': 'Replaced Antenna' },
      // { 'code': '000', 'name': 'Dug Out Meter Pit' },
      // { 'code': '000', 'name': 'Changed \ Repaired Curb Stop Lid' },
      // { 'code': '000', 'name': ' Repaired Meter Pit' },
      // { 'code': '000', 'name': ' Locked Setting \ Meter' },



    ]
  };

  public static OVERRIDE = {
    'id': 'override',
    'name': 'Override',
    'src': 'assets/images/Meter_Override_black.svg',
    'activeSrc': 'assets/images/Meter_Override.svg',
    'activeStatus': false,
    'width': 30,
    'options': [
      { 'code': 'I06', 'name': 'Checked Leak at Curb/Street' },
      { 'code': 'I04', 'name': 'Cleaned Out Curb Box/Pit' },
      { 'code': 'I60', 'name': '“No Meter” Connection Investigation' },
      { 'code': 'I59', 'name': '“No Meter” Premise Investigation' },
      { 'code': 'I61', 'name': '“No Meter” Sewer Inspection' },
      { 'code': 'I57', 'name': '“No Meter” Sewer Only Account' },
      { 'code': 'I44', 'name': 'Turned Off' },
      { 'code': 'I45', 'name': 'Turned On' },
      { 'code': 'I58', 'name': '“No Meter” Flat Rate / Non Metered Account' },
      { 'code': 'I40', 'name': 'Unable to Execute' },
      
    ]
  };

  public static REMOVE = {
    'id': 'remove',
    'name': 'Remove',
    'src': 'assets/images/minus-circle-black.svg',
    'activeSrc': 'assets/images/minus-circle-white.svg',
    'activeStatus': false,
    'width': 20,
    'options': [
      // { 'code': 'I22', 'name': 'Removed Meter' }
      // { 'code': '25', 'name': 'Abandoned' },
      // { 'code': '12', 'name': 'Customer Request' },
      // { 'code': '23', 'name': 'Demolition' },
      // { 'code': '01', 'name': 'Installation Removal' },
      // { 'code': '03', 'name': 'Installation Reduction' },
      // { 'code': '04', 'name': 'Installation Shutdown' },
      { 'code': '12', 'name': 'Customer Request' },
      // { 'code': '17', 'name': 'Stopped Meter' },
      { 'code': '23', 'name': 'Demo' },
      { 'code': '25', 'name': 'Abandoned' },
      // { 'code': '26', 'name': 'Meter Malfunction' },
      // { 'code': '27', 'name': 'Backward Meter' },
      // { 'code': '28', 'name': 'Warrantee' },
      // { 'code': '30', 'name': 'Incompatable with RF' },
      // { 'code': '31', 'name': 'Meter Leaking' },
      // { 'code': '32', 'name': 'AMI Repair/Replace' },
      // { 'code': '33', 'name': 'AMI New Install' },
      // { 'code': '52', 'name': 'Mueller Composite Meter' },
      // { 'code': '53', 'name': 'Error Status 999' },
      // { 'code': '54', 'name': 'DEVICE & REGI READ DIFFERS' },
      // { 'code': '56', 'name': 'MDMS Conversion' },
      // { 'code': '57', 'name': 'Deactivate Cell MIU - Damaged' },
      // { 'code': '58', 'name': 'Deactivate MIU - Warranty EOL' },
      // { 'code': '59', 'name': 'Replaced AMI' },
      // { 'code': '60', 'name': 'Replaced AMR/Touchpad' },
      // { 'code': '61', 'name': 'Upgraded to AMI' },
      // { 'code': '62', 'name': 'Downgrade to AMR/Touchpad' },
      // { 'code': '63', 'name': 'New Install RF/AMR/Touchpad' },
      // { 'code': '64', 'name': 'Demolition' },
    ],
    'optionData': [
      // { 'code': '25', 'name': 'Abandoned' },
      // { 'code': '33', 'name': 'AMI New Install' },
      // { 'code': '32', 'name': 'AMI Repair/Replace' },
      // { 'code': '13', 'name': 'Bad Dial' },
      // { 'code': '27', 'name': 'Backward Meter' },
      // { 'code': '12', 'name': 'Customer Request' },
      // { 'code': '23', 'name': 'Demolition' },
      // { 'code': '54', 'name': 'Device & Register Read Differs' },
      // { 'code': '18', 'name': 'Downsize' },
      // { 'code': '20', 'name': 'Damaged' },
      // { 'code': '53', 'name': 'Error Status 999' },
      // { 'code': '14', 'name': 'Fast Meter' },
      // { 'code': '16', 'name': 'Frozen Meter' },
      // { 'code': '03', 'name': 'Installation Reduction' },
      // { 'code': '01', 'name': 'Installation Removal' },
      // { 'code': '04', 'name': 'Installation Shutdown' },
      // { 'code': '30', 'name': 'Incompatible with RF' },
      // { 'code': '31', 'name': 'Meter Leaking' },
      // { 'code': '26', 'name': 'Meter Malfunction' },
      // { 'code': '52', 'name': 'Mueller Composite Meter' },
      // { 'code': '06', 'name': 'Periodic replacement' },
      // { 'code': '24', 'name': 'Plugged' },
      // { 'code': '15', 'name': 'Slow Meter' },
      // { 'code': '17', 'name': 'Stopped Meter' },
      // { 'code': '21', 'name': 'Stolen' },
      // { 'code': '19', 'name': 'Upsize' },
      // { 'code': '28', 'name': 'Warranty' }
    ]



  };

  public static REPLACE_MIU = {
    'id': 'replace_miu',
    'name': 'Replace',
    'src': 'assets/images/minus-circle-black.svg',
    'activeSrc': 'assets/images/minus-circle-white.svg',
    'activeStatus': false,
    'width': 20,
    'options': [
      { 'code': '57', 'name': 'Deactivate Cellular MIU - Damaged' },
      { 'code': '58', 'name': 'Deactivate Cellular MIU - Warranty EOL' },
      { 'code': '59', 'name': 'Replaced AMI' },
      { 'code': '60', 'name': 'Replaced AMR / Touchpad' },
      { 'code': '61', 'name': 'Upgrade to AMI' },
      { 'code': '62', 'name': 'Downgrade to AMR / Touchpad' },

    ],
    'optionData': [
      // { 'code': '25', 'name': 'Abandoned' },


    ]
  };


  public static INSTALL_MIU = {
    'id': 'install_miu',
    'name': 'Install',
    'src': 'assets/images/minus-circle-black.svg',
    'activeSrc': 'assets/images/minus-circle-white.svg',
    'activeStatus': false,
    'width': 20,
    'options': [
      { 'code': '33', 'name': 'AMI New Install' },
      { 'code': '63', 'name': 'New Install RF / AMR / Touchpad' },
    ],
    'optionData': [
      // { 'code': '25', 'name': 'Abandoned' },


    ]
  };

  public static REMOVE_MIU = {
    'id': 'remove_miu',
    'name': 'Remove',
    'src': 'assets/images/minus-circle-black.svg',
    'activeSrc': 'assets/images/minus-circle-white.svg',
    'activeStatus': false,
    'width': 20,
    'options': [
      { 'code': '25', 'name': 'Abandoned' },
      { 'code': '12', 'name': 'Customer Request' },
      { 'code': '23', 'name': 'Demo' },
    ],
    'optionData': [
      // { 'code': '25', 'name': 'Abandoned' },


    ]
  };
  public static BASIC_READ_TYPES = [
    { 'id': '01', 'name': 'Keyed', 'miu': 'No', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': '03', 'name': 'Retired - Neptune Mtr ITRON RF', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': '11', 'name': 'Neptune - RF - AMR', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Neptune" },
    { 'id': '12', 'name': 'Neptune - RF-AMI-Fixed Network', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Neptune" },
    { 'id': '13', 'name': 'Mueller - RF - AMR', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Mueller" },
    { 'id': '14', 'name': 'Mueller - RF-AMI-Fixed Network', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Mueller" },
    { 'id': '15', 'name': 'Retired - ITRON - RF - AMR', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': '16', 'name': 'Retired - ITRON-RF-AMI-Fix Net', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': '18', 'name': 'Datamatic-RF-AMI-Fixed Network', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Datamatic" },
    { 'id': '20', 'name': 'Aclara - RF -AMI-Fixed Network', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Aclara" },
    { 'id': '21', 'name': 'Retired - AMI Aclara - MVRS', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': '22', 'name': 'Badger - ME - RF - AMR', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Badger" },
    { 'id': '23', 'name': 'Badger - RF - AMR', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Badger" },
    { 'id': '24', 'name': 'Sensus - RF -AMI-Fixed Network', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Sensus" },
    { 'id': '27', 'name': 'Badger - ATT Cellular - AMI', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Badger" },
    { 'id': '31', 'name': 'Badger - VZN Cellular - AMI', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Badger" },
    { 'id': '29', 'name': 'Neptune - VZN Cellular - AMI', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Neptune" },
    { 'id': '30', 'name': 'Neptune - FN Cellular - AMI', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Neptune" },
    { 'id': '28', 'name': 'Retired - AMR - Master Meter', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': '25', 'name': 'Sensus - MXU - RF - AMR', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Sensus" },
    { 'id': '26', 'name': 'Sensus - Smart Point - RF -AMR', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': 'E', 'name': 'Retired - AMR Radio Reads-MVRS', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': '08', 'name': 'Neptune - Touch Pad - AMR', 'miu': 'No', 'tp': 'Yes', 'currentRead': 'Yes',"manufacturerName":"Neptune" },
    { 'id': '19', 'name': 'Retired - Mueller-TouchPad-AMR', 'miu': 'No', 'tp': 'Yes', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': 'T', 'name': 'Retired -Touch Pad MVRS only', 'miu': 'No', 'tp': 'Yes', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': 'X', 'name': 'Retired -Touchpad (WRR MVRS)', 'miu': 'No', 'tp': 'Yes', 'currentRead': 'Yes',"manufacturerName":"" },
    { 'id': '32', 'name': 'Kamstrup - Fixed - AMI - Int', 'miu': 'Yes', 'tp': 'No', 'currentRead': 'Yes',"manufacturerName":"Kamstrup" }
  ];
  public static METER_SIZE_TYPES = {
    '0001': '5/8 "',
    '0002': '3/4 "',
    '0003': '1 "',
    '0004': '1-1/2 "',
    '0005': '2 "',
    '0006': '3 "',
    '0007': '4 "',
    '0008': '6 "',
    '0009': '8 "',
    '0010': '10 "',
    '0011': '12 "',
    '0012': '1/2 "',
    '0013': '14 "',
    '0014': '16 "',
    '0015': '5 "'
  }
  public static METER_SIZE_ARRAY_TYPES = [
    '5/8"',
    '3/4"',
    '1"',
    '1-1/2"',
    '2"',
    '3"',
    '4"',
    '6"',
    '8"',
    '10"',
    '12"',
    '1/2"',
    '14"',
    '16"',
    '5"'
  ]
  public static UNIT_OF_MEASURE = [
    "1MG",
    "CCF",
    "CGL",
    "DCG",
    "DCF",
    "FT3",
    "GAL",
    "TCH",
    "TGL",
    "TNG",
    "TTG",
  ]
  public static LARGE_METER_UNIT = [
    'CF',
    'GPM'
  ]
  public static STATUS_UPDATE_CHECK = ['ONST','ENRT','COMP','INCM','HOLD','CANC'];
  public static FILTER_PICTURE_CATRIGE = [
    {name : 'Left On Porch' , value : 'LEFT ON PORCH / DOORSTEP'},
    {name : 'Gave to Customer' , value : 'HANDED TO CUSTOMER'},
    {name : 'Other' , value : 'OTHER'}
  ];
  public static METER_MANUFACTURER = [
    "ARI",
    "AY Mcdonald",
    "Aclara",
    "American Standard",
    "Ames",
    "Apollo",
    "Apollo/Conbraco",
    "BAVCO",
    "Backflow Direct",
    "Badger",
    "Beeco",
    "Buckner",
    "CLA",
    "CONVERSION",
    "Cash Acme",
    "Champion",
    "Cla-Val",
    "Conbraco",
    "Deringer",
    "Elster",
    "Febco",
    "Flomatic",
    "Ford",
    "Giacomini",
    "Grinnell",
    "Hersey",
    "Honeywell",
    "Itron",
    "Jones Stephens Corp",
    "Kamstrup",
    "Kennedy-Grinnell",
    "Kent",
    "Legend",
    "Master Meter",
    "Metron-Farnier",
    "Mueller",
    "Musterfirma GmbH",
    "N/A",
    "Neptune",
    "Orion",
    "Precision",
    "Premier",
    "Rainbird",
    "Rockwell",
    "Sensus",
    "Strahman",
    "Taco",
    "Toro",
    "UNKNOWN",
    "Unknown",
    "User Owned",
    "Verify",
    "Watts",
    "Wilkins",
    "Wolverine Brass",
    "Worthington",
    "Zenner",
  ]
}
