import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { UploadFileComponent } from '../../components/upload-file/upload-file.component';

@NgModule({
  exports: [UploadFileComponent],
  declarations: [UploadFileComponent],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  providers: []
})
export class UploadFileModule { }
