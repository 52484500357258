import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccessControlService } from '../access-control/access-control.service';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

export interface UserInfo {
  user: string | null | undefined;
  groups: string | null | undefined;
  hasPermissions: boolean | null | undefined;
}

@Injectable()
export class AuthService {
  /**
   * subject holds userinfo
   */
  userInfoSub = new BehaviorSubject<UserInfo>({} as UserInfo);

  constructor(
    private accessCtrlService : AccessControlService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) { }

  getPermissions(identity) {
    this.accessCtrlService.storePermissionsInLocalStorage().subscribe(() => {
      this.userInfoSub.next({
        user: identity.preferred_username,
        groups: this.userGroups,
        hasPermissions: true
      });
    }, (e) => {
      this.userInfoSub.next({
        user: identity.preferred_username,
        groups: this.userGroups,
        hasPermissions: false
      });
    });
  }

  get Identity(){
    let identity: any =  window.localStorage['okta-token-storage'] ? JSON.parse(window.localStorage['okta-token-storage']) : null;
    return identity;
  }
  get claims() {
    return this.Identity ? this.Identity.idToken.claims : null;
  }

  get username() {
    return this.Identity ? this.Identity.idToken.DName : null;
  }

  get userGroups() {
    return this.Identity ? this.Identity.idToken.W1V : null;
  }

  get accessToken() {
    let accessToken: any = this.oktaAuth.getAccessToken();
    return accessToken ? accessToken : null;
  }

  get idToken() {
    let idToken: any = this.oktaAuth.getIdToken();
    return idToken ? idToken : null;
  }

  logout() {
    this.oktaAuth.signOut(); 
  }

}
