import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], searchText: string,property?:string):  any[]  {
    if (!items) return [];
    if (!searchText) return items;
    return items.filter(item => {
        if(property)
        {
          return item[property].toLowerCase().includes(searchText.toLowerCase());
        }
        else{
        return JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase());
        }
    });
  }

}