import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ToasterSevice {
    constructor(private toastr: ToastrService) { }

    public success(message, title?) {
        this.toastr.clear();
        this.toastr.success(message, title);
    }

    public error(message, title?) {
        this.toastr.clear();
        this.toastr.error(message, title);
    }
    public info(message, title?) {
        this.toastr.clear();
        this.toastr.info(message, title);
    }

    public warn(message, title?) {
        this.toastr.clear();
        this.toastr.warning(message, title);
    }
}