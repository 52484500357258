import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import 'rxjs/add/operator/toPromise';
import { FILE_FORMAT_TYPE, UPLOAD_FILE_FORMAT } from 'src/app/app.config';


@Component({
  selector: 'app-error',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  constructor(private domSanitizer: DomSanitizer, private http: HttpClient,public dialog: MatDialog, public dialogRef: MatDialogRef<ErrorDialogComponent>) {

  }

  ngOnInit(): void {

  }
  close(){
    this.dialogRef.close();
  }
}
