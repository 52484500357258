import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * Access control service
 */
@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  httpOptions: any;

  permissionsEndpoint: string = environment.apiBaseUrl + 'permissionsbyId';
  refreshPermissionsEndpoint: string = environment.apiBaseUrl + 'refreshGroupPermissions';

  constructor(private http: HttpClient) {
    if (environment.ssoconfig === 'local') {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Basic YWRtaW46YWRtaW4='
        })
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({})
      }
    }
  }

  getPermissionsByGroupId(groupId): Observable<any> {
    return this.http.post(this.permissionsEndpoint, groupId).pipe(
      tap(perm => {
        localStorage.setItem('userPermissions', JSON.stringify(perm));
      }),
      catchError(err => {
        console.log(err);
        return EMPTY
      })
    )
  }

  refreshPermissions(): Observable<any> {
    return this.http.get(this.refreshPermissionsEndpoint, this.httpOptions);
  }

  get groupId() {
    let groupId = [];
    const token =  window.localStorage['okta-token-storage'] ? JSON.parse(window.localStorage['okta-token-storage']) : null;
    const claims = token.idToken.claims;
    //const claims = JSON.parse(localStorage.getItem("id_token_claims_obj") as string);
    let groupMembership = claims ? (claims['W1V'] ? claims['W1V'] : []) : [];

    if (groupMembership.length > 0) {
      const filteredGrp = groupMembership.filter(grp => grp.includes('W1V_'));
      groupId = filteredGrp;
    }

    return groupId;
  }

  get permissions() {
    const permissions = JSON.parse(localStorage.getItem('userPermissions') as string);
    return permissions ? permissions : [];
  }

  clearUserPermissions() {
    localStorage.removeItem('userPermissions');
  }

  haveRequiredPermissions(moduleName: string): Observable<any> {
    const groupId = this.groupId;
    const permissions = this.permissions;
    if (permissions.length > 0) {
      return of(permissions.some(perm => perm.resourceName === moduleName));
    } else {
      return this.getPermissionsByGroupId(groupId).pipe(
        map(accessPerms => accessPerms.some(accessPerm => accessPerm.resourceName === moduleName))
      );
    }
  }

  storePermissionsInLocalStorage(groupId?: any) {
    return this.getPermissionsByGroupId(groupId ? groupId : this.groupId);
  }

  getWritablePermissions(moduleName: string) {
    const permissions = this.permissions.some(perm => {
      if (perm.resourceName === moduleName && perm.permissions) {
        return perm.permissions.includes('writable') ? true : false;
      } else
        return "";
    });
    return permissions ? true : false;
  }
}
