import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoNotificationPopupComponent } from './info-notification-popup/info-notification-popup.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    InfoNotificationPopupComponent,
   
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
  ],
  exports: [
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    InfoNotificationPopupComponent
  ]
})
export class InfoNotificationPopupModule { }
