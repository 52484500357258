import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from '../services/utilities.service';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
})
export class NetworkComponent implements OnInit {
  constructor(public utilities: UtilitiesService,) { }

  ngOnInit(): void {}
}
