import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fsronly',
  templateUrl: './fsronly.component.html',
  styleUrls: ['./fsronly.component.scss']
})
export class FsronlyComponent implements OnInit {

  constructor() { }
  role : any = [];

  ngOnInit(): void {
    const token =  window.localStorage['okta-token-storage'] ? JSON.parse(window.localStorage['okta-token-storage']) : null;
    const claim = token.idToken.claims;
    this.role = claim.W1V;
    //this.role = JSON.parse(localStorage.getItem("id_token_claims_obj") as string).W1V
  }

}
