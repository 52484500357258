import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { LoaderService } from './services/loader.service';
// import { SocketService } from './services/socket.service';
import { ToasterSevice } from './services/ToasterService.service';
import 'rxjs/add/observable/interval';
import { UtilitiesService } from './services/utilities.service';
import { OrdersService } from './services/orders.service';
import { environment } from '../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { filter, map } from 'rxjs/operators';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading: boolean = true;
  page: string = '';
  loadPage: string = '';
  checkTokenValidity: Subscription = new Subscription;
  w1vInstance;
  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private loader: LoaderService,
    private toaster: ToasterSevice,
    private ordersService: OrdersService,
    private utilitiesService: UtilitiesService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private oktaAuthService: OktaAuthStateService,
    private authService : AuthService

  ) {
    this.w1vInstance = new BroadcastChannel('w1v_instance');
    this.w1vInstance.postMessage('there is a new instance');
    this.w1vInstance.onmessage = event => {
      console.log(event);
      if (event.data === 'there is a new instance') {
        window.location.href = '/new-session.html';
      }
    }

    this.router.events.subscribe((event: any) => {
      if (event.constructor.name === 'NavigationStart') {
        if (event && event.url) {
          const routes = event.url.split('/');
          this.page = routes[1];
        }
      }
    });

    //this.swUpdate.activateUpdate().then( event  => {
       //console.log('old version was', event.previous);
       //console.log('new version is', event.current);
      // console.log(event);
    //});

    this.swUpdate.versionUpdates.subscribe(res => {
      console.log(res);
      if(res.type === 'VERSION_READY'){
      if (confirm('New version Available. Do you want to reload the app?')) {
        this.swUpdate.activateUpdate().then(() => {
          window.location.reload();
        });
      }
    }
    });


    if (!localStorage.availableStatus) {
      setTimeout(() => {
        this.utilitiesService.updateAvailibilityStatus(1);
      }, 5000);
    }
  }

  ngAfterViewInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .subscribe((res) => {
          if(res.type === 'VERSION_READY'){
          this.swUpdate
            .activateUpdate()
            .then(() => {
              this.toaster.info('Update Available');
              window.location.reload();
            });
          }
        });
      
    }
  }

  async ngOnInit() {
    this.loader.fetchLoader().subscribe(loader => {
      this.isLoading = loader;
    });
    this.oktaAuthService.authState$.subscribe((x)=> {
      if(x.isAuthenticated){
        console.log("AUTHENTICATED : TRUE");
        this.loadPage = 'isPresent';
        let claims = this.authService.claims
        localStorage.setItem('user', claims.preferred_username);
        if (claims.W1V.indexOf('W1V_CONT') == -1) {
          localStorage.setItem('contractor', 'no');
        } else {
          localStorage.setItem('contractor', 'yes');
        }
        this.authService.getPermissions(claims);
      }
    }, error => {
      console.log('SSO ERROR = ' + error.params.error_description ? error.params.error_description : error);
      const logObj = { 'method': 'configureSingleSignOn', 'msg': error.params.error_description ? error.params.error_description : 'ERROR' , 'data': error };
      this.utilitiesService.displayLogs(logObj, true);
    });
  }


  // async ngOnInit() {
  //   // this.socket.getMessage();
  //   console.log(environment);
  //   if (environment.ssoconfig == 'local') {
  //     localStorage.setItem('user', 'local_user');
  //     this.loadPage = 'isPresent';
  //   } else {
  //     let token = await this.authService.configureSingleSignOn().catch((data) => {
  //       this.loadPage = 'notPresent';
  //       console.log('ERROR = ' + data.params.error_description);
  //       const logObj = { 'method': 'configureSingleSignOn', 'msg': data.params.error_description, 'data': data };
  //       this.utilitiesService.displayLogs(logObj, true);
  //       this.errorHandler.handleError(data);
  //     });

  //     if (token && this.oauthService.getAccessToken()) {
  //       this.loadPage = 'isPresent';
  //       let identity: any = this.oauthService.getIdentityClaims();
  //       localStorage.setItem('user', identity.preferred_username);
  //       if (identity.W1V.indexOf('W1V_CONT') == -1) {
  //         localStorage.setItem('contractor', 'no');
  //       } else {
  //         localStorage.setItem('contractor', 'yes');
  //       }
  //       this.authService.getPermissions(identity);
  //     } else {
  //       // this.loadPage = '';
  //     }
  //   }

  //   this.loader.fetchLoader().subscribe(loader => {
  //     this.isLoading = loader;
  //   });

  //   // setInterval(() => {
  //   //   this.socket.sendMessage('hello');
  //   //   this.socket.getMessage();
  //   // });
  //   this.checkTokenValidity = Observable.interval(60000)
  //     .subscribe((val) => {
  //       console.log('TTT called : ' + val);
  //       this.authService.checkIfTokenAboutToExpire(900000);
  //     });
  // }

  ngOnDestroy() {
    this.w1vInstance.close();
    if (this.utilitiesService.postLocationInterval) {
      clearInterval(this.utilitiesService.postLocationInterval);
    }

    this.utilitiesService.clearLocationInterval();

    if (this.ordersService.notificationIntervalTimer) {
      clearInterval(this.ordersService.notificationIntervalTimer);
    }
    this.checkTokenValidity.unsubscribe();

    // if (this.ordersService.jeopardyIntervalTimer) {
    //   clearInterval(this.ordersService.jeopardyIntervalTimer);
    // }
  }
}
