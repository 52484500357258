import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { API_INFO, API_URL, CURRENT_LOCATION_INTERVAL, easterTimeZone, FAR_AWAY_DISTANCE, FETCH_TRUCK_LOCATION, POST_FSR_LOCATION_INTERVAL, PREVENT_QUEUE_URL, TRANSITIONS_QUEUE, TRUCK_LOCATION_INTERVAL, W1VMGMT_API_URL } from '../app.config';
import { map } from 'rxjs/operators';
import { BehaviorSubject, observable, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { dbConfig } from '../db.config';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { WarningMessageDailogComponent } from '../dialogs/warning-message-dailog/warning-message-dailog.component';
import { MatDialog } from '@angular/material/dialog';
import { OKTA_CONFIG, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { AuthService } from './auth/auth.service';
// import { Howl } from 'howler';
// import { MatDialog } from '@angular/material/dialog';
// import { WarningMessageDailogComponent } from '../dialogs/warning-message-dailog/warning-message-dailog.component';
@Injectable()
export class UtilitiesService {
  online: boolean;
  easterTimeZone = 'America/New_York';
  locationInterval;
  postLocationInterval;
  currentLocation = new BehaviorSubject<Object>({});
  currentLocationObservable = this.currentLocation.asObservable();
  errorLocation = new BehaviorSubject<Object>({});
  locationObj = new BehaviorSubject<Object>({});
  locationDisabledWarning = false;
  logsPosted = false;
  locationArray: any = [];
  postLocation = false;
  syncing = false;
  queue: any[] = [];
  transitions: any[] = [];
  indexedDBUploadedFiles: any = [];
  loggingOut: boolean = false;
  notifactionVolume = true;
  self = this;
  _networkCalls: any[] = [];
  _locationRequests: any[] = [];
  networkCalls = new BehaviorSubject<any[]>([]);
  locationEnabled: boolean = true;
  connectivityStatus: string = '';
  status: any;
  userName: any;
  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    public dbService: NgxIndexedDBService,
    public router: Router,
    public platform: Platform,
    private oktaAuthService: OktaAuthStateService,
    @Inject(OKTA_CONFIG) private oktaAuth: OktaAuth,
    private authservice :AuthService
  ) {
    const location =  localStorage.getItem('online');
    //this.online = false;
    this.online = location ? JSON.parse(location) : window.navigator.onLine;
    if (this.online === null || this.online === undefined) {
      this.online = true;
    }
    this.postLocationInitialize();
    this.getLocation();
  }

  setNetworkCalls(obj: any) {
    if (this._networkCalls && this._networkCalls.length === 1000) {
      this._networkCalls = [];
    }
    obj.time = moment().format('D MMMM HH:mm:ss');
    this._networkCalls.push(obj);
  }

  setLocationCalls(obj: any) {
    if (this._locationRequests && this._locationRequests.length === 1000) {
      this._locationRequests = [];
    }
    obj.time = moment().format('D MMMM HH:mm:ss');
    this._locationRequests.push(obj);
  }

  geteasterTimeZone() {
    return this.easterTimeZone;
  }

  getserverTimeZone() {
    return moment.tz(moment().toDate(), this.geteasterTimeZone()).utcOffset();
  }
  getCurrentTimeZone(){
    return moment.tz.guess();
  }
  currentTimeZone() {
    const tz = moment.tz.guess();
    const time = moment().format();
    const format = 'YYYY-MM-DD HH:mm:ss z';
    return moment(time, format).tz(tz).format(format);
  }

  getAvailabilityReasons() {
    const url = API_URL.getAvailabilityReasons;
    return this.getCall(url);
  }

  getAllStatesAndDistricts(isContractors?){
    let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
    let url = API_URL.getAllStatesAndDistricts ;
    url = isContractors?url+"?isContractor="+isContractors:url;
    return this.getCall(url);
  }

  getAllMeterManufactures(){
    let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
    let url = API_URL.getAllMeterManufactures;
    return this.getCall(url);
  }

  getMatArrivalNotes(matCode: any){
    const matCodeString = matCode['matCode'];
    const url = API_URL.getMatArrivalData + matCodeString;
   // const options = this.getHeader();
return this.getCallMat(url);
   //return this.http.get(url).subscribe(data => console.log(data));
    //return this.getCall(url);
  }


  setAvailableStatus() {
    let availabilityReasons = localStorage.getItem('availabilityReasons');
    availabilityReasons = availabilityReasons
      ? JSON.parse(availabilityReasons)
      : [];
    const availableStatus = localStorage.getItem('availableStatus');
    if (
      availabilityReasons &&
      availabilityReasons.length > 0 &&
      availableStatus &&
      availableStatus != 'undefined'
    ) {
      const availableStatusCode = JSON.parse(availableStatus);
      this.connectivityStatus =
        availableStatusCode === 0
          ? 'available'
          : availableStatusCode === availabilityReasons.length - 1
            ? 'signoff'
            : 'unavail';
      this.status = availableStatus
        ? JSON.parse(availableStatus)
        : 0;
    } else {
      this.status = 0;
      this.connectivityStatus = 'available';
    }
  }

  postLocationInitialize() {
    const logObj = { method: 'postLocationInitialize', msg: 'Location->' };
    this.displayLogs(logObj, false);
    if (this.postLocationInterval) {
      clearInterval(this.postLocationInterval);
    }
    this.postLocationInterval = setInterval(() => {
      const locationObj = {};
      locationObj['status'] = [];
      locationObj['status'].push(
        'Publishing Location to API: ======>',
        this.locationArray
      );
      this.setLocationCalls(locationObj);
      if (this.locationArray && this.locationArray.length > 0) {
        this.publishLocation().subscribe((res) => {
          this.locationArray = [];
        });
      }
    }, POST_FSR_LOCATION_INTERVAL);
  }

  publishLocation() {
    return this.postCall(
      API_URL.updateFsrLocation,
      this.locationArray,
      'updateFSRLocation'
    );
  }

  getLocation() {
    console.log("aaab:this gets called");
    const logObj = { method: 'getLocation', msg: 'Location->' };
    this.displayLogs(logObj, false);
    const truckId = localStorage.getItem('truckId') || '';
    if (
      FETCH_TRUCK_LOCATION &&
      truckId &&
      truckId !== 'null' &&
      truckId !== 'undefined'
    ) {
      this.getTruckLocation(truckId);
    } else {
      this.getCurrentLocation();
    }
  }

  getCurrentLocation() {
    const logObj = { method: 'getCurrentLocation', msg: 'Location->' };
    this.displayLogs(logObj, false);
    this.clearLocationInterval();
    this.gettingCurrentLocation();
    this.locationInterval = setInterval(() => {
      this.gettingCurrentLocation();
    }, CURRENT_LOCATION_INTERVAL);
  }

  gettingCurrentLocation() {
    const self = this;
    const logObj = { method: 'gettingCurrentLocation', msg: 'Location->' };
    this.displayLogs(logObj, false);
    // this.currentLocation.next({ message: 'Getting Current location ....' });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userId = this.getEmployeeId();
        this.locationObj.next(position);
        this.locationEnabled = true;
        if (position && position.coords && userId) {
          const coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          self.validateBrowserLocation(coords);
        }
      },
      (err) => {
        this.errorLocation.next(err);
        this.locationEnabled = false;
        const error =
          err && err.message ? err.message : 'browser location issue';
        if (err.code === 1 && !this.locationDisabledWarning) {
          this.locationDisabledWarning = true;
          this.dialog.open(WarningMessageDailogComponent, {
            hasBackdrop: true,
            disableClose: true,
            width: '404px',
            data: {
              title: 'Warning',
              description:
                'This application requires your location details in order to function properly. Please enable location services on the browser.  If you are an iPad user, please also enable the device location.',
              button: 'Close',
            },
          });
        }
        const logObj = {
          method: 'gettingCurrentLocation',
          msg: 'Unable to retrieve your location',
          error: error,
        };
        this.displayLogs(logObj, false);
        const browserLocationObj = {};
        browserLocationObj['key'] =
          'browser-location-error-' + this.getEmployeeId(true);
        browserLocationObj['message'] = 'Location issue';
        browserLocationObj['error'] = error;
        if (!self.logsPosted) {
          self.postLocationLogs(browserLocationObj);
        }
        const locationObj = {};
        locationObj['status'] = [];
        locationObj['status'].push('Browser Error: ', logObj);
        this.setLocationCalls(locationObj);
        // self.maskLocation();
      }
    );
  }

  postLocationLogs(obj) {
    const logObj = { method: 'postLocationLogs', msg: 'Location->' };
    this.displayLogs(logObj, false);
    const fsrId = this.getEmployeeId(true);
    if (fsrId) {
      obj['timeStamp'] = this.currentTimeZone();
      obj['fsrId'] = fsrId;
      this.displayLogs(obj, true);
      this.logsPosted = true;
    }
  }

  validateBrowserLocation(coords, lastStoredLocation?) {
    const logObj = { method: 'validateBrowserLocation', msg: 'Location->' };
    this.displayLogs(logObj, false);
    const locationObj = {};
    locationObj['status'] = [];
    locationObj['browser'] = coords;
    locationObj['status'].push('Validate Browser Location ====>');
    locationObj['status'].push('Browser Coordinates: ', coords);
    if (!lastStoredLocation) {
      localStorage.setItem('overRideLocation', 'false');
    }
    if (coords && coords.latitude && coords.longitude) {
      const firstWorkOrderLatitude = localStorage.getItem(
        'firstWorkOrderLatitude'
      );
      const firstWorkOrderLongitude = localStorage.getItem(
        'firstWorkOrderLongitude'
      );
      if (firstWorkOrderLatitude && firstWorkOrderLongitude) {
        locationObj['status'].push('First WorkOrder Coordinates: ', {
          latitude: firstWorkOrderLatitude,
          longitude: firstWorkOrderLongitude,
        });
        const distance = this.findDistance(
          coords.latitude,
          coords.longitude,
          firstWorkOrderLatitude,
          firstWorkOrderLongitude
        );
        if (distance > 1609 * FAR_AWAY_DISTANCE) {
          const maskedCords: any = this.maskLocation();
          if (maskedCords && maskedCords.longitude && maskedCords.latitude) {
            coords = maskedCords;
            locationObj['status'].push('Masked Coordinates: ', maskedCords);
          }
          if (!this.logsPosted) {
            const distanceObj = {};
            const lastPremiseLat = localStorage.getItem('lastPremiseLat');
            const lastPremiseLng = localStorage.getItem('lastPremiseLng');
            const officeLat = localStorage.getItem('officeLat');
            const officeLng = localStorage.getItem('officeLng');
            distanceObj['key'] =
              'get-FSR-Valid-Location-' + this.getEmployeeId(true);
            distanceObj['message'] = 'Location issue';
            distanceObj['firstWorkOrderLatitude'] = firstWorkOrderLatitude;
            distanceObj['firstWorkOrderLongitude'] = firstWorkOrderLongitude;
            distanceObj['lastPremiseLatitude'] = lastPremiseLat
              ? lastPremiseLat
              : '';
            distanceObj['lastPremiseLongitude'] = lastPremiseLng
              ? lastPremiseLng
              : '';
            distanceObj['officeLatitude'] = officeLat ? officeLat : '';
            distanceObj['officeLongitude'] = officeLng ? officeLng : '';
            this.postLocationLogs(distanceObj);
            this.logsPosted = true;
          }
        }
      }
      this.pushLocation(coords);
    } else {
      coords = this.maskLocation();
      locationObj['maskedCoordinates'] = coords;
      locationObj['status'].push(
        'Not got coords from browser, directly masking location'
      );
      locationObj['status'].push('Masked Coordinates: ', coords);
      this.pushLocation(coords);
    }
    this.setLocationCalls(locationObj);
  }

  maskLocation() {
    const logObj = { method: 'maskLocation', msg: 'Location->' };
    this.displayLogs(logObj, false);
    let coords = {};
    const lastPremiseLat = localStorage.getItem('lastPremiseLat');
    const lastPremiseLng = localStorage.getItem('lastPremiseLng');
    if (lastPremiseLat && lastPremiseLng) {
      coords = {
        latitude: lastPremiseLat,
        longitude: lastPremiseLng,
      };
      localStorage.setItem('overRideLocation', 'true');
      this.locationEnabled = false;
    } else {
      const officeLat = localStorage.getItem('officeLat');
      const officeLng = localStorage.getItem('officeLng');
      if (officeLat && officeLng) {
        coords = {
          latitude: officeLat,
          longitude: officeLng,
        };
        localStorage.setItem('overRideLocation', 'true');
        this.locationEnabled = false;
      }
    }
    return coords;
  }

  pushLocation(data) {
    const logObj = { method: 'pushLocation', msg: 'Location->' };
    this.displayLogs(logObj, false);
    const locationObj = {};
    locationObj['status'] = [];
    locationObj['status'].push('Pushing Location ====>');
    const oldCoords = {
      latitude: data['latitude'],
      longitude: data['longitude'],
    };
    locationObj['status'].push('Old Coordinates: ', oldCoords);
    if (oldCoords && oldCoords.latitude && oldCoords.longitude) {
      localStorage.setItem('oldLatitude', oldCoords.latitude);
      localStorage.setItem('oldLongitude', oldCoords.longitude);
    }
    const coords = {
      latitude: data['latitude'],
      longitude: data['longitude'],
    };
    locationObj['publish'] = coords;
    locationObj['status'].push('New Coordinates: ', coords);
    if (
      coords['latitude'] &&
      coords['longitude'] &&
      (coords['latitude'].toString() !== oldCoords.latitude ||
        coords['longitude'].toString() !== oldCoords.longitude)
    ) {
      locationObj['status'].push(
        'Publishing Coordinates in an array: ' + JSON.stringify(coords)
      );
      const logObj = {
        method: 'Sharing Location with App',
        msg: 'Location->',
        data: data,
      };
      this.displayLogs(logObj, false);
      this.currentLocation.next(coords);
      localStorage.setItem('latitude', coords['latitude'].toString());
      localStorage.setItem('longitude', coords['longitude'].toString());
      locationObj['status'].push('Status ====> True');
      if (this.online) {
        this.updateFSRLocation(coords).subscribe((res) => { });
      }
    } else {
      locationObj['status'].push('Status ====> False');
      this.currentLocation.next(coords);
      this.updateFSRLocation(coords).subscribe((res) => { });
    }
    this.setLocationCalls(locationObj);
  }

  updateFSRLocation(data) {
    const logObj = { method: 'updateFSRLocation', msg: 'Location->' };
    this.displayLogs(logObj, false);
    if (this.getUserId()) {
      const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
      const userId = this.getEmployeeId() || '';
      data = {
        fsrId: JSON.parse(userId),
        latitude: data.latitude.toString(),
        longitude: data.longitude.toString(),
        updateTime: currentTime,
      };
      if (this.locationArray && this.locationArray.length === 0) {
        this.locationArray.push(data);
      } else {
        const lastIndexObj = this.locationArray[this.locationArray.length - 1];
        if (
          data.latitude !== lastIndexObj.latitude &&
          data.longitude !== lastIndexObj.longitude
        ) {
          this.locationArray.push(data);
        }
      }
      return this.emptyresponce(true);
    }
  }

  getTruckLocation(truckId) {
    console.log("aaabb:this gets called");
    const logObj = { method: 'getTruckLocation', msg: 'Location->' };
    this.displayLogs(logObj, false);
    if (truckId) {
      this.fetchTruckLocation(truckId);
      this.clearLocationInterval();
      this.locationInterval = setInterval(() => {
        this.fetchTruckLocation(truckId);
      }, TRUCK_LOCATION_INTERVAL);
    } else {
      this.getCurrentLocation();
    }
  }

  fetchTruckLocation(truckId) {
    const logObj = { method: 'fetchTruckLocation', msg: 'Location->' };
    this.displayLogs(logObj, false);
    const userId = this.getEmployeeId(true);
    const obj = {};
    obj['key'] = 'fetch-Gis-Truck-location-error-' + userId;
    obj['message'] = 'Location issue';
    if (this.online) {
      // if (truckId) {
      /*
       **** getTruckLocations() finction Should be uncomment ****
       **** after the new Geo Location API is done from backend ****
      */

      // this.getTruckLocations(truckId).subscribe(
      //   (res) => {
      //     console.log(res, 'local api call')
      //     if (res && res.features && res.features.length > 0 && userId) {
      //       const coords = {
      //         latitude: res.features[0].geometry.coordinates[1],
      //         longitude: res.features[0].geometry.coordinates[0],
      //       };
      //       this.validateBrowserLocation(coords);
      //     } else {
      //       if (!this.logsPosted) {
      //         obj['error'] = 'no response from GIS';
      //         this.postLocationLogs(obj);
      //         this.logsPosted = true;
      //       }
      //       this.maskLocation();
      //     }
      //   },
      //   (err) => {
      //     if (!this.logsPosted) {
      //       obj['error'] = err;
      //       this.postLocationLogs(obj);
      //       this.logsPosted = true;
      //     }
      //   }
      // );
      // } else {
      //   this.maskLocation();
      // }

      this.maskLocation(); // Should be removed once the GEO Location API is Integrated and above commented lined should be enabled
    }
  }

  getTruckLocations(truckId) {
    const logObj = { method: 'getTruckLocations', msg: 'Location->' };
    this.displayLogs(logObj, false);
    const url =
      API_URL.truckLocation +
      truckId +
      '&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=DeviceID,+TimeStampe&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&having=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&sqlFormat=none&resultType=&f=geojson';
    const api = this.getCall(url);
    return api;
  }

  clearLocationInterval() {
    const logObj = { method: 'clearLocationInterval', msg: 'Location->' };
    this.displayLogs(logObj, false);
    if (this.locationInterval) {
      clearInterval(this.locationInterval);
    }
  }

  findDistance(lat1, lon1, lat2, lon2) {
    // console.log('M->', lat1, lon1, lat2, lon2);
    const R = 6371; // km
    const dLat = this.toRad(lat2 - lat1);
    const dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    // console.log('M->',d * 1000);
    return d * 1000;
  }

  toRad(value) {
    return (value * Math.PI) / 180;
  }

  getEmployeeId(mappedId = false) {
    //const claims = localStorage.getItem('id_token_claims_obj');
    const claims =  this.authservice.claims;
    let empID = '';
    if (claims && claims !== 'undefined' && claims !== 'null') {
      empID = claims.Employee_ID;
      const mappedIdVal = localStorage.getItem('mappedId');
      if (mappedId && empID && empID.length === 9 && mappedIdVal) {
        empID = mappedIdVal;
      }
      empID = empID && empID !== 'undefined' && empID !== 'null' ? empID : '';
    } else {
      empID = '';
    }
    return empID;
  }

  getUserId() {
    const claims = this.authservice.claims;
    return claims && claims !== 'undefined' && claims !== 'null'
      ? claims.sub1
      : null;
  }

  getToken() {
    var storage: any;
    this.oktaAuthService.authState$.subscribe((x)=> {
      if(x.isAuthenticated){
        storage =  window.localStorage['okta-token-storage'] ? JSON.parse(window.localStorage['okta-token-storage']) : null;
      }
    });
    return storage.accessToken.accessToken ? storage.accessToken.accessToken : '';
  }

  displayLogs(logs: any, capture: boolean) {
    if (false) {
      console.log(logs, moment().format('HH:mm:ss'));
    }
    if (capture) {
      //this.captureLogs(logs);
    }
  }

  captureLogs(logs) {
    const fsrId = this.getEmployeeId(true);
    if (fsrId) {
      let connection: any = '';
      if (navigator && navigator['connection']) {
        connection = navigator['connection'];
      }
      const logObj = {
        logs: logs,
        device: this.platform.IOS ? 'Ipad' : 'Desktop',
        employeeId: this.getEmployeeId(true),
        latitude: localStorage.latitude,
        longitude: localStorage.longitude,
        evironment: environment,
        online: this.online,
        nontransitions: this.queue.length,
        transitions: this.transitions.length,
        timestamp: moment().format('LLLL'),
        effectiveType:
          connection && connection.effectiveType
            ? connection.effectiveType
            : '',
        rtt: connection && connection.rtt ? connection.rtt : '',
        downlink: connection && connection.downlink ? connection.downlink : '',
      };
      this.addLog(logObj, 'log-' + moment().valueOf()).subscribe((res) => { });
    }
  }

  fixIndexDbIssue(err) {
    if (
      err &&
      err.message &&
      err.message.indexOf(
        'One of the specified object stores was not found'
      ) !== -1
    ) {
      window.location.href = '/fix-db.html';
    }
  }

  addDayToGivenDate(dateValue) {
    return moment(dateValue).add(1, 'day').toDate();
  }

  toDate(dateValue) {
    return moment(dateValue).toDate();
  }

  getYear(dateValue) {
    return moment(dateValue).year();
  }
  getMonth(dateValue) {
    return moment(dateValue).month() + 1;
  }
  getday(dateValue) {
    return moment(dateValue).date();
  }
  getminutes(dateValue) {
    return moment(dateValue).minutes();
  }

  gethourBydate(dateValue) {
    return moment(dateValue).hours();
  }

  formatDateTime(date, offset, format) {
    if (date) {
      date = moment(date).format(format);
      return date;
    }
    return null;
  }

  formatDate(d) {
    const datestring =
      this.getYear(d) +
      '-' +
      ('0' + this.getMonth(d)).slice(-2) +
      '-' +
      ('0' + this.getday(d)).slice(-2) +
      ' ' +
      ('0' + this.gethourBydate(d)).slice(-2) +
      ':' +
      ('0' + this.getminutes(d)).slice(-2) +
      ':00';
    return datestring;
  }

  getTimestampbyDate(dateValue) {
    return moment(dateValue).valueOf();
  }

  getESTitemstamp() {
    return moment
      .tz(moment().toDate(), easterTimeZone)
      .format('YYYY-MM-DDHH:mm:ss');
  }

  getCurrentDate() {
    return moment().toDate();
  }

  getCurrentTime() {
    return moment().valueOf();
  }

  getCurrentYear() {
    return moment().year();
  }

  getCurrentMonth() {
    return moment().month() + 1;
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  emptyresponce(ignore?) {
    const observable = Observable.create((observer) => {
      setTimeout(() => {
        observer.next({});
      }, 2000);
    });
    return observable;
  }

  async getCallMat(url: string, options?: any) {
    var matData: any;
    if (this.online || url.indexOf('ping') !== -1) {
      const options = this.getHeader();
      const api = await this.http.get(url, options).subscribe( data => {matData = data;      
      this.dbService.update('arrivalData', matData).subscribe(
        (x) => { },
        (err) => {
          return console.warn(
            'Error in saving arrival data in indexdb at setting stage',
            err
          );
        }
      ); 
      return matData;
      },
      //{err => this.handleError(err) }
      );

    } else {
      return this.emptyresponce();
    }
  }

  getCall(url: string, options?: any) {
    if (this.online || url.indexOf('ping') !== -1) {
      options = this.getHeaders();
      const api = this.http.get(url, options).pipe(
        map((res: Response | any) => {
          this.setNetworkCalls({ options, response: res, url });
          return res;
        }), 
        catchError((err) => this.handleError(err))
      );
      return api;
    } else {
      return this.emptyresponce();
    }
  }

  getMgmtCall(url: string, options?: any) {
    if (this.online) {
      options = this.getW1VManagementHeaders();
      const api = this.http.get(url, options).pipe(
        map((res: Response | any) => {
          this.setNetworkCalls({ options, response: res, url });
          return res;
        }),
        catchError((err) => this.handleError(err))
      );
      return api;
    } else {
      return this.emptyresponce();
    }
  }

  awappsgetCall(url: string, options?: any) {
    if (this.online || url.indexOf('ping') !== -1) {
      options = this.getHeader();
      const api = this.http.get(url, options).pipe(
        map((res: Response | any) => {
          this.setNetworkCalls({ options, response: res, url });
          return res;
        }),
        catchError((err) => this.handleError(err))
      );
      return api;
    } else {
      return this.emptyresponce();
    }
  }

  postCall(url, data, key, options?, disableDBStorage = true) {
    if (
      url.indexOf('updateWorkOrderAndSequenceStatus') !== -1 ||
      url.indexOf('saveWoSoCompletion') !== -1 ||
      url.indexOf('adhocSoCreation') !== -1
    ) {
      const log = { url: url, 'db-key': key, payload: data };
      this.displayLogs(log, true);
    }
    for (let i = 0; i < PREVENT_QUEUE_URL.length; i++) {
      if (url && url.indexOf(PREVENT_QUEUE_URL[i]) !== -1) {
        disableDBStorage = false;
        break;
      }
    }
    if (!disableDBStorage && this.online) {
      if (!options) {
        options = this.getHeaders();
      }
      const api = this.http.post(url, data, options).pipe(
        map((res: Response | any) => {
          this.setNetworkCalls({ options, response: res, payload: data, url });
          return res;
        }),
        catchError((err) => this.handleError(err, data))
      );
      return api;
    } else {
      this.addInIndexDB(url, data, key);
      return this.emptyresponce();
    }
  }

  addInIndexDB(url, data, key) {
    const time = this.getTimestampbyDate(moment().toDate());
    const datavalue = {
      url: url,
      data: data,
      key: time + '-' + key,
      time: time,
    };
    if (key === 'sentNotes') {
      const lastIndexOf = url.lastIndexOf('/');
      url = url.substring(0, lastIndexOf) + '/';
    }
    if (url && TRANSITIONS_QUEUE.indexOf(url) !== -1) {
      this.addTransactionEntry(datavalue);
      // this.getTransactionCount();
    } else {
      this.addNonTransactionEntry(datavalue);
    }
  }

  // getTransactionCount() {
  //     const logObj = { 'method': 'getTransactionCount', 'msg': 'Sync->' };
  //     this.displayLogs(logObj, false);
  //     this.dbService.count('transitions').subscribe(
  //         (count) => {
  //             const logObj = { 'method': 'Inside getTransactionCount -> transitionsCount -> ' + count, 'msg': 'Sync->' };
  //             this.displayLogs(logObj, false);
  //             this.transitionsCount = count;
  //         }
  //     );
  // }

  addTransactionEntry(data) {
    const logObj = { method: 'addTransactionEntry', msg: 'Sync->', data: data };
    this.displayLogs(logObj, false);
    this.dbService.update('transitions', data).subscribe(
      () => {
        const logObj = {
          method:
            'Inside addTransactionEntry -> ' +
            data.key +
            ' ' +
            data['url'] +
            ' ' +
            moment().format('HH:mm:ss'),
          msg: 'Sync->',
        };
        this.displayLogs(logObj, false);
      },
      (error) => {
        console.log(error);
        const logObj = {
          method: 'addTransactionEntry',
          msg: 'Sync-> IndexDb',
          error: error,
        };
        this.displayLogs(logObj, true);
      }
    );
  }

  addNonTransactionEntry(data) {
    const logObj = {
      method: 'addNonTransactionEntry',
      msg: 'Sync->',
      data: data,
    };
    this.displayLogs(logObj, false);
    this.dbService.update('nontransitions', data).subscribe(
      (res) => {
        const logObj = {
          method:
            'Inside addNonTransactionEntry -> ' +
            data.key +
            ' ' +
            data['url'] +
            ' ' +
            moment().format('HH:mm:ss'),
          msg: 'Sync->',
          data: res,
        };
        this.displayLogs(logObj, false);
      },
      (error) => {
        console.log(error);
        const logObj = {
          method: 'addNonTransactionEntry',
          msg: 'Sync-> IndexDb',
          error: error,
        };
        this.displayLogs(logObj, true);
      }
    );
  }

  trasctionalUpdate(transationData, workOrderData) {
    const logObj = { method: 'trasctionalUpdate', msg: 'Sync->' };
    this.displayLogs(logObj, false);
    let promise = new Promise((resolve, reject) => {
      let db;
      const DBOpenRequest = window.indexedDB.open(dbConfig.name);
      DBOpenRequest.onsuccess = (event) => {
        db = DBOpenRequest.result;
        var transaction = db.transaction([dbConfig.name], 'readwrite');

        // report on the success of opening the transaction
        transaction.oncomplete = (event) => {
          // console.log('Sync-> <li>Transaction completed: database modification finished.</li>');
          resolve(event);
        };

        transaction.onerror = (event) => {
          // console.log('Sync-> <li>Transaction not opened due to error. Duplicate items not allowed.</li>');
          reject(event);
        };

        const transitionsStore = transaction.objectStore('transitions');
        const workOrdersStore = transaction.objectStore('workOrders');
        try {
          let request = transitionsStore.put(
            transationData.value,
            transationData.key
          );
          request.onsuccess = (res) => {
            // console.log('Sync-> trsuccess', res);
          };
          request.onerror = (err) => {
            // console.log('Sync-> trerror', err);
            transaction.abort();
          };

          let req = workOrdersStore.put(workOrderData.value);
          req.onsuccess = (res) => {
            // console.log('Sync-> wosuccess', res);
          };
          req.onerror = (err) => {
            // console.log('Sync-> woerror');
            transaction.abort();
          };
        } catch (e) {
          transaction.abort();
        }
      };
    });
    return promise;
  }

  getHeaders() {
    let obj: any = {};
    const token = this.getToken();
    if (token) {
      obj['Authorization'] = token;
      // obj['w1v-version'] = '4';
      obj['APIKey'] = API_INFO.apiKey;
      //obj['Access-Control-Allow-Credentials'] = true;
    }
    const headers = new HttpHeaders(obj);
    const options: any = {};
    options['headers'] = headers;
    return options;
  }
  getHeader() {
    let obj: any = {};
    const token = this.getToken();
    if (token) {
      obj['Authorization'] = token;
      // obj['w1v-version'] = '4';
    //obj['Access-Control-Allow-Credentials'] = true;
    }
    const headers = new HttpHeaders(obj);
    const options: any = {};
    options['headers'] = headers;
    return options;
  }

  getHeaderMat() {
    let obj: any = {};
    const token = this.getToken();
    if (token) {
      obj['Authorization'] = token;
      // obj['w1v-version'] = '4';
    }
    const headers = new HttpHeaders().set('Authorization', token);
    const options: any = {};
    options['headers'] = headers;
    return options;
  }

  getHeadersNoBearer() {
    let obj: any = {};
    const token = this.getToken();
    if (token) {
      obj['Authorization'] = token.slice(6);
      obj['APIKey'] = API_INFO.apiKey;
      //obj['Access-Control-Allow-Credentials'] = true;
    }
    const headers = new HttpHeaders(obj);
    const options: any = {};
    options['headers'] = headers;
    return options;
  }
  getSoCompletionHeaders() {
    let obj: any = {};
    const token = this.getToken();
    if (token) {
      obj['Authorization'] = token;
      obj['appVersion'] = '4';
      obj['APIKey'] = API_INFO.apiKey;
      //obj['Access-Control-Allow-Credentials'] = true;
    }
    const headers = new HttpHeaders(obj);
    const options: any = {};
    options['headers'] = headers;
    return options;
  }

  removeLeadingZeros(str) {
    // const regex = new RegExp('^0+(?!$)', 'g');
    // str = str.replaceAll(regex, '');
    return str;
  }

  removeLeadingZeros2(str) {
    str = JSON.stringify(str);
    if (str) {
      const regex = new RegExp('^0+(?!$)', 'g');
      str = str.replaceAll(regex, '');
    }
    return str;
  }

  handleError(error: HttpErrorResponse, payload?) {
    const errorObj: any = {};
    if (error) {
      errorObj.keyword = 'API Error';
      errorObj.message = 'FSR - ' + this.getEmployeeId(true);
      errorObj.payload = payload;
      errorObj.error = error;
      if (!error.url || (error.url && error.url.indexOf('addUiLog') === -1)) {
        const logObj = { method: 'handleError', msg: 'Sync->', data: error };
        this.displayLogs(logObj, true);
      }
      this.setNetworkCalls({ response: error, payload, url: error.url });
      const details = { detail: error, status: error.status };
      if (error.status === 500 || error.status === 504) {
        return this.emptyresponce();
      }
      if (
        error &&
        error.url &&
        (details.status == 401 || error.url.indexOf('addUiLog') === -1)
      ) {
        if (details.status === 401 && !this.loggingOut) {
          this.loggingOut = true;
          localStorage.removeItem('isOpen');
          sessionStorage.removeItem('isOpen');
          localStorage.removeItem('tabCount');
          // alert('Your session got timed out. Please login again for a new session.');
          //   this.logoutApp(true);
          // this.logout();
        }
      }
      return this.emptyresponce();
    } else {
      return this.emptyresponce();
    }
  }

  logout() {
    window.location.href = '/logout.html';
  }

  addLog(data, key?) {
    data = JSON.stringify(data);
    const api = this.postCall(API_URL.addUiLog, data, key);
    return api;
  }

  redirectToDashboard(order) {
    const hash = location.hash;
    if (hash) {
      const arr = location.hash.split('id=');
      if (order && arr && arr.length > 1 && arr[1] === order) {
        setTimeout(() => {
          this.router.navigateByUrl('/orders');
        }, 3000);
      }
    }
  }

  playNotification() {
    // if (localStorage.getItem('notificationSound')) {
    //     this.notifactionVolume = JSON.parse(localStorage.getItem('notificationSound'));
    // }
    // setTimeout(() => {
    //     const sound = new Howl({
    //         src: ['assets/notification_voice_message.mp3'],
    //         autoplay: true,
    //         volume: this.notifactionVolume ? 0.6 : 0,
    //         onend: function () {
    //         }
    //     });
    //     sound.play();
    // }, 10);
  }
  localToGMT() {
    const date = this.getCurrentTime();
    const currentTime = moment(date).format("YYYY-MM-DD hh:mm:ss A");
    let utctime = moment(currentTime, "YYYY-MM-DD hh:mm:ss A").utc();
    return utctime.format("YYYY-MM-DD HH:mm:ss");
  }
  updateFsrStatus(): Observable<any> {
    const fsrId = this.getEmployeeId();
    const options = this.getHeaders();
    const status = localStorage.availableStatus;
    const currentTime = this.localToGMT();
    const url = `${API_URL.updateFsrStatus}/${fsrId}/${localStorage.availableStatus}?availabilityLastUpdated=${currentTime}`;
    if (!status || !fsrId) {
      return this.emptyresponce(true);
    } else if (status == '21') {
      return this.http.post(url, {}, options);
    } else {
      return this.postCall(
        url,
        {},
        'updateFsrStatus'
      );
    }
  }

  storeAvailibilityStatus(statusID) {
    localStorage.setItem('availableStatus', JSON.stringify(statusID));
  }

  updateAvailibilityStatus(statusID) {
    this.status = statusID;
    this.storeAvailibilityStatus(this.status);
    this.setAvailableStatus();
    this.updateFsrStatus().subscribe((response: any) => {
      console.info(response);
    }, err => {
      console.log('Error: ' + err);
    });
  }

  getW1VManagementHeaders() {
    let obj: any = {};
    const token = this.getToken();
    if (token) {
      obj['Authorization'] = token;
      // obj['w1v-version'] = '4';
      obj['APIKey'] = API_INFO.apiKey;
      //obj['Access-Control-Allow-Credentials'] = true;
    }
    const headers = new HttpHeaders(obj);
    const options: any = {};
    options['headers'] = headers;
    return options;
  }

  getAllBannerData(): Observable<any> {
    return this.getMgmtCall(`${W1VMGMT_API_URL.bannerData}/W1VFIELD`);
  }

  getMapPins(sequenceNum, color = '#999', labelRequired, size? : boolean){
    
    const colorMap = {
      '#DA5A2F' : '#FFFFFF',
      '#B50A3D' :	'#FFFFFF',
	    '#1B81CC' :	'#FFFFFF',
	    '#FFC300' :	'#2B2E2F',
      '#6A7583' :	'#FFFFFF',
	    '#6EA000' :	'#0E1315',
	   
      //
      '#d2691e' : 'white',
      '#ff5d5d' :	'black',
	    '#08bde4' :	'black',
	    '#ffc300' :	'black',
      '#999' :	'black',
	    '#0978eb' :	'white',
	    '#008000' :	'white',
      '#662773' : 'white'
      
    }
    
    const customIcon = {
     svgMarker : {
      path: "M14.5 1.18433C7.88316 1.18433 2.5 6.54567 2.5 13.1356C2.5 21.314 13.2388 33.3202 13.696 33.8274C14.1255 34.3037 14.8753 34.3029 15.304 33.8274C15.7612 33.3202 26.5 21.314 26.5 13.1356C26.4999 6.54567 21.1168 1.18433 14.5 1.18433Z",
      fillColor:  '#' + color.toUpperCase(),
      fillOpacity: 1,
      //rotation: 0,
      //scale: 1,
      strokeColor: color == '999' || color == 'ffc300' ? 'black' : '#D9E7F0',
      strokeWeight:2,
      anchor: size ? [29, 58] : [16,32],
      size: size ?  [58, 58] : [32, 32],
      scaledSize: size ?  [58, 58] : [32, 32],
      labelOrigin: size ? [27,27] : [14, 14],
      
    },
    label : labelRequired ? {
      text: sequenceNum.toString(),
      color: colorMap['#' + color]? colorMap['#' + color]: "white",
      fontSize: '16px',
      //fontWeight: 'bold'
    } : null,
    //size: new google.maps.Size(32, 32),
    //scaledSize: new google.maps.Size(32, 32), 
  }
  return customIcon;
  }
}

