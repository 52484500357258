<div class="mat-container">
  <div class="mat-header"></div>
  <div class="mat-content">
    <div class="images"><img src="/assets/images/icons/error_outline.svg"></div>
    Unable to reach system.<br/>Retry validation.
  </div>
  <div class="action-bottom">
    <button (click)="close()">Ok</button>
  </div>
</div>
