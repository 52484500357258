import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  title:string;
  description:string;
  button:string;
}

@Component({
  selector: 'app-warning-message-dailog',
  templateUrl: './warning-message-dailog.component.html',
  styleUrls: ['./warning-message-dailog.component.scss']
})
export class WarningMessageDailogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<WarningMessageDailogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
    
  }

  save(): void {
    this.dialogRef.close();
  }
}
