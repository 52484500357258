import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Equipment, Tile } from '../interfaces/capture-form.interfaces';
import { WorkOrder } from '../interfaces/order.interfaces';
import { map, filter } from 'lodash';
import { API_URL, DEFAULT_MAX_DIALS } from '../app.config';
import { Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { CaptureFormConfig } from '../config/capture-form-config';
import { UtilitiesService } from './utilities.service';
import { ToasterSevice } from './ToasterService.service';
import { InspectionScreenComponent } from '../components/capture-form/inspection-screen/inspection-screen.component';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { MeterActionsService } from './meter-actions.service';


@Injectable()
export class CaptureFormService {
  config = CaptureFormConfig;
  fscreen!: string;

  public isActionBtnTriggered = new BehaviorSubject<any>(false);
  validatePopup: any;
  public getOrder = new BehaviorSubject<WorkOrder | null>(null);
  public getDetails = new BehaviorSubject<any | null>(null);
  isValidInput: boolean = true;
  isInvalidInvestigationExpiryDate: boolean = false;
  errorInRegisterReasonCode: boolean = false;

  private dataSource = new BehaviorSubject<any>(null);
  public data$ = this.dataSource.asObservable();

  updateDataSource(data: any) {
    this.dataSource.next(data);
  }

  tiles: Tile[] = [
    { meterDirectional: 'RL', meterSupplemental: 'OS', content: 'Back Left', colspan: 3, rowspan: 1, data: [], class: 'curve-top-left' },
    { meterDirectional: 'RE', meterSupplemental: 'OS', content: 'Back Right', colspan: 3, rowspan: 1, data: [], class: 'curve-top-right' },
    { meterDirectional: 'LR', meterSupplemental: 'OS', content: 'Side Left Rear', colspan: 1, rowspan: 1, data: [], class: 'side left' },
    { meterDirectional: 'RL', meterSupplemental: 'IS', content: 'Inside Back Left', colspan: 2, rowspan: 1, data: [], class: 'inside' },
    { meterDirectional: 'RE', meterSupplemental: 'IS', content: 'Inside Back Right', colspan: 2, rowspan: 1, data: [], class: 'inside' },
    { meterDirectional: 'RA', meterSupplemental: 'OS', content: 'Side Right Rear', colspan: 1, rowspan: 1, data: [], class: 'side' },
    { meterDirectional: 'LF', meterSupplemental: 'OS', content: 'Side Left Front', colspan: 1, rowspan: 1, data: [], class: 'side left' },
    { meterDirectional: 'FL', meterSupplemental: 'IS', content: 'Inside Front Left', colspan: 2, rowspan: 1, data: [], class: 'inside' },
    { meterDirectional: 'FS', meterSupplemental: 'IS', content: 'Inside Front Right', colspan: 2, rowspan: 1, data: [], class: 'inside' },
    { meterDirectional: 'RF', meterSupplemental: 'OS', content: 'Side Right Front', colspan: 1, rowspan: 1, data: [], class: 'side' },
    { meterDirectional: 'FL', meterSupplemental: 'OS', content: 'Front Left', colspan: 3, rowspan: 1, data: [], class: 'curve-bottom-left' },
    { meterDirectional: 'FS', meterSupplemental: 'OS', content: 'Front Right', colspan: 3, rowspan: 1, data: [], class: 'curve-bottom-right' }
  ];
  updateOrder(data: WorkOrder) {
    this.getOrder.next(data);
  }

  updateDetails(data: any) {
    this.getDetails.next(data);
  }

  updateActionBtnStatus(data: any) {
    this.isActionBtnTriggered.next(data);
  }

  errArr: any = [];
  errors: any = {
    arrival: [],
    premise: [],
    meter: [],
    'meter test': [],
    activity: [],
    inspection: [],
    complete: [],
  };

  premise: any = {
    safety: '',
    lockPlugMeter: '',
    devices: {
      meter: {
        id: 'meter',
        name: 'Meter Device',
        directional: '',
        supplemental: '',
        position: '',
        selected: false,
        img: 'assets/images/capture-form/meter-device.png',
      },
      reading: {
        id: 'reading',
        name: 'Reading Device',
        directional: '',
        supplemental: '',
        position: '',
        selected: false,
        img: 'assets/images/capture-form/reading-device.png',
      },
    },
    safetyNotes: '',
    heatType: '',
    curbBoxMeasurementDescription: '',
    secureAccess: '',
    activityNotes: {
      'FSR Name': { value: '', time: 0 },
      'Meter Location': { value: '', time: 0 },
      'Meter Location changed to': { value: '', time: 0 },
      'Reading Location': { value: 'trt', time: 0 },
      'Reading Location changed to': { value: '', time: 0 },
      'Secure Access': { value: '', time: 0 },
      'Secure Access changed to': { value: '', time: 0 },
      'Safety Alert': { value: '', time: 0 },
      'Heat Type': { value: '', time: 0 },
      'Heat Type changed to': { value: '', time: 0 },
      'Safety Alert Notes': { value: '', time: 0 },
      'LockPlug Meter': { value: '', time: 0 },
      'LockPlug Meter changed to': { value: '', time: 0 },
      Notes: { value: '', time: 0 },
    },
  };

  meters: Equipment[] = [];

  public register: any = {
    meterSerialNumber: '',
    manufactureSerialNumber: '',
    meterSize: '',
    collectionType: '',
    uom: '',
    'rf-miu': '',
    'tp-encoder': '',
    dials: '',
    currentRead: '',
    isNoRead: '',
    previousRead: '',
    previousReadDate: '',
  };

  complete: any = {
    serviceComplete: '',
    reasonCategory: '',
    reasonSelect: '',
    twoPerson: '',
    miscellaneousInvoice: '',
    officeReview: '',
    informationIssueReport: '',
    notes: '',
    backOfficeComments: '',
    register1ReasonCode: '',
    rating: '',
    reasonRating: [],
    activityNotes: {
      'Service Order was': { value: '', time: 0 },
      'Incomplete Reason Category': { value: '', time: 0 },
      'Incomplete Reason': { value: '', time: 0 },
      'Crew size needed': { value: '', time: 0 },
      'MISC Invoice': { value: '', time: 0 },
      'Office Review': { value: '', time: 0 },
      'Office Review Notes': { value: '', time: 0 },
      'Feedback Rating': { value: '', time: 0 },
      'Feedback Reason': { value: '', time: 0 },
      'RZC Reason 1': { value: [], time: 0 },
      'RZC Reason 2': { value: [], time: 0 },
      'Photos Captured': { value: '', time: 0 },
      'Investigation Expire Date' : {value: '', time:0},
      'Notes Captured by FSR': { value: '', time: 0 },
    },
    notesGenerated: '',

    investigationExpiryDate: '',
  };

  inspection: any = {
    inspectService: '',
    serviceReason: '',
    filterPitcherorCartridge:'',
    customerInteraction :'',
    filterPitcherorCartridgeOtherReason : '',
    leaveLeadInformationMaterial : '',
    customerSideMaterial: '',
    customerSideLeak: '',
    needFollowUp: '',
    reasonFollow: '',
    fSRInteraction: '',
    purpose: '',
    sewer: '',
    sewerReason: [],
    sewerInspectionNotes: '',
    activityNotes: {
      'Service Line Material': { value: 'None', time: 0 },
      'Provided Pitcher Catridge' : { value: 'NO', time: 0 },
      'Pitcher Catridge Customer Interaction' : { value: '', time: 0 },
      'Pitcher Catridge Other reason' : { value: '', time: 0 },
      'Left Lead Information Material' : { value: 'NO', time: 0 },
      'Customer Side Leak': { value: 'None', time: 0 },
      'FUNO Selected': { value: '', time: 0 },
      'Premise pass sewer inspection': { value: '', time: 0 },
      'Reason for not passing': { value: '', time: 0 },
    },
  };

  meterActivites: any = {
    submitted: false,
    activitySubmitted: false,
    serviceLeftVal: '',
    serviceLeftAt: '',
    serviceFoundVal: '',
    serviceFoundAt: '',
    serviceFoundLabel: '',
    serviceLeftLabel: '',
    operatedPointOfControl: '',
    companyAction: [],
    primaryActivities: [],
    additionalActions: [],
    primaryActivityNotes: [],
    override:[],
    meters: [],
    
    selected: {},
    deviceLocationNotes: '',
    activityNotes: {
      'Found Service FSR Selected': { value: '', time: 0 },
      'Left Service FSR Selected': { value: '', time: 0 },
      'Point of Control Operated FSR Selected': { value: '', time: 0 },
      'Meter Activities': [],
      'Additional Actions Performed': { value: [], time: 0 },
      'Notes': { value: '', time: 0 },
      'Override Reason':{value:'',time:0}
    },
    meterNotes: [],
    meterTest: [],
    serviceFoundInDispatch: '',
  };

  constructor(
    private router: Router,
    private dbService: NgxIndexedDBService,
    private utilities: UtilitiesService,
    private toast: ToasterSevice,
    public dialog: MatDialog,
    private MeterActionsService:MeterActionsService
    
  ) { }

  resetFormData() {
    this.errors = {
      premise: [],
      meter: [],
      'meter test': [],
      activity: [],
      inspection: [],
      complete: [],
    };

    this.premise = {
      safety: '',
      lockPlugMeter: '',
      devices: {
        meter: {
          id: 'meter',
          name: 'Meter Device',
          directional: '',
          supplemental: '',
          position: '',
          selected: false,
          img: 'assets/images/capture-form/meter-device.png',
        },
        reading: {
          id: 'reading',
          name: 'Reading Device',
          directional: '',
          supplemental: '',
          position: '',
          selected: false,
          img: 'assets/images/capture-form/reading-device.png',
        },
      },
      safetyNotes: '',
      heatType: '',
      curbBoxMeasurementDescription: '',
      secureAccess: '',
      activityNotes: {
        'FSR Name': { value: '', time: 0 },
        'Meter Location': { value: '', time: 0 },
        'Meter Location changed to': { value: '', time: 0 },
        'Reading Location': { value: '', time: 0 },
        'Reading Location changed to': { value: '', time: 0 },
        'Secure Access': { value: '', time: 0 },
        'Secure Access changed to': { value: '', time: 0 },
        'Safety Alert': { value: '', time: 0 },
        'Heat Type': { value: '', time: 0 },
        'Heat Type changed to': { value: '', time: 0 },
        'Safety Alert Notes': { value: '', time: 0 },
        'LockPlug Meter': { value: '', time: 0 },
        'LockPlug Meter changed to': { value: '', time: 0 },
        Notes: { value: '', time: 0 },
      },
    };

    this.meters = [];

    this.register = {
      meterSerialNumber: '',
      manufactureSerialNumber: '',
      meterSize: '',
      collectionType: '',
      uom: '',
      'rf-miu': '',
      'tp-encoder': '',
      dials: '',
      currentRead: '',
      isNoRead: '',
      previousRead: '',
      previousReadDate: '',
    };

    this.complete = {
      serviceComplete: '',
      reasonCategory: '',
      reasonSelect: '',
      twoPerson: '',
      miscellaneousInvoice: '',
      officeReview: '',
      informationIssueReport: '',
      notes: '',
      backOfficeComments: '',
      rating: '',
      reasonRating: [],
      activityNotes: {
        'Service Order was': { value: '', time: 0 },
        'Incomplete Reason Category': { value: '', time: 0 },
        'Incomplete Reason': { value: '', time: 0 },
        'Crew size needed': { value: '', time: 0 },
        'MISC Invoice': { value: '', time: 0 },
        'Office Review': { value: '', time: 0 },
        'Office Review Notes': { value: '', time: 0 },
        'Feedback Rating': { value: '', time: 0 },
        'Feedback Reason': { value: '', time: 0 },
        'RZC Reason 1': { value: [], time: 0 },
        'RZC Reason 2': { value: [], time: 0 },
        'Photos Captured': { value: '', time: 0 },
        'Investigation Expire Date' : {value: '', time:0},
        'Notes Captured by FSR': { value: '', time: 0 },
      },
      notesGenerated: '',
    };

    this.inspection = {
      inspectService: '',
      serviceReason: '',
      filterPitcherorCartridge:'',
      customerInteraction :'',
      filterPitcherorCartridgeOtherReason : '',
      leaveLeadInformationMaterial : '',
      customerSideMaterial: '',
      customerSideLeak: '',
      needFollowUp: '',
      reasonFollow: '',
      fSRInteraction: '',
      purpose: '',
      sewer: '',
      sewerReason: [],
      sewerInspectionNotes: '',
      activityNotes: {
        'Service Line Material': { value: 'None', time: 0 },
        'Provided Pitcher Catridge' : { value: 'NO', time: 0 },
        'Pitcher Catridge Customer Interaction' : { value: '', time: 0 },
        'Pitcher Catridge Other reason' : { value: '', time: 0 },
        'Left Lead Information Material' : { value: 'NO', time: 0 },
        'Customer Side Leak': { value: 'None', time: 0 },
        'FUNO Selected': { value: '', time: 0 },
        'Premise pass sewer inspection': { value: '', time: 0 },
        'Reason for not passing': { value: '', time: 0 },
      },
    };

    this.meterActivites = {
      submitted: false,
      activitySubmitted: false,
      serviceLeftVal: '',
      serviceLeftAt: '',
      serviceFoundVal: '',
      serviceFoundAt: '',
      serviceFoundLabel: '',
      serviceLeftLabel: '',
      operatedPointOfControl: '',
      companyAction: [],
      meters: [],
      selected: {},
      location: '',
      activityNotes: {
        'Found Service FSR Selected': { value: '', time: 0 },
        'Left Service FSR Selected': { value: '', time: 0 },
        'Point of Control Operated FSR Selected': { value: '', time: 0 },
        'Meter Activities': [],
        'Additional Actions Performed': { value: [], time: 0 },
        Notes: { value: '', time: 0 },
        'Override Reason':{value:'',time:0},
      },
      meterNotes: [],
      meterTest: [],
    };
  }

  navigateFromScreen(dataObj: any, screen: string, nextScreen?: string) {
    console.log(dataObj, screen, nextScreen);
    if (nextScreen) {
      this.fscreen = nextScreen;
    }
    if(screen=="arrival")
      {
        if (nextScreen) {
          this.router.navigate(
            [
              this.config.PARENT_ROUTE +
              '/' +
              this.config.SECTION_ROUTE +
              '/' +
              nextScreen,
            ],
            { queryParams: { id: dataObj.order } }
          );
        }
      }
      else{
    this.dbService.update(screen, dataObj).subscribe(
      (data) => {
        if (nextScreen) {
          this.router.navigate(
            [
              this.config.PARENT_ROUTE +
              '/' +
              this.config.SECTION_ROUTE +
              '/' +
              nextScreen,
            ],
            { queryParams: { id: dataObj.order } }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  }

  mergeEquipmentObject(equipment) {
    const processedEquipment = Object.assign(new Equipment(), equipment);
    const dialsSet = this.getNoOfDials(processedEquipment, false);
    return dialsSet;
  }

  resetActivities(meter) {
    meter.activities = [];
    meter.meterAction = '';
    meter.miuAction = '';
    meter.errors.Maintain_Activities = false;
    meter.errors.Meter_Action = false;
    meter.errors.MIU_Action = false;
    return meter;
  }

  validatePremise(
    order: WorkOrder,
    workOrderNumber: string,
    screen: string = 'meter',
    skipNavigate: boolean = false
  ) {
    // debugger;
    this.errors.premise = [];
    if (order.fixedChargeNoMeter?.toLowerCase() != "yes" && this.meterActivites.meters.length != 0 && !(this.meterActivites.meters.length == 1 && this.meterActivites.meters[0].noMeterInfo == true)) {
      if (!this.premise.devices.meter || !this.premise.devices.meter.position) {
        this.errors.premise.push('meterPositionLocation');
      }
      if (
        !this.premise.devices.meter ||
        !this.premise.devices.meter.directional
      ) {
        this.errors.premise.push('meterDirectionalLocation');
      }
      if (
        !this.premise.devices.meter ||
        !this.premise.devices.meter.supplemental
      ) {
        this.errors.premise.push('meterSupplementalLocation');
      }
      console.log(this.premise.devices);
      if (
        this.premise.devices.reading &&
        this.premise.devices.reading.directional?!this.premise.devices.reading.position:false
      ) {
        this.errors.premise.push('Reading Device Position');
      }
    }

    const data: any = { data: this.premise, order: workOrderNumber };
    if (!skipNavigate) {
      this.navigateFromScreen(data, 'premise', screen);
    }
  }

  validateArrival(workOrderNumber: string,screen: string)
    {
      this.errors.arrival = [];
      const data: any = { data:this.premise, order: workOrderNumber };
      //console.log(this.meterActivites.meters, 'in arrival tab');
      this.navigateFromScreen(data, 'arrival', screen);
    }

    validateDetails(
      workOrderNumber: string,
      screen: string = 'details'){
        const data: any = { data: this.meterActivites, order: workOrderNumber };
        this.router.navigate(
          [
            this.config.PARENT_ROUTE +
            '/' +
            this.config.SECTION_ROUTE +
            '/' +
            'widgets',
          ]);
      }

  validateMeter(
    order: WorkOrder,
    workOrderNumber: string,
    screen: string,
    skipNavigate: boolean = false
  ) {
    this.errors.meter = [];
    const data: any = { data: this.meterActivites, order: workOrderNumber };
    if (order && order?.fixedChargeNoMeter?.toLowerCase() != 'yes') {
      if (this.meterActivites.meters.length == 0) {
        this.errors.meter.push('Please select no meter or an add meter or miu');
      }
    }
    if (this.isValidInput) {
      this.errors.meter.push('Enter a complete read or select "No Read Obtained"');
    }

    // debugger;
    // if (this.isValidInput) {
    //   this.errors.meter.push('');
    // }
    // //............
    // let outgoingId: any = [];
    // let outgoingDevice = {};
    // this.meterActivites.meters = _.map(this.meterActivites.meters, meter => {
    //   // if (meter.dualtoSingle && meter.miuNumber1 && meter.miuNumber2) {
    //   //   this.errors.meter.push('Single Meter can\'t have dual registers.');
    //   // } 
    //    if (meter.outgoingMeter || meter.outgoingMIU) {
    //     const id = (meter.outgoingMeter) ? meter.serialNumber : meter.miuNumber1;
    //     outgoingId.push(id);
    //     outgoingDevice[id] = meter;
    //   } else {
    //     if (outgoingId.indexOf(meter.oldNumberMeter) !== -1 || outgoingId.indexOf(meter.oldMIUSerialNumber) !== -1) {
    //       const id = outgoingId.indexOf(meter.oldNumberMeter) !== -1 ? meter.oldNumberMeter : meter.oldMIUSerialNumber;
    //       meter.oldSourceofRead = outgoingDevice[id].noRead == true ? "03" : (outgoingDevice[id].isAPIRead == true ? "25" : "01");
    //       meter.oldSourceofRead2 = outgoingDevice[id].noRead2 == true ? "03" : (outgoingDevice[id].isAPIRead == true ? "25" : "01");
    //     }
    //   }
    //   return meter
    // });
    console.log(this.meterActivites.meters, 'in meter tab');
    this.navigateFromScreen(data, 'meterActivites', screen);
  }

  validateMeterTest(
    workOrderNumber: string,
    screen: string,
    skipNavigate: boolean = false
  ) {
    const data: any = { data: this.meterActivites, order: workOrderNumber };
    this.navigateFromScreen(data, 'meterActivites', screen);
  }

  validateInspect(
    workOrderNumber: string,
    screen: string = 'complete',
    skipNavigate: boolean = false
  ) {
    this.errors.inspection = [];
    if (!this.inspection.inspectService) {
      this.errors.inspection.push('Inspect Service');
    }
    if (
      this.inspection.inspectService == 'Y' &&
      !this.inspection.customerSideMaterial && !this.inspection.serviceReason
    ) {
      this.errors.inspection.push('Service Line Material');
    }
    if (!this.inspection.filterPitcherorCartridge) {
      this.errors.inspection.push('Pitcher or Catridge Question');
    }
    if (
      this.inspection.filterPitcherorCartridge == 'YES' &&
      !this.inspection.customerInteraction 
    ) {
      this.errors.inspection.push('Customer Interaction');
    }
    if (
      this.inspection.filterPitcherorCartridge == 'YES' && this.inspection.customerInteraction  == 'OTHER' &&
      !this.inspection.filterPitcherorCartridgeOtherReason
    ) {
      this.errors.inspection.push('Other Reason');
    }
    if (
      this.inspection.inspectService == 'Y' && !this.inspection.customerSideMaterial
      && (this.inspection.serviceReason == 'Unknown' || this.inspection.serviceReason == 'Unclassified')
    ) {
      this.errors.inspection.push('Additional Details');
    }
    if (
      !this.inspection.customerSideLeak ||
      (this.inspection.customerSideLeak == 'Y' &&
        !this.inspection.fSRInteraction)
    ) {
      this.errors.inspection.push('Customer Side Leak');
    }
    if (
      this.inspection.customerSideLeak == 'Y' &&
      !this.inspection.fSRInteraction
    ) {
      this.errors.inspection.push('Customer Interaction');
    }
    if (!this.inspection.needFollowUp) {
      this.errors.inspection.push('Need FollowUp');
    }
    if (
      !this.inspection.needFollowUp ||
      (this.inspection.needFollowUp == 'Y' && !this.inspection.reasonFollow)
    ) {
      this.errors.inspection.push('Follow Up Task');
    }
    if (
      !this.inspection.needFollowUp ||
      (this.inspection.needFollowUp == 'Y' &&
        this.inspection.reasonFollow &&
        !this.inspection.purpose)
    ) {
      this.errors.inspection.push('Follow Up Purpose');
    }
    if(this.inspection.sewer !== ''){
    if(
      this.inspection.sewer == 'N'){
        this.inspection.activityNotes['Premise pass sewer inspection'].value = 'No';
        this.inspection.activityNotes['Premise pass sewer inspection'].time = moment().valueOf();
        let reason:any= [];
        
        this.inspection.sewerReason.map(v =>{
          reason.push(this.getValueName(v ,this.config.SEWER_PASS_INSPECTION))
      });
      this.inspection.activityNotes['Reason for not passing'].value = reason + ' '+ this.inspection.sewerInspectionNotes;
      this.inspection.activityNotes['Reason for not passing'].time = moment().valueOf();
    }else{
      this.inspection.activityNotes['Premise pass sewer inspection'].value = this.inspection.sewer === 'Y' ? 'Yes' : 'Did Not Inspect';
      this.inspection.activityNotes['Premise pass sewer inspection'].time = moment().valueOf();
    }
    }
    if(this.inspection.customerInteraction == "OTHER"){
      this.inspection.activityNotes['Pitcher Catridge Other reason'].value = this.inspection.filterPitcherorCartridgeOtherReason;
      this.inspection.activityNotes['Pitcher Catridge Other reason'].time = moment().valueOf();
    }else {
      this.inspection.filterPitcherorCartridgeOtherReason = "";
      this.inspection.activityNotes['Pitcher Catridge Other reason'].value = '';
      this.inspection.activityNotes['Pitcher Catridge Other reason'].time = moment().valueOf();
    }
    const data: any = { data: this.inspection, order: workOrderNumber };
    if (!skipNavigate) {
      this.navigateFromScreen(data, 'inspection', screen);
    }
  }

  validateActvities() {
    // debugger;
    this.errArr = [];
    let activitiesSelected = this.meterActivites.primaryActivities;
    if ((!activitiesSelected || activitiesSelected.length == 0)) {
      //obj.errors.Maintain_Activities = true;
      const meterErr =
        'Primary activities for Meter';
      if (this.errArr.indexOf(meterErr) === -1) {
        this.errArr.push(meterErr);
      }
    }
    // _.map(this.getNonPSTMeters(this.meterActivites.meters), (item) => {

    //   console.log(item);
    //   if (
    //     !item.addedMIU &&
    //     !item.addedMeter &&
    //     !item.incomingMeter &&
    //     !item.incomingMIU &&
    //     !item.outgoingMeter &&
    //     !item.outgoingMIU &&
    //     !item.removedMeter &&
    //     !item.removedMIU &&
    //     !item.replaceRegister1 &&
    //     !item.replaceRegister2 &&
    //     !item.noMeterInfo &&
    //     (item.activePSTOrder == true || item.disabledMeterForProcessingIndicator == true)
    //   ) {
    //     const obj = _.cloneDeep(item);
    //     obj.device = 'meter';
    //     obj.errors.Maintain_Activities = false;
    //     if ((!obj.activities || obj.activities.length == 0)) {
    //       obj.errors.Maintain_Activities = true;
    //       const meterErr =
    //         'maintain activities for Meter - ' + obj.serialNumber;
    //       if (this.errArr.indexOf(meterErr) === -1) {
    //         this.errArr.push(meterErr);
    //       }
    //     }
    //     meters.push(obj);
    //     if (
    //       selected &&
    //       selected.device === 'meter' &&
    //       selected.serialNumber === obj.serialNumber
    //     ) {
    //       self.meterActivites.selected = obj;
    //     }
    //   }
    //   else if (!item.outgoingMeter && !item.outgoingMIU) {

    //     if (
    //       // (item.noMeterInfo && !item.fixedNoChargeMeter) ||
    //       ((item.addedMeter && !item.outgoingMIU) ||
    //         item.incomingMeter ||
    //         (item.removedMeter && !item.incomingMIU) ||
    //         (!item.outgoingMeter && (item.replaceRegister1 || item.replaceRegister2))) ||
    //       (!item.noMeterInfo && !item.addedMIU && (item.activePSTOrder == true || item.disabledMeterForProcessingIndicator == true)) ||
    //       (item.noMeterInfo == true)


    //     ) {
    //       const obj = _.cloneDeep(item);
    //       obj.device = 'meter';
    //       obj.errors.Maintain_Activities = false;
    //       // if (!obj.activities || obj.activities.length == 0) {
    //       if ((!item.noMeterInfo || (item.noMeterInfo && item.fixedNoChargeMeter)) && !item.outgoingMeter && item.serialNumber && (!item.activities || item.activities.length == 0)) {
    //         obj.errors.Maintain_Activities = true;
    //         const meterErr =
    //           'maintain activities for Meter - ' + obj.serialNumber;
    //         if (this.errArr.indexOf(meterErr) === -1) {
    //           this.errArr.push(meterErr);
    //         }
    //       }
    //       obj.errors.Meter_Action = false;
    //       if ((!obj.meterAction && (item.addedMeter || item.removedMeter || item.incomingMeter || item.replaceRegister1 || item.replaceRegister2) && !item.outgoingMeter) || (!obj.meterAction && item.noMeterInfo && !item.fixedNoChargeMeter)) {
    //         obj.errors.Meter_Action = true;
    //         const meterErr =
    //           'action for Meter - ' + obj.serialNumber;
    //         if (this.errArr.indexOf(meterErr) === -1) {
    //           this.errArr.push(meterErr);
    //         }
    //       }
    //       meters.push(obj);
    //       if (
    //         selected &&
    //         selected.device === 'meter' &&
    //         selected.serialNumber === obj.serialNumber
    //       ) {
    //         self.meterActivites.selected = obj;
    //       }
    //     } else if (
    //       item.serialNumber &&
    //       !item.outgoingMeter &&
    //       !item.outgoingMIU &&
    //       !item.removedMeter &&
    //       (!item.noMeterInfo && (item.activePSTOrder == true || item.disabledMeterForProcessingIndicator == true))

    //     ) {
    //       const obj = _.cloneDeep(item);
    //       obj.device = 'meter';
    //       obj.errors.Maintain_Activities = false;
    //       if (!obj.activities || obj.activities.length == 0) {
    //         obj.errors.Maintain_Activities = true;
    //         const meterErr =
    //           'maintain activities for Meter - ' + obj.serialNumber;
    //         if (this.errArr.indexOf(meterErr) === -1) {
    //           this.errArr.push(meterErr);
    //         }
    //       }
    //       meters.push(obj);
    //       if (
    //         selected &&
    //         selected.device === 'meter' &&
    //         selected.serialNumber === obj.serialNumber
    //       ) {
    //         self.meterActivites.selected = obj;
    //       }
    //     }
    //     if (
    //       (item.addedMIU && !item.outgoingMeter) ||
    //       item.incomingMIU ||
    //       (item.removedMIU && !item.incomingMeter)
    //     ) {
    //       const obj = _.cloneDeep(item);
    //       obj.device = 'miu';
    //       obj.errors.MIU_Action = false;
    //       if (!obj.miuAction) {
    //         obj.errors.MIU_Action = true;
    //         this.errArr.push('activities for MIU ' + obj.miuNumber1);
    //       }
    //       meters.push(obj);
    //       if (
    //         selected &&
    //         selected.device === 'miu' &&
    //         selected.miuNumber1 === obj.miuNumber1
    //       ) {
    //         self.meterActivites.selected = obj;
    //       }
    //     }
    //   }
    //   return item;
    // });
  }

  validateAcitivty(
    workOrderNumber: string,
    screen: string = 'inspection',
    skipNavigate: boolean = false
  ) {
    // debugger;
    // 'Meter Activities': ''
    this.errors.activity = [];
    if (!this.meterActivites.serviceLeftVal) {
      this.errors.activity.push('Left Service FSR Selected');
    }
    if (
      !this.meterActivites.serviceLeftAt &&
      this.meterActivites.serviceLeftVal &&
      this.meterActivites.serviceLeftVal !== 'U'
    ) {
      this.errors.activity.push('Service Left At');
    }
    if (!this.meterActivites.serviceFoundVal) {
      this.errors.activity.push('Found Service FSR Selected');
    }
    if (
      !this.meterActivites.serviceFoundAt &&
      this.meterActivites.serviceFoundVal &&
      this.meterActivites.serviceFoundVal !== 'U'
    ) {
      this.errors.activity.push('Service Found At');
    }
    if (!this.meterActivites.operatedPointOfControl) {
      this.errors.activity.push('Operated Point Of Control');
    }
    if(this.meterActivites.meters[0]?.meterAction?.length==0 && this.meterActivites.meters[0]?.meterFlag=='N'){
      if(!this.meterActivites.override)
      this.errors.activity.push('Please select No meter ');
    }
    // let meterError = false;
    // map(this.meterActivites.meters, item => {
    //   if (
    //     !item.addedMIU &&
    //     !item.addedMeter &&
    //     !item.incomingMeter &&
    //     !item.incomingMIU &&
    //     !item.outgoingMeter &&
    //     !item.outgoingMIU &&
    //     !item.removedMeter &&
    //     !item.removedMIU &&
    //     !item.replaceRegister1 &&
    //     !item.replaceRegister2 &&
    //     !item.noMeterInfo

    //   ) {
    //     if ((!item.activities || item.activities.length == 0) && item.activePSTOrder == true && item.disabledMeterForProcessingIndicator == false) {
    //       item.Maintain_Activities = true;
    //       meterError = true;
    //     }
    //   } else {
    //     if ((!item.noMeterInfo || (item.noMeterInfo && item.fixedNoChargeMeter)) && !item.outgoingMeter && item.serialNumber && (!item.activities || item.activities.length == 0)) {
    //       item.Maintain_Activities = true;
    //       meterError = true;
    //     }
    //     if ((item.noMeterInfo && !item.fixedNoChargeMeter) || item.addedMeter || item.incomingMeter || item.removedMeter || (!item.outgoingMeter && (item.replaceRegister1 || item.replaceRegister2))) {
    //       if (!item.meterAction) {
    //         item.Meter_Action = true;
    //         meterError = true;
    //       }
    //     }
    //     if (item.addedMIU || item.incomingMIU || item.removedMIU) {
    //       if (!item.miuAction) {
    //         item.MIU_Action = true;
    //         meterError = true;
    //       }
    //     }
    //   }
    //   return item;
    // });
    // if (meterError) {
    this.validateActvities();
    this.errors.activity = this.errors.activity.concat(this.errArr);
    // }
    let meterActivity: any = [];
    this.meterActivites.activityNotes['Left Service FSR Selected'].value =
      this.meterActivites.serviceLeftAt +
      ' ' +
      (this.meterActivites.serviceLeftAt
        ? this.getValueName(
          this.meterActivites.serviceLeftAt,
          this.config.SERVICE_FOUND_CODES
        )
        : 'Unable To Verify');
    this.meterActivites.activityNotes['Left Service FSR Selected'].time = moment().valueOf();
    const serviceFound =
      this.meterActivites.serviceFoundVal == 'U'
        ? ''
        : this.meterActivites.serviceFoundVal == 'Y'
          ? ' ON at'
          : ' OFF at';
    this.meterActivites.activityNotes['Found Service FSR Selected'].value =
      this.meterActivites.serviceFoundAt +
      ' ' +
      (this.meterActivites.serviceFoundVal !== 'U'
        ? this.getValueName(
          this.meterActivites.serviceFoundAt,
          this.config.SERVICE_FOUND_CODES
        )
        : 'Unable To Verify');
    this.meterActivites.activityNotes['Found Service FSR Selected'].time =
      moment().valueOf();
    this.meterActivites.activityNotes['Point of Control Operated FSR Selected'].value =
      this.meterActivites.operatedPointOfControl === 'C01'
        ? this.meterActivites.operatedPointOfControl + ' (Yes)'
        : this.meterActivites.operatedPointOfControl + ' (No)';
    this.meterActivites.activityNotes['Point of Control Operated FSR Selected'].time =
      moment().valueOf();
      if(this.meterActivites.primaryActivities?.length){
    this.meterActivites.primaryActivities.map((v) => {
        meterActivity.push(this.getLabelName(v, this.config.MAINTAIN.options));
    });}
    this.meterActivites.activitySubmitted = true;
    const data: any = { data: this.meterActivites, order: workOrderNumber };
    if (!skipNavigate) {
      this.navigateFromScreen(data, 'meterActivites', screen);
    }
  }

  validateComplete(
    workOrderNumber: string,
    screen: string = '',
    skipNavigate: boolean = false
  ) {
    this.errors.complete = [];
    if (!this.complete.serviceComplete) {
      this.errors.complete.push('Service Complete');
    }
    if (
      !this.complete.serviceComplete ||
      (this.complete.serviceComplete == 'INCM' && !this.complete.reasonCategory)
    ) {
      this.errors.complete.push('Reason Category');
    }
    if (
      !this.complete.serviceComplete ||
      (this.complete.serviceComplete == 'INCM' &&
        this.complete.reasonCategory &&
        !this.complete.reasonSelect)
    ) {
      this.errors.complete.push('Reason');
    }
    if (!this.complete.twoPerson) {
      this.errors.complete.push('Two Person Field');
    }
    if (!this.complete.miscellaneousInvoice) {
      this.errors.complete.push('Miscellaneous Invoice');
    }
    if (this.complete.officeReview == 'Y' && !this.complete.backOfficeComments) {
      this.errors.complete.push('Back Office Comments');
    }
    if (!this.complete.informationIssueReport) {
      this.errors.complete.push('Information Issue Report');
    }
    // if (this.complete.informationIssueReport == 'Y' && !this.complete.notes) {
    //   this.errors.complete.push('Notes');
    // }
    //this.isInvalidInvestigationExpiryDate ? this.errors.complete.push('Investigation Expiry Date') : '';

    if (this.complete.serviceComplete == "COMP" && this.errorInRegisterReasonCode) {
      this.errors.complete.push('Reason Category');
    }

    const data: any = { data: this.complete, order: workOrderNumber };
    if (screen && screen !== 'complete' && !skipNavigate) {
      this.navigateFromScreen(data, 'complete', screen);
    }
  }

  getErrorsCount() {
    return (
      this.errors.premise.length +
      this.errors.meter.length +
      this.errors.activity.length +
      this.errors.inspection.length +
      this.errors.complete.length
    );
  }

  fetchDetails(id: String, deviceLocation: String, device: string, district?: string, manufacturer?: string) {
    let url;
    if (device === 'meter' && district && manufacturer) {
      url =
        API_URL.getMeterDetails_scanner
        + "&district=" + district + "&meterManufacturer=" + manufacturer + "&meterSerialNumber=" + id + "&deviceType=" + "Z" + "&planningPlant=" + "";
    }
    else if(device === 'meter'){
      url =
      API_URL.getMeterDetails_mapcall +
      '&actionCode=I&MSN=' +
      id +
      '&deviceLocation=' +
      deviceLocation;
    }
    else if(device === 'miu' && district && manufacturer){
      url =
      API_URL.getMeterDetails_scanner
      + "&district=" + district + "&meterManufacturer=" + manufacturer + "&meterSerialNumber=" + id + "&deviceType=" + "G" + "&planningPlant=" + "";
    }
    else {
      url =
        API_URL.getMIUDetails_mapcall +
        '&actionCode=I&MSN=' +
        id +
        '&deviceLocation=' +
        deviceLocation;
    }
    return this.utilities.getCall(url);
  }
  getCustomerAtGlance(workOrderNumber: String) {
    const url = API_URL.getCustomerAtGlance + workOrderNumber;
    return this.utilities.getCall(url);
  }
  getMeterAtGlance(workOrderNumber: String) {
    const url = API_URL.getMeterInfoAtGlance + workOrderNumber;
    return this.utilities.getCall(url);
  }
  getPaymentAtGlance(workOrderNumber: String) {
    const url = API_URL.getPaymentInfoAtGlance + workOrderNumber;
    return this.utilities.getCall(url);
  }
  getBusinessPartnerInfo(workOrderNumber: String) {
    const url = API_URL.getServiceOrderInfo + workOrderNumber;
    return this.utilities.getCall(url);
  }
  getServiceOrdersForPremise(premiseId: string) {
    const url = API_URL.getBusinessPartnerInfo + premiseId;
    return this.utilities.getCall(url);
  }
  getInteractionRecordsInfo(workOrderNumber: string) {
    const url = API_URL.getInteractionRecordsInfo + workOrderNumber;
    return this.utilities.getCall(url);
  }
  initializeMeterRead(length: number) {
    const readings: Array<any> = [];
    for (let i = 1; i <= length; i++) {
      const dial = { key: 'meterReading' + i, value: 0 };
      readings.push(dial);
    }
    return readings;
  }

  getNonPSTMeters(meters: any[]): any[] {
    return meters.filter(p => { return p?.activePSTOrder != false && p.disabledMeterForProcessingIndicator == false });
  }

  getNoOfDials(meter: Equipment, reset = false) {
    if (reset) {
      meter.meterReadings = [];
      meter.meterReadings2 = [];
    }

    const numberDefault = meter.numberOfDials ? parseInt(meter.numberOfDials) : parseInt(DEFAULT_MAX_DIALS);
    for (let i = 0; i < numberDefault; i++) {
      if (!meter.meterReadings[i] || meter.meterReadings[i] == '0') {
        // const value = meter.noRead ? '0' : '0';
        const value: any = meter.meterReadings[i] == '0' ? '0' : (meter.meterReadings[i] ? meter.meterReadings[i] : '');
        meter.meterReadings[i] = value;
      }
    }
    if (meter.compoundMeter?meter.compoundMeter.toLowerCase() !== 'no':false) {
      const numberDefault2 = meter.numberOfDials2 ? parseInt(meter.numberOfDials2) : parseInt(DEFAULT_MAX_DIALS);
      for (let k = 0; k < numberDefault2; k++) {
        if (!meter.meterReadings2[k] || meter.meterReadings2[k] == '0') {
          // const value = meter.noRead2 ? '0' : '0';
          const value: any = meter.meterReadings2[k] == '0' ? '0' : (meter.meterReadings2[k] ? meter.meterReadings2[k] : '');
          meter.meterReadings2[k] = value;
        }
      }
    }
    return meter;
  }

  validateMeterNumber(meters, newMeter) {
    let result: string[] = [];
    meters.map((v) => {
      const vSerialNumber = this.utilities.removeLeadingZeros2(v.serialNumber);
      const newMeterSerialNumber = this.utilities.removeLeadingZeros2(
        newMeter.serialNumber
      );
      if (
        v.serialNumber == newMeter.serialNumber ||
        vSerialNumber == newMeterSerialNumber
      ) {
        result.push(v.serialNumber);
      }
    });
    return result;
  }

  validateMUINumber(meters, newMeter, secondDial) {
    let result: string[] = [];
    meters.map((v: any) => {
      const vMiuNumber1 = this.utilities.removeLeadingZeros2(v.miuNumber1);
      const vMiuNumber2 = this.utilities.removeLeadingZeros2(v.miuNumber2);
      const newMeterMiuNumber1 = this.utilities.removeLeadingZeros2(
        newMeter.miuNumber1
      );
      const newMeterMiuNumber2 = this.utilities.removeLeadingZeros2(
        newMeter.miuNumber2
      );
      if (!secondDial) {
        if (
          newMeter.miuNumber1 == v.miuNumber1 ||
          (v.miuNumber2 && newMeter.miuNumber1 == v.miuNumber2) ||
          newMeterMiuNumber1 == vMiuNumber1 ||
          (vMiuNumber2 && newMeterMiuNumber1 == vMiuNumber2)
        ) {
          result.push(v.miuNumber1);
        }
      } else {
        if (
          newMeter.miuNumber2 == v.miuNumber1 ||
          (v.miuNumber2 && newMeter.miuNumber2 == v.miuNumber2) ||
          newMeterMiuNumber2 == vMiuNumber1 ||
          (vMiuNumber2 && newMeterMiuNumber2 == vMiuNumber2)
        ) {
          result.push(v.miuNumber2);
        }
      }
    });
    if (newMeter.miuNumber1 == newMeter.miuNumber2) {
      if (!secondDial) {
        result.push(newMeter.miuNumber1);
      } else {
        result.push(newMeter.miuNumber2);
      }
    }
    return result;
  }

  validateMUIData(data) {
    let result = true;
    if (!data.MSN) {
      result = false;
    }
    return result;
  }

  assignMeterData(meter: Equipment, data) {
    console.log(data);
    console.log("eqp",meter);
    meter.deviceLocation = data.deviceLocation;
    meter.installation = data.installation;
    meter.serviceType = data.serviceType;
    meter.ams = data.AMS;
    meter.serialNumber = this.utilities.removeLeadingZeros(data.MSN);
    meter.manufacturerSerialNumber = this.utilities.removeLeadingZeros(
      data.manufacturerSerial
    );
    meter.meterManufacturer = data.manufacturer;
    meter.meterSize = data.meterSize
      ? data.meterSize.replace('-METER', '').replace('Water Meter Size', '')
      : data.meterSize;
    meter.meterSizeVal = data.meterSize
      ? data.meterSize.replace('-METER', '').replace('Water Meter Size', '')
      : data.meterSize;
    meter.positionalLocation = data.meterPosition;
    meter.directionalLocation = data.meterDirection;
    meter.supplementalLocation = data.meterSupplemental;
    meter.readTypeDescription = data.readTypeDescription;
    meter.readType = data.readType;
    meter.deviceLocationNotes = data.deviceLocationNotes;
    meter.pointOfControlNotes = data.PointOfControl;
    meter.secureAccess = data.secureAccess;
    meter.amiDevice = data.amiDevice;
    meter.deviceCategory = data.deviceCategory;
    meter.equipmentId = data.equipmentID;
    meter.siteId = data.siteId;
    console.log(meter.installationType);
    const isOutgoingCompoundMeter = meter.compoundMeter === 'Yes' ? true : false;
    meter.compoundMeter =
      data.registerDetails && data.registerDetails.length < 2 ? 'No' : 'Yes';
    data.registerDetails.map((register: any) => {
      if (register.registerID === '001') {
        meter.meterReading = Math.round(register.read);
        meter.unitForReading = register.UoM;
        meter.numberOfDials = register.Dials;
        meter.registerId = register.registerID;
        meter.readDate = register.readDate;
        // meter.readType = register.readType
        //   ? register.readType.split(',')[0]
        //   : null;
        // if (meter.readType) {
        //   meter.readType = meter.readType.trim();
        //   map(CaptureFormConfig.BASIC_READ_TYPES, (item) => {
        //     if (item.id === meter.readType) {
        //       meter.readTypeDescription = item.name;
        //     }
        //   });
        // }
        meter.meterReadType = register.meterReadType;
        meter.oldRead = Math.round(register.read).toString();
        if (isOutgoingCompoundMeter && meter.compoundMeter === 'No') {
          meter.dualtoSingle = true;
        }
      } else if (register.registerID === '002') {
        meter.meterReading2 = Math.round(register.read);
        meter.unitForReading2 = register.UoM;
        meter.numberOfDials2 = register.Dials;
        meter.registerId2 = register.registerID;
        meter.readDate2 = register.readDate;
        // meter.readType2 = register.readType
        //   ? register.readType.split(',')[0]
        //   : null;
        // if (meter.readType2) {
        //   meter.readType2 = meter.readType2.trim();
        //   map(CaptureFormConfig.BASIC_READ_TYPES, (item) => {
        //     if (item.id === meter.readType2) {
        //       meter.readTypeDescription2 = item.name;
        //     }
        //   });
        // }
        meter.meterReadType2 = register.meterReadType;
        meter.oldRead2 = Math.round(register.read).toString();
      }
    });

    meter = this.getNoOfDials(meter, true);
    return meter;
  }

  assignMiuData(meter: Equipment, data, secondDial) {
    if (!secondDial) {
      meter.miu1DeviceCategory = data.deviceCategory;
      meter.readType = data.readType ? data.readType.split(',')[0] : null;
      if (meter.readType) {
        meter.readType = meter.readType.trim();
        map(CaptureFormConfig.BASIC_READ_TYPES, (item) => {
          if (item.id === meter.readType) {
            meter.readTypeDescription = item.name;
          }
        });
      }
    } else {
      meter.miu2DeviceCategory = data.deviceCategory;
      meter.readType2 = data.readType ? data.readType.split(',')[0] : null;
      if (meter.readType2) {
        meter.readType2 = meter.readType2.trim();
        map(CaptureFormConfig.BASIC_READ_TYPES, (item) => {
          if (item.id === meter.readType2) {
            meter.readTypeDescription2 = item.name;
          }
        });
      }
    }
    return meter;
  }

  getMeterSizeVal(size) {
    return this.config.METER_SIZE_TYPES[size];
  }

  getReplacedDeviceInfo(device, currentMeter) {
    const meter: any = filter(this.meterActivites.meters, (meter) => {
      if (
        (device === 'meter' &&
          meter.oldNumberMeter === currentMeter.serialNumber) ||
        (device === 'miu' && meter.oldNumberMIU === currentMeter.miuNumber1)
      ) {
        // let meterNotes = device === 'meter' ? meter.oldNumberMeter + ' meter replaced with ' + currentMeter.serialNumber : meter.oldNumberMIU + ' RF/MIU replaced with '+ currentMeter.miuNumber1;
        // this.notes.meter.push(meterNotes)
        return meter;
      }
      return false;
    });
    return meter && meter.length > 0 ? meter[0] : new Equipment();
  }

  getLatestMeterRead(meter_id, vendor_name, read_type, miu_number, unit_for_reading, serial_number, manufacturer_serial_number, site_id, is_compound_meter, offset) {
    const url = API_URL.getLatestRead_mapcall + 
                        'vendorName=' + vendor_name +
                        '&equipmentId=' + meter_id +
                        '&readType=' + read_type +
                        '&miuNumber=' + miu_number +
                        '&unitForReading=' + unit_for_reading +
                        '&serialNumber=' + serial_number +
                        '&manufacturerSerialNumber=' + manufacturer_serial_number + 
                        '&siteId=' + site_id+
                        '&isCompoundMeter=' + is_compound_meter +
                        '&utcOffset=' + offset;
    return this.utilities.getCall(url);
  }


  getActionFlags() {
    return {
      INSTALL: 'I',
      REMOVE: 'R',
      REPLACE: 'E',
      MAINTAINENCE: 'O',
      DEFAULT: 'X',
      REGISTER: 'G',
      MISC: 'N',
    };
  }

  setActionFlag(meterActivities, order: WorkOrder) {
    // debugger;
    let actionFlags = this.getActionFlags();
    meterActivities.map((act) => {
      act.data?.meters
        .filter((x) => {
          return !(x.incomingMeter == false && x.outgoingMeter == true);
        })
        .forEach((element) => {
          // debugger;
          let actionFlagMeter = '';
          let actionFlagMIU = '';

          //below conditions with new meter install

          if (element.addedMeter == true) {
            actionFlagMeter = actionFlags.INSTALL;
          }
          if (element.addedMIU == true) {
            actionFlagMIU = actionFlags.INSTALL;
          }

          if (element.addedMIU == false) {
            actionFlagMIU = actionFlags.DEFAULT;
          }

          if (element.incomingMIU == true && element.outgoingMIU == false) {
            actionFlagMIU = actionFlags.REPLACE;
          }

          //below conditions with meter replace

          if (element.incomingMeter == true && element.outgoingMeter == false) {
            actionFlagMeter = actionFlags.REPLACE;
          }

          if (
            element.addedMIU == false &&
            element.incomingMIU == false &&
            element.outgoingMIU == false &&
            element.removedMIU == false
          ) {
            actionFlagMIU = actionFlags.DEFAULT;
          }
          if (element.incomingMIU == true && element.outgoingMIU == false) {
            actionFlagMIU = actionFlags.REPLACE;
          }

          if (element.addedMIU == true) {
            actionFlagMIU = actionFlags.INSTALL;
          }

          //below conditions with remove meter

          if (element.removedMeter == true) {
            actionFlagMeter = actionFlags.REMOVE;
          }
          if (
            element.addedMIU == false &&
            element.incomingMIU == false &&
            element.outgoingMIU == false &&
            element.removedMIU == false
          ) {
            actionFlagMIU = actionFlags.DEFAULT;
          }

          if (element.removedMIU == true) {
            actionFlagMIU = actionFlags.REMOVE;
          }

          //below are maintainnence activity with meter

          if (
            element.addedMeter == false &&
            element.outgoingMeter == false &&
            element.incomingMeter == false &&
            element.removedMeter == false
          ) {
            actionFlagMeter = actionFlags.DEFAULT;
          }

          if (element.incomingMIU == true && element.outgoingMIU == false) {
            actionFlagMIU = actionFlags.REPLACE;
          }

          if (element.removedMIU == true) {
            actionFlagMIU = actionFlags.REMOVE;
          }

          if (element.addedMIU == true) {
            actionFlagMIU = actionFlags.INSTALL;
          }

          //below are default no action for mters

          if (
            element.addedMeter == false &&
            element.incomingMeter == false &&
            element.outgoingMeter == false &&
            element.removedMeter == false &&
            element.addedMIU == false &&
            element.incomingMIU == false &&
            element.outgoingMIU == false &&
            element.removedMIU == false
          ) {
            actionFlagMeter = actionFlags.MAINTAINENCE;
            actionFlagMIU = actionFlags.DEFAULT;
          }

          //below are register replace scenarios
          if (element.replaceRegister1 == true) {
            actionFlagMeter = actionFlags.REGISTER;
          }
          if (element.incomingMIU == true && element.outgoingMIU == false) {
            actionFlagMIU = actionFlags.REPLACE;
          }
          if (
            element.addedMIU == false &&
            element.incomingMIU == false &&
            element.outgoingMIU == false &&
            element.removedMIU == false
          ) {
            actionFlagMIU = actionFlags.DEFAULT;
          }

          // debugger;

          //action flag for matcodes
          if (element.activePSTOrder == false) {
            actionFlagMeter = actionFlags.MISC;
            actionFlagMIU = actionFlags.DEFAULT;
          }

          if (
            element.noMeterInfo == true &&
            order.fixedChargeNoMeter?.toLowerCase() == 'yes'
          ) {
            actionFlagMeter = actionFlags.MISC;
            actionFlagMIU = actionFlags.DEFAULT;
          }
          //added N flag for meters with noor zero  serial numbers
          let serialvalue = element.serialNumber;
          if (
            (serialvalue == null ||
              serialvalue?.replace(/0/g, '').length == 0) &&
            (actionFlagMeter == '' ||
              actionFlagMeter == actionFlags.MAINTAINENCE)
          ) {
            actionFlagMeter = actionFlags.MISC;
          }

          //finall setting the variable to meter element
          if (actionFlagMeter == '') {
            console.error(
              'No value found for actionFlagMeter for meter=>',
              element
            );
          }
          if (actionFlagMIU == '') {
            actionFlagMIU = actionFlags.DEFAULT;
          }
          element['actionFlagMeter'] = actionFlagMeter;
          element['actionFlagMIU'] = actionFlagMIU;
        });

      if (act.data?.meters.length == 0) {
        act.data['actionFlagMeter'] = actionFlags.MISC;
        act.data['actionFlagMIU'] = actionFlags.DEFAULT;
      }
    });
  }

  getSplittedLocationValue(input) {
    let value = '';
    try {
      value = input
        ? input.split(' ').length > 1
          ? input.split(' ')[0]
          : input
        : '';
    } catch (exception) {
      console.error('Error in splitting premise location values', exception);
    }
    return value;
  }
  getSplittedLocationValueOfLabel(input) {
    let value = '';
    try {
      value = input
        ? input.split(' ').length > 1
          ? input.split(' ')[1]
          : input
        : '';
    } catch (exception) {
      console.error('Error in splitting premise location values', exception);
    }
    return value;
  }
  getPremiseLocationByCode(code) {
    let result = CaptureFormConfig.PREMISE_MAPPING.meterLocation.filter((x) => {
      return x.code == code;
    });
    if (result && result.length > 0) {
      return result[0].description;
    } else {
      return code;
    }
  }

  setPremiseDetails(order: WorkOrder) {
    if (order.alerts && order.alerts.length > 0) {
      let safetyNotes:any=[];
     let allSafety = order.alerts.filter((el) => {
      if(el.alertType === 'Safety'){
        safetyNotes.push(el.alertDetails);
      }});
      this.premise.safetyNotes = safetyNotes.toString();
      this.premise.safety = safetyNotes.toString();
    }
    this.premise.lockPlugMeter = order.lockPlugMeter;
    this.premise.heatType = (order.heatType == null)? '':order.heatType;
    if (order.equipments && order.equipments.length > 0) {
      const equipment = order.equipments[0];
      const pointOfControlNotes: any = equipment['pointOfControlNotes'];
      this.premise.curbBoxMeasurementDescription =
        pointOfControlNotes &&
          pointOfControlNotes.length > 0 &&
          pointOfControlNotes[0]
          ? pointOfControlNotes[0].trim()
          : '';
      this.premise.secureAccess = equipment.secureAccess || '00';
      const self = this;
      const directionLocationsArray = [
        'RL',
        'RE',
        'FL',
        'FS',
        'LR',
        'RA',
        'LF',
        'RF',
      ];
      let directionalLocation = this.getSplittedLocationValue(
        equipment.directionalLocation
      );
      if (
        equipment.directionalLocation &&
        directionLocationsArray.indexOf(directionalLocation) === -1
      ) {
        equipment.directionalLocation = 'FS';
      }

      let meterloc = this.getSplittedLocationValue(equipment.meterLocation);
      if (equipment.meterLocation && ['IS', 'OS'].indexOf(meterloc) === -1) {
        equipment.meterLocation = 'OS';
      }

      let readingloc = this.getSplittedLocationValue(
        equipment.readingDeviceDirectionalLocation
      );
      if (
        equipment.readingDeviceDirectionalLocation &&
        directionLocationsArray.indexOf(readingloc) === -1
      ) {
        equipment.readingDeviceDirectionalLocation = 'FS';
      }

      let readingsuppl = this.getSplittedLocationValue(
        equipment.readingDeviceSupplementalLocation
      );
      if (
        equipment.readingDeviceSupplementalLocation &&
        ['IS', 'OS'].indexOf(readingsuppl) === -1
      ) {
        equipment.readingDeviceSupplementalLocation = 'OS';
      }

      map(CaptureFormConfig.PREMISE_MAPPING.meterLocation, (item) => {
        let meterlocation = this.getSplittedLocationValue(
          equipment.meterLocation
        );
        if (
          item.description === equipment.meterLocation ||
          item.code === meterlocation
        ) {
          self.premise.devices.meter.supplemental = item.code;
        }

        let readingsuppl = this.getSplittedLocationValue(
          equipment.readingDeviceSupplementalLocation
        );
        if (
          item.description === equipment.readingDeviceSupplementalLocation ||
          item.code === readingsuppl
        ) {
          self.premise.devices.reading.supplemental = item.code;
        }
      });

      map(CaptureFormConfig.PREMISE_MAPPING.meterPosition, (item) => {
        if (
          item.label === equipment.positionalLocation ||
          item.value ==
          this.getSplittedLocationValue(equipment.positionalLocation)
        ) {
          self.premise.devices.meter.position = item.value;
        }
        if (
          item.label === equipment.readingDevicePositionalLocation ||
          item.value ==
          this.getSplittedLocationValue(
            equipment.readingDevicePositionalLocation
          )
        ) {
          self.premise.devices.reading.position = item.value;
        }
      });

      map(CaptureFormConfig.PREMISE_MAPPING.meterDirectional, (item) => {
        if (
          item.description === equipment.directionalLocation ||
          item.code ===
          this.getSplittedLocationValue(equipment.directionalLocation)
        ) {
          self.premise.devices.meter.directional = item.code;
        }
        if (
          item.description === equipment.readingDeviceDirectionalLocation ||
          item.code ===
          this.getSplittedLocationValue(
            equipment.readingDeviceDirectionalLocation
          )
        ) {
          self.premise.devices.reading.directional = item.code;
        }
      });

      if (
        this.premise.devices.meter.supplemental &&
        this.premise.devices.meter.position &&
        this.premise.devices.meter.directional
      ) {
        this.premise.devices.meter.selected = true;
      }

      if (
        this.premise.devices.reading.supplemental &&
        this.premise.devices.reading.position &&
        this.premise.devices.reading.directional
      ) {
        this.premise.devices.reading.selected = true;
      }
    }
    //this.premise.activityNotes['FSR Name'].value = this.getNotesSignarture();
      this.premise.activityNotes['Meter Location'].value = this.getMeterPosition(this.premise.devices['meter'].directional, this.premise.devices['meter'].supplemental) + ', ' + (this.getLabelName(this.premise.devices['meter'].position, this.config.METER_DEVICE_POSITIONS) || '');
      this.premise.activityNotes['Meter Location'].time = moment().valueOf();
      this.premise.activityNotes['Reading Location'].value = this.getMeterPosition(this.premise.devices.reading.directional, this.premise.devices.reading.supplemental) + ', ' + (this.getLabelName(this.premise.devices.reading.position, this.config.READING_DEVICE_POSITIONS) || '');
      this.premise.activityNotes['Reading Location'].time = moment().valueOf();
      this.premise.activityNotes['Secure Access'].value = this.getLabelName(this.premise.secureAccess, this.config.SECURE_ACCESS);
      this.premise.activityNotes['Heat Type'].value = this.getValueOfId(this.premise.heatType, this.config.HEAT_TYPES);
      this.premise.activityNotes['LockPlug Meter'].value = this.getlableLockedmeter(this.premise.lockPlugMeter, this.config.LOCK_PLUG);
      console.log(order);
      
  }
  getMeterPosition(direc, supl) {
    let direcposition: any = ''
    this.tiles.map((v: any) => {
      if (v.meterDirectional == direc && v.meterSupplemental == supl) {
        direcposition = v.content;
      }
    })
    return direcposition || ''
  }
  setMeterDetails(order: WorkOrder) {
    console.log(order,"original value from server");
    if (order.equipments && order.equipments.length > 0) {
      const equipment = order.equipments[0];
      const deviceLocationNotes: any = equipment['deviceLocationNotes'];
      this.meterActivites.deviceLocationNotes =
        deviceLocationNotes &&
          deviceLocationNotes.length > 0 &&
          deviceLocationNotes[0]
          ? deviceLocationNotes[0].trim()
          : '';
    }
    map(order?.equipments, (equipment, i) => {
      const meter: Equipment = this.mergeEquipmentObject(equipment);
      meter['meterSizeVal'] = equipment.meterSize
        ? equipment.meterSize
          .replace('-METER', '')
          .replace('Water Meter Size', '')
        : equipment.meterSize;
        
      meter.oldRead = Math.round(equipment.meterReading).toString();
      //debugger
      if (CaptureFormConfig.MATCODES.PST.indexOf(order.matCode) >= 0) {
        meter.activePSTOrder = meter.processingIndicator != 'X' ? false : true;
      }
      //  meter.processingIndicator="";
      if (order.matCode != 'PST' && meter.processingIndicator != 'X') {
        meter.disabledMeterForProcessingIndicator = true;
      }
      console.log(order.siteId);
            meter.siteId = order.siteId;
            
      if (equipment.compoundMeter == 'Yes') {
        map(order.equipments, (item) => {
          if (
            item.serialNumber === order.equipments[i].serialNumber &&
            item.registerId === '002' &&
            order.equipments[i].registerId !== item.registerId &&
            order.equipments[i].registerId !== '003'
          ) {
            meter.numberOfDials2 = item.numberOfDials;
            meter.readDate2 = item.readDate;
            meter.readType2 = item.readType;
            meter.readTypeDescription2 = item.readTypeDescription;
            meter.registerId2 = item.registerId;
            meter.meterReading2 = item.meterReading;
            meter.unitForReading2 = item.unitForReading;
            meter.meterSizeVal2 = item.meterSize?item.meterSize
            .replace('-METER', '')
            .replace('Water Meter Size', '')
          : item.meterSize;
            meter.oldRead2 = Math.round(item.meterReading).toString();
            meter.meterReadings2 = [];
            
            this.meterActivites.meters.push(meter);
          }
        });
      } else if (!equipment.compoundMeter || equipment.compoundMeter == 'No') {
        meter.compoundMeter = 'No';
        this.meterActivites.meters.push(meter);
      }
    });
    if (
      (order.equipments == null || order.equipments.length == 0) &&
      order.fixedChargeNoMeter?.toLowerCase() == 'yes'
    ) {
      let newMeter = this.getNoMeter(order);
      newMeter.fixedNoChargeMeter = true;
      this.meterActivites.meters.push(newMeter);
    }
  }
  getLabelName(nameKey, dataArray) {
    for (var i = 0; i < dataArray.length; i++) {
      if (dataArray[i].value && dataArray[i].value === nameKey) {
        return dataArray[i].label;
      } else if (dataArray[i].code && dataArray[i].code === nameKey) {
        return dataArray[i].label ? dataArray[i].label : dataArray[i].name;
      }
    }
  }
  getValueName(nameKey, dataArray) {
    for (var i = 0; i < dataArray.length; i++) {
      if (dataArray[i].label && dataArray[i].label === nameKey) {
        return dataArray[i].value;
      } else if (dataArray[i].code && dataArray[i].code === nameKey) {
        return dataArray[i].value ? dataArray[i].value : dataArray[i].name;
      }
    }
  }
  getValueOfId(nameKey, dataArray) {
    nameKey = nameKey==null? '':nameKey
    for (var i = 0; i < dataArray.length; i++) {
      if (dataArray[i].id === nameKey) {
        return dataArray[i].label;
      }
    }
  }
  getReadType(idkey, dataArray){
    if(idkey){
   const index =  dataArray.findIndex(x => x.id === idkey)
    return dataArray[index].name?dataArray[index].name:'NA'
    }else{
      return 'NA'
    }
  }
  getMIUManufacturerName(idkey, dataArray){
    if(idkey){
   const index =  dataArray.findIndex(x => x.id === idkey)
    return dataArray[index].manufacturerName?dataArray[index].manufacturerName:''
    }else{
      return ''
    }
  }
  getlableLockedmeter(data, dataArray) {
    if (data != undefined) {
      const lockedcode = data.split(',')
      const selectedLock: any = []
      dataArray.map((v: any) => {
        lockedcode.map((o: any) => {
          if (v.value == o) {
            selectedLock.push(v.label)
          }
        })
      })
      return selectedLock.toString();
    }
  }
  setActivityDetails(order: WorkOrder) {
    // debugger;

    this.meterActivites.serviceFoundInDispatch = '';
  }
  setInspectIonDetails(order: WorkOrder) {
   console.log(order,this.inspection);

   this.dbService.getByKey('inspection', order.workOrderNumber).subscribe(
    obj => {
      console.log('data: ', obj);
      const inspectionObj: any = obj;
      if (inspectionObj != null && inspectionObj.data) {
        this.inspection = inspectionObj.data;
        // debugger;
        
      }
    },
    err => console.log(err)
  );
    this.inspection.customerSideLeak = 'N';
    this.inspection.needFollowUp = 'N';
    this.inspection.needFollowUp = 'N';
    this.inspection.filterPitcherorCartridge = 'NO';  
    this.inspection.leaveLeadInformationMaterial = 'NO';
    if(order.customerSideMaterial && order.customerSideMaterial.toLowerCase().match('unknown')){
    this.inspection.serviceReason='Unknown';
    }else if(order.customerSideMaterial && order.customerSideMaterial.toLowerCase().match('unclassified')){
      this.inspection.serviceReason='Unclassified';
    }else{
      this.inspection.serviceReason='';
    }
    // this.saveInspectionDefaultData(order);
  }
  saveInspectionDefaultData(orderInfo) {
    const data1 = { data: this.inspection, order: orderInfo.workOrderNumber };
    this.dbService.update('inspection', data1).subscribe(
      (x) => { },
      (err) => {
        return console.warn(
          'Error in saving inspection tab data in indexdb at setting stage',
          err
        );
      }
    );
  }
  setCompletionDetails(order: WorkOrder) {
    order.crewSize == 1 ? this.complete.twoPerson = 'N' : this.complete.twoPerson = 'Y';
    this.complete.miscellaneousInvoice = 'N';
    this.complete.officeReview = 'N';
    this.complete.informationIssueReport = 'N';
  }

  generateNotes(notesContent, header) {
    let notes = '****' + header + '****:\n';
    const notesArr: any = [];
    map(notesContent, (value, key) => {
      if (key == 'Meter Activities') {
        value.map((v: any) => {
          notesArr.push(v);
        })
      }
      if (value.value && value.value.length > 0) {
        notesArr.push({ content: key + ' : ' + value.value, time: value.time });
      }
    });
    // const activity = _.sortBy(notesArr, 'time', 'asc');
    notesArr.map((v) => {
      notes = notes + '- ' + v.content + '\n';
    });
    this.complete.notesGenerated =
      this.complete.notesGenerated + '\n\n\n' + notes;
  }

  getNotesSignarture() {
    return (
      '[' +
      this.utilities.getUserId() +
      '] [' +
      moment().format('MM/DD/YYYY HH:mm a') +
      ']\n'
    );
  }

  getPSTDefaultMeter(meter) {
    return meter;
  }

  getNoMeter(wo) {
    let newMeter = new Equipment();
    newMeter.serialNumber = '0000000000';
    newMeter.manufacturerSerialNumber = '0000000000';
    newMeter.meterReading = 0;
    newMeter.meterReadings = [];
    newMeter.installation = wo['installation'];
    newMeter.deviceCategory = '0000000000';
    newMeter.amiDevice = false;
    newMeter.processingIndicator = 'X';
    newMeter.numberOfDials = '4';
    newMeter.unitForReading = 'XXX';
    newMeter.encoderId = '00000';
    newMeter.meterSize = 'XXX';
    newMeter.siteId = '00000';
    newMeter.readType = '';
    newMeter.noMeterInfo = true;
    newMeter.noRead = true;
    newMeter.deviceLocation = '';
    newMeter['amiDevice'] = false;
    newMeter.activePSTOrder = true;
    newMeter.activityPerformed = this.MeterActionsService.getActionDetails('NMM',false,false);
    newMeter.meterFlag = newMeter.activityPerformed.meterFlag;
    newMeter.miu1Flag = newMeter.activityPerformed.mIU1Flag;
    newMeter.miu2Flag = newMeter.activityPerformed.mIU2Flag;
    return newMeter;
  }

  getLargeMeterConfigData() {
    const url = API_URL.getLargeMeterConfigData + localStorage.getItem('state');
    return this.utilities.getCall(url);
  }
  setLargeMeterConfig(data) {
    // debugger;
    this.dbService.update('largeMeterConfig', data).subscribe(
      () => {

      },
      (error) => {
        console.log('large meter config data not loaded in indexdb1', error);
      }
    ),
      (err) => {
        console.log('large meter config data not loaded in indexdb11', err);
      };
  }

  //TO be removed
  validateMeterInfoTemp() {
    let errorCount1 = 0;
    let errorCount2 = 0;
    this.meterActivites.meters.forEach((obj, idx) => {
      this.meterActivites.meters[idx].meterReadings.forEach((readingOne) => {
        if (!obj.noRead) {
          if (!this.meterActivites.meters[idx].disabledMeterForProcessingIndicator && this.meterActivites.meters[idx].activePSTOrder) {
            if (readingOne == '') {
              errorCount1++;
            }
          }
        }
        if (this.meterActivites.meters[idx].compoundMeter == "No") {
          if (!this.meterActivites.meters[idx].disabledMeterForProcessingIndicator && this.meterActivites.meters[idx].activePSTOrder) {
            !obj.noRead && errorCount1 > 0 ? this.meterActivites.meters[idx].errorInMeter = true : this.meterActivites.meters[idx].errorInMeter = false;
          } else {
            this.meterActivites.meters[idx].errorInMeter = false;
          }
        }
      });

      this.meterActivites.meters[idx].meterReadings2.forEach((readingTwo) => {
        if (!obj.noRead2) {
          if (!this.meterActivites.meters[idx].disabledMeterForProcessingIndicator && this.meterActivites.meters[idx].activePSTOrder) {
            if (readingTwo == '') {
              errorCount2++;
            }
          }
        }
        if (this.meterActivites.meters[idx].compoundMeter == "Yes") {
          if (!this.meterActivites.meters[idx].disabledMeterForProcessingIndicator && this.meterActivites.meters[idx].activePSTOrder) {
            !obj.noRead2 && errorCount2 > 0 ? this.meterActivites.meters[idx].errorInDualMeter = true : this.meterActivites.meters[idx].errorInDualMeter = false;
            !obj.noRead && !obj.meterReadings.every(item => item != '') ? this.meterActivites.meters[idx].errorInMeter = true : this.meterActivites.meters[idx].errorInMeter = false;
          } else {
            this.meterActivites.meters[idx].errorInDualMeter = false;
            this.meterActivites.meters[idx].errorInMeter = false;
          }
        }
      });
    });
    if (errorCount1 > 0 || errorCount2 > 0) {
      this.isValidInput = true;
    } else {
      this.isValidInput = true;
    }
  }


  validateMeterInfo() {
    let errorCount1 = 0;
    let errorCount2 = 0;
    console.log(this.meterActivites);
    this.meterActivites.meters.forEach((obj, idx) => {
      
      if(this.meterActivites.meters[idx].processingIndicator == 'X' && !this.meterActivites.meters[idx].activityPerformed ){
        if ( this.meterActivites.meters[idx].sourceOfRead1 == '01'){
          this.meterActivites.meters[idx].meterReadings.forEach((reading) => {
            if (reading == '') {
              errorCount1++;
              this.meterActivites.meters[idx].errorInMeter = true
            }else {
              this.meterActivites.meters[idx].errorInMeter = false;
            }
          });
        }
        if (this.meterActivites.meters[idx].compoundMeter == "Yes" && this.meterActivites.meters[idx].sourceOfRead2 == '01'){
          this.meterActivites.meters[idx].meterReadings2.forEach((reading) => {
            if (reading == '') {
              errorCount2++;
              this.meterActivites.meters[idx].errorInDualMeter = true;
              this.meterActivites.meters[idx].errorInMeter = true;            
            }else {
              this.meterActivites.meters[idx].errorInDualMeter = false;
              this.meterActivites.meters[idx].errorInMeter = false;     
            }
          });
        }
      }
    });
    if (errorCount1 > 0 || errorCount2 > 0) {
      this.isValidInput = true;
    } else {
      this.isValidInput = false;
    }
  }  

}
