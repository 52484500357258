import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UtilitiesService } from '../services/utilities.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {
  @Input() center: any;
  map: any;
  bounds: google.maps.LatLngBounds;
  maskedLocation: any;

  constructor(public utilities: UtilitiesService) { }

  ngOnInit(): void {}

  onMapReady(map) {
    this.map = map;
    this.bounds = new google.maps.LatLngBounds();
  }

  ngOnDestroy() {
    this.map = null;
  }

  getCurrentLocationObj() {
    const objs = this.utilities._locationRequests.slice().reverse();
    let browserLocation;
    for(let i = 0; i < objs.length; i++) {
      if (objs[i] && objs[i].status[0] == 'Validate Browser Location ====>' && objs[i].status[2] && objs[i].status[2].latitude && objs[i].status[2].longitude) {
        const cords = objs[i].status[2];
        browserLocation = { lat: parseFloat(cords.latitude), lng: parseFloat(cords.longitude) };
        if (objs[i].status[6] && objs[i].status[6].latitude && objs[i].status[6].longitude) {
          const maskedCords = objs[i].status[6];
          this.maskedLocation = { lat: parseFloat(maskedCords.latitude), lng: parseFloat(maskedCords.longitude) };
          this.center = this.maskedLocation;
        }
        break;
      }
    }
    return browserLocation;
  }
}