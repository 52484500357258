import { EnvironmentConfiguration } from './environment-types';
const backendUrl: string = "https://w1v-qa.awapps.com/core/";

export const environment : EnvironmentConfiguration = {
  version: '4.8.6',
  production: false,
  environment: 'qa',
  environmentName: 'QA',
  apiBaseUrl: 'https://w1v-qa.awapps.com/core/', //'https://w1v-qa.awapps.com/core/'
  apiW1VmgmtBaseUrl: 'https://w1v-qa.awapps.com/core/',  // Work management backend URL
  ssoconfig: 'okta',
  gaTrackingID: null, // google analytics
  sapUri: 'https://awqas.test.apimanagement.us10.hana.ondemand.com:443/',
  mapCallUrl:'https://mapcall.awapps.com/',
  clientId: '0oa14ivrxcsYnU60k0h8',
  issuer: 'https://login-dev.amwater.net/oauth2/aust4s51xcphZQULl0h7',
  awapps: 'https://api.awapps.com/',
  c1vUrl: 'https://c1v-qa.np.awapps.net/openidlogin',
  meterOpsUrl: 'https://meteropaws.amwaternp.net/',
  mapcallUrl:'https://mapcallqa.awapps.com/',
  redirectUri : 'https://w1v-qa.awapps.com', //'https://w1v-qa.np.awapps.net', //http://localhost:4200,
  postLogoutRedirectUri : 'https://w1v-qa.awapps.com/logout.html', //'https://w1v-qa.np.awapps.net/logout.html', //http://localhost:4200,
  scopes: ['openid', 'profile', 'email', 'W1V','offline_access'],
  pkce: true,
  validator: true,
  apiKey: '5EMvcGuBGARGgQNhGRIp52FhrfOkKV1Q',
  authConfig : {
    issuer: 'https://login-dev.amwater.net/oauth2/aust4s51xcphZQULl0h7',
    clientId: '0oa14ivrxcsYnU60k0h8',
    //redirectUri: 'https://w1v-qa.awapps.com/login/callback',
    redirectUri: 'https://w1v-qa.awapps.com/login/callback', //'http://localhost:4200/login/callback',
    scopes: ['openid', 'profile', 'email', 'W1V','offline_access'],
    pkce: true,
    maxClockSkew : 300,
    autoRenew: true,
    tokenManager: {
      storage: 'localStorage'
    }
  }
};
