<!-- <div  class="main-container">
    <app-loader [displayProgressSpinner]="isLoading" class="loader"></app-loader>
    <router-outlet *ngIf="loadPage == 'isPresent'"></router-outlet>
</div>
<div *ngIf="loadPage == 'notPresent'">
    <app-unauthorized></app-unauthorized>
</div> -->

<div class="main-container">
    <app-loader [displayProgressSpinner]="isLoading" class="loader"></app-loader>
    <router-outlet></router-outlet>
</div>