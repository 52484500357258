<div class="container">
  <app-diagnostic-page></app-diagnostic-page>
  <h2>API CALLS</h2>
  <div class="index">
    <mat-list role="list">
      <mat-list-item role="listitem" class="list-item" *ngFor="let call of utilities._networkCalls.slice().reverse();">
        <div class="content">
          <div class="sub-item">
            <h5>Time:</h5>{{ call.time }}
          </div>
          <div class="sub-item">
            <h5>URL:</h5>{{ call.url }}
          </div>
          <div class="sub-item">
            <h5 *ngIf="call.payload">Payload:</h5> {{ call.payload | json }}
          </div>
          <div class="sub-item">
            <h5>Response:</h5> {{ call.response | json }}
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>