import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoiceToTextModule } from 'src/app/components/voice-to-text/voice-to-text.module';
import { SpeechRecognitionService } from 'src/app/services/speech-recognition.service';
import { InfoNotificationPopupModule } from 'src/app/dialogs/info-notification-popup/info-notification-popup.module';
import { GenericMeterModule } from 'src/app/components/generic-meter/generic-meter.module';




@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    VoiceToTextModule,
    InfoNotificationPopupModule,
    GenericMeterModule,
    
  ],
  exports: [
    VoiceToTextModule,
    InfoNotificationPopupModule,
    GenericMeterModule,
    
  ],
  providers: [SpeechRecognitionService],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
