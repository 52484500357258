import { environment } from '../environments/environment';
import * as moment from 'moment';
import 'moment-timezone';

export const API_URL = {
    addUiLog: environment.apiBaseUrl + 'workorder/addUiLog',
    ping: environment.apiBaseUrl + 'location/ping',
    updateFsrLocation: environment.apiBaseUrl + 'location/updateFsrLocation',
    getAllStatesAndDistricts: environment.apiBaseUrl + 'location/stateAndDistricts',
    retrieveFiles: environment.apiBaseUrl + 'opentext-document-management/api/v1/nodes/',
    getWorkOrderMinDetails: environment.apiBaseUrl + 'workorder/getWorkOrderMinDetails/',
    getWorkOrderDetails: environment.apiBaseUrl + 'workorder/getWorkOrderDetails/',
    getAllMeterManufactures: environment.apiBaseUrl + 'reference-table/data?tableName=meter_manufacturer',
    getMeterDetails_scanner: environment.sapUri + 'meter-scanner-details?actionCode=I',
    getMeterDetails_mapcall: environment.sapUri + 'meter-details?deviceType=Z&equipmentID=',
    getMIUDetails_mapcall: environment.sapUri + 'miu-details?deviceType=G&equipmentID=',
    getCustomerAtGlance: environment.apiBaseUrl + 'location/getWorkOrderCustomerAtGlance/',
    getMeterInfoAtGlance: environment.apiBaseUrl + 'location/getWorkOrderMeterAtGlance/',
    getPaymentInfoAtGlance: environment.apiBaseUrl + 'location/getWorkOrderPaymentDetails/',
    getServiceOrderInfo: environment.apiBaseUrl + 'location/getWorkOrderCustomerOrders/',
    getBusinessPartnerInfo: environment.apiBaseUrl + 'workorder/getPremiseOrders/',
    getInteractionRecordsInfo: environment.apiBaseUrl + 'location/getWorkOrderNotes/',
    getCloudOrders: environment.apiBaseUrl + 'workorder/getWorkOrders/',
    getContractorCloudOrders: environment.apiBaseUrl + 'workorder/getContractorWorkOrders/',
    updateAllOrderStatus: environment.apiBaseUrl + 'workorder/updateAllWorkOrderStatus/',
    getOrdersVersion: environment.apiBaseUrl + 'workorder/getFsrUpdates/',
    getAvailabilityReasons: environment.apiBaseUrl + 'workorder/getAvailabilityReasons',
    getContractorOrdersVersion: environment.apiBaseUrl + 'workorder/getContractorUpdates/',
    getFSRSchedule: environment.apiBaseUrl + 'workorder/getFsrShiftSchedule?fsrId=',
    getLatestRead_mapcall: environment.apiBaseUrl + 'meter/reading?',
    getEmployeeDetails: environment.apiBaseUrl + 'workorder/getMappedId?fsrId=',
    twoPersonJob: environment.mapCallUrl + 'Modules/mvc/HealthAndSafety/ConfinedSpaceForm/New',
    uploadFiles: environment.sapUri + 'opentext-document-management/api/v1/nodes/',
    deleteFiles: environment.apiBaseUrl + 'workorder/deleteResource?id=',
    storeCMSInfo: environment.apiBaseUrl + 'workorder/addResource',
    getStoredCMSInfo: environment.apiBaseUrl + 'workorder/searchResource',
    mailNotification: environment.apiBaseUrl + 'workorder/getNotifications/',
    mailNotificationStatus: environment.apiBaseUrl + 'workorder/updateNotificationsStatus/',
    getThreatsForDistrict: environment.apiBaseUrl + 'workorder/getThreatsForDistrict?districts=',
    truckLocation: environment.sapUri + 'geoevent-service-proxy/arcgis/rest/services/GeoEvent/VehicleLocations/FeatureServer/0/query?where=DeviceID+=+\'',
    getContractorOpenOrders: environment.apiBaseUrl + 'workorder/getContractorOtherWorkOrders/',
    getAllAddress: environment.apiBaseUrl + 'workorder/getNearByPremises/',
    getAllMeterNumbers: environment.apiBaseUrl + 'workorder/getPresentOrdersOnPremise',
    adhocOrderCreation: environment.apiBaseUrl + 'workorder/adhocSoCreation',
    safetyCheckForm: environment.mapCallUrl + 'Modules/mvc/HealthAndSafety/ShortCycleWorkOrderSafetyBrief/New',
    safetyCheck: environment.sapUri + 'mapcall-service-proxy/modules/',
    getAmwaterCrew: environment.sapUri + 'mapcall-service-proxy/modules/api/OpenCrewAssignments?hours=48',
    getfsrOpenOrders: environment.apiBaseUrl + 'workorder/getFsrOpenOrders/',
    getfsrDeclineOrders: environment.apiBaseUrl + 'workorder/getFsrDeclinedOrders/',
    getAmwaterOutages: environment.apiBaseUrl + 'workorder/getAmwaterOutages/',
    getNearbyFsrs: environment.apiBaseUrl + 'workorder/getNearbyFsrs/',
    getFsrInfo: environment.apiBaseUrl + 'workorder/getFsrInfo?fsrId=',
    getUserPreferences: environment.apiBaseUrl + 'workorder/getUserPreferences/',
    updateUserPreferences: environment.apiBaseUrl + 'workorder/updateUserPreferences',
    getNearbyAmwaterLocations: environment.apiBaseUrl + 'workorder/getNearbyAmwaterLocations/',
    //planetRisk: environment.awapps + 'planet-risk/v1/incidents/bounding-box-search?',
    planetRisk: environment.apiBaseUrl + 'planet-risk/bounding-box-search?',
    tapImage: environment.sapUri + 'mapcall-service-proxy/Modules/api/tapimage?PremiseNumber=',
    confirmAcknoweldgement: environment.apiBaseUrl + 'workorder/confirmAcknoledgement?workOrderNumber=',
    getLargeMeterConfigData: environment.apiBaseUrl + 'workorder/users/getMeterTestConfig?state=',
    acceptOpenOrder: environment.apiBaseUrl + 'workorder/acceptOpenOrder?fsrId=',
    updateFsrStatus: environment.apiBaseUrl + 'workorder/updateFsrStatus',
    getMatArrivalData: environment.apiBaseUrl + 'arrival-screen/data/'
};

// List all URLs from W1V Management App
export const W1VMGMT_API_URL = {
    bannerData: environment.apiW1VmgmtBaseUrl + 'banner/data',
    allAppLink: environment.apiW1VmgmtBaseUrl + 'banner/getAllAppLinks',
}

export const API_INFO = {
    apiKey: environment.apiKey,
};

export const TIMER = {
    PING: 20000
}

export const FETCH_TRUCK_LOCATION = true;
export const CURRENT_LOCATION_INTERVAL = 20000;
export const POST_FSR_LOCATION_INTERVAL = 1 * 60 * 1000;
export const FAR_AWAY_DISTANCE = 25;
export const DISTANCE_GAP = 500;
export const NOTIFICATION_INTERVAL = 30;
export const ONLINE_CHECK_INTERVAL = 5 * 1000;
export const QUEUE_INTERVAL = 5 * 1000;
export const CONNECTIVITY_STATUS_INTERVAL = 15 * 60 * 1000;
export const TRUCK_LOCATION_INTERVAL = 20000;
export const DISPLAY_POPUP_INTERVAL = 60000;
export const JEOPARDY_TIME = 30;

export const IGNORE_FAILURE_API = [
    'updateFsrLocation'
];

export const IGNORE_STATUS_UPDATE = [
    'COMP',
    'INCM',
    'ONST',
    'ENRT'
];

export const myTruckStatus = [
    0,
    '0',
    10
];
export const topPrioritiesNumbers = [
    '1',
    '2',
    '3'
]
export const disableDefer = [
    0,
    '0',
    '1',
    '2',
    '3',
    10
];

export const currentDayWorkOrderPriority = 10;
export const escalatorWorkOrderPriority = 0;

export const VALID_TRUCK_STATUS = [
    'ACPT',
    'AKNW',
    'ENRT',
    'ONST',
    'HOLD'
];

export const VALID_TRUCK_STATUS_FILTER = [
    'ACPT',
    'AKNW',
    'ENRT',
    'ONST',
    'HOLD',
    'COMP',
    'INCM',
    'CANC',
    'PECA'
];

export const VALID_ACCEPT_STATUS = [
    'PRDS',
    'DISP',
    'DECL',
    'UNSC',
    'ASGN'
];

export const UNSCHEDULE_STATUS = [
    'DISP',
    'ACPT',
    'AKNW',
    'HOLD',
    'DECL',
    'EMRG',
    'PECA',
    'ENRT',
    'SUSP',
    'ASGN'
];

export const ONHOLD_STATUS = [
    'ENRT',
    'ONST'
];

export const NOTIFICATION_STATUS = [
    'DISP',
    'ACPT',
    'AKNW',
    'HOLD',
    'DECL',
];

export const DETAILS_NOT_FOR_INDEXEDDB = [
    'COMP',
    'INCM',
    'CANC',
    'PECA',
    'UNSC',
    'DECL',
    'TECO'
];

export const SEWER_ICON_MATCODES = [
    'DMS',
    'DRS',
    'OMS',
    'ORS',
    'SCI'
];

export const SEWER_AUTHORITY_CODE = [
    'DMS',
    'OMS'
];

export const ON_GOING_STATUS = [
    'ENRT',
    'ONST'
];

export const COMPLETE_STATUS = [
    'COMP',
    'INCM',
    'CANC',
    'PECA'
];
export const ALERT_IMAGES = {
    'default': 'Alert_Danger.svg',
    'Pet Alert': 'Alert_Pet.svg',
    'Police Escort Alert': 'Police_Escort.svg',
    'snake': 'Alert_Snake.svg',
    'bug': 'Alert_Insect.svg'
}
export const APPOINTMENT_PRIORITY = ['3'];

export const ORDER_SEVERITIES = {
    '0': { value: '0', color: 'd2691e', label: 'Same Day', class: 'priority0', buffer: '' },
    '1': { value: '1', color: 'ff5d5d', label: 'Emergency', class: 'priority1', buffer: '(1-2 Hrs)' },
    '2': { value: '2', color: '08bde4', label: 'Emergency', class: 'priority2', buffer: '(24 Hrs)' },
    '3': { value: '3', color: 'ffc300', label: 'Customer Appt', class: 'priority3', buffer: '' },
    '4': { value: '4', color: '999', label: 'Within 5 Days', class: 'priority4', buffer: '' },
    '5': { value: '5', color: '999', label: 'Within 15 Days', class: 'priority5', buffer: '' },
    '6': { value: '6', color: '999', label: 'Within 30 Days', class: 'priority6', buffer: '' },
    '7': { value: '7', color: '999', label: 'Within 90 Days', class: 'priority7', buffer: '' },
    '8': { value: '8', color: '999', label: 'Within 160 Days', class: 'priority8', buffer: '' },
    '9': { value: '9', color: '0978eb', label: 'Expired', class: 'priority9', buffer: '' },
    '10': { value: '10', color: '008000', label: 'Same due date', class: 'priority10', buffer: '' },
    '11': { value: '11', color: '662773', label: '3 hours appt.', class: 'three-hours', buffer: '' }
};

export const currentTimeZone = moment(moment().toDate()).utcOffset() / 60;
export const easterTimeZone = 'America/New_York';
export const serverTimeZone = moment.tz(moment().toDate(), easterTimeZone).utcOffset();

export const JPRD_WORK_ORDERS: any[] = [];
export const JPRD_NOTIFICATION_INTERVAL = 300;

export const PREVENT_QUEUE_URL = ['addUiLog', 'opentext-document-management', 'adhocSoCreation'];
export const FILE_FORMAT_TYPE = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'gif', 'GIF', 'svg', 'SVG'];
export const UPLOAD_FILE_FORMAT = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'gif', 'GIF', 'svg', 'SVG', 'csv', 'xlsx', 'xlx', 'pdf', 'txt', 'CSV', 'DOCX', 'docx', 'doc'];

export const API_SERVICES = {
    aws: environment.apiBaseUrl,
    statusupdate: 'status/updateWorkOrderAndSequenceStatus',
    statuscomplete:'status/saveWoSoCompletion',
    statuscompletev2:'status/saveWoSoCompletionV2'
    //soCreate: 'work1v-service-proxy/amqservice/socreateworkorder/'
};

export const TRANSITIONS_QUEUE = [
    API_URL.updateAllOrderStatus,
    API_SERVICES.aws + API_SERVICES.statuscomplete,
    API_SERVICES.aws + API_SERVICES.statusupdate
];

export const highPriorityArr = ['1', '2','3', '0'];
export const DEFAULT_MAX_DIALS = "8";
