<div class="container">
  <!-- <h2>Location</h2> -->
  <app-diagnostic-page></app-diagnostic-page>
  <ngui-map scrollwheel="true" zoom="15" [center]="center" (mapReady$)="onMapReady($event)">
    <div>
      <marker icon="assets/images/maps/my_overidden_location.svg" [position]="maskedLocation">
      </marker>
    </div>
    <div>
      <marker [icon]="{
          url: 'assets/images/maps/my_location.svg',
          scaledSize: [72,84]
        }" [position]="getCurrentLocationObj()">
      </marker>
    </div>
  </ngui-map>

  <div class="index">
    <mat-list role="list">
      <mat-list-item role="listitem" class="list-item"
        *ngFor="let record of utilities._locationRequests.slice().reverse();">
        <div class="content">
          <div class="sub-item">
            <span class="time">{{ record.time }}</span>
          </div>
          <div class="sub-item">{{ record.status | json }}</div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>