import { DBConfig } from "ngx-indexed-db";

export function migrationFactory() {
    return {
        1: (db: any, transaction: { objectStore: (arg0: string) => any; }) => {
            // const store = transaction.objectStore('premise');
            // store.createIndex('data', 'data', { unique: false });
            // store.createIndex('order', 'order', { unique: false });
        }
    };
}

export const dbConfig: DBConfig = {
    name: 'w1v',
    version: 13,
    objectStoresMeta: [
        {
            store: 'workOrders',
            storeConfig: { keyPath: 'workOrderNumber', autoIncrement: false },
            storeSchema: [
                { name: 'workOrderNumber', keypath: 'workOrderNumber', options: { unique: false } },
                { name: 'status', keypath: 'status', options: { unique: false } },
                { name: 'priority', keypath: 'priority', options: { unique: false } },
                { name: 'sequence', keypath: 'sequence', options: { unique: false } },
            ]
        },
        {
            store: 'arrivalData',
            storeConfig: { keyPath: 'matCode', autoIncrement: false },
            storeSchema:[]
        },
        {
            store: 'widgets',
            storeConfig: { keyPath: 'workOrderNumber', autoIncrement: false },
            storeSchema: [
                { name: 'workOrderNumber', keypath: 'workOrderNumber', options: { unique: false } }
            ]
        },
        {
            store: 'version',
            storeConfig: { keyPath: 'key', autoIncrement: false },
            storeSchema: [

            ]
        },
        {
            store: 'premise',
            storeConfig: { keyPath: 'order', autoIncrement: false },
            storeSchema: [
                // { name: 'data', keypath: 'data', options: { unique: false } },
            ]
        },
        {
            store: 'meter-test',
            storeConfig: { keyPath: 'order', autoIncrement: false },
            storeSchema: [
                // { name: 'data', keypath: 'data', options: { unique: false } },
            ]
        },
        {
            store: 'inspection',
            storeConfig: { keyPath: 'order', autoIncrement: false },
            storeSchema: [
                // { name: 'data', keypath: 'data', options: { unique: false } },
            ]
        },
        {
            store: 'complete',
            storeConfig: { keyPath: 'order', autoIncrement: false },
            storeSchema: [
                // { name: 'data', keypath: 'data', options: { unique: false } },
            ]
        },
        {
            store: 'meterActivites',
            storeConfig: { keyPath: 'order', autoIncrement: false },
            storeSchema: [
                // { name: 'data', keypath: 'data', options: { unique: false } },
            ]
        },
        {
            store: 'largeMeterConfig',
            storeConfig: { keyPath: 'order', autoIncrement: false },
            storeSchema: [
                // { name: 'data', keypath: 'data', options: { unique: false } },
            ]
        },
        {
            store: 'workOrderDetails',
            storeConfig: { keyPath: 'order', autoIncrement: false },
            storeSchema: [
                { name: 'data', keypath: 'data', options: { unique: false } },
            ]
        },
        {
            store: 'transitions',
            storeConfig: { keyPath: 'key', autoIncrement: false },
            storeSchema: [

            ]
        },
        {
            store: 'nontransitions',
            storeConfig: { keyPath: 'key', autoIncrement: false },
            storeSchema: [
            ]
        },
        {
            store: 'uploadedFiles',
            storeConfig: { keyPath: 'key', autoIncrement: false },
            storeSchema: []
        }
    ],
    migrationFactory
};
